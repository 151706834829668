import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../hooks';
import { ROUTES } from '../../../models';
import { routesRoles } from '../../../routes/routesRoles';

interface IProtectedRoute {
  pathName: ROUTES;
  component: JSX.Element;
}

const ProtectedRoute: FC<IProtectedRoute> = ({ pathName, component }) => {
  const auth = useAuth();  

  const location = useLocation();
  if (auth?.role && routesRoles[auth?.role]?.includes(pathName)) {
    return component;
  }
  if (auth && auth!.token) {
    return <Navigate to={ROUTES.UNAUTHORIZED} state={{ from: location }} replace />;
  }
  return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
};

export default ProtectedRoute;
