import moment from 'moment';
import { t } from 'i18next';

import { formatDate, isBeforeToday } from './dates';
import { EarlyBirdPriceDatePeriodType, FormatDateEnum, SessionPeriodType, ISessionFormFields, IExperienceFormFields, Session, IDefaultSession } from '../../models';

export const validateStartDate = (value: Date) => {
  let errors: string[] = [];
  if (isBeforeToday(value)) {
    errors = [t('youCantSelectDateBeforeToday')];
  }

  return errors;
};
export interface IGetEarlyBirdPriceDateValueParams {
  earlyBirdPriceDate: EarlyBirdPriceDatePeriodType;
  startDate: Date;
  startTime: Date;
}

export const getEarlyBirdPriceDateValue = ({
  earlyBirdPriceDate,
  startDate,
  startTime,
}: IGetEarlyBirdPriceDateValueParams) => {
  const startDateVal = formatDate(startDate, FormatDateEnum.YMD);
  const startTimeVal = formatDate(startTime, FormatDateEnum.HM);
  const earlyBirdDate = moment(`${startDateVal} ${startTimeVal}`).subtract(
    parseInt(earlyBirdPriceDate.replace('d', '')),
    'd'
  );
  return earlyBirdDate.valueOf();
};

export const validateStartDateBasedOnEarlyBirdPrice = ({
  earlyBirdPriceDate,
  startDate,
  startTime,
}: IGetEarlyBirdPriceDateValueParams) => {
  const todayDate = Date.now();
  const earlyDate = getEarlyBirdPriceDateValue({
    earlyBirdPriceDate,
    startDate,
    startTime,
  });
  const diffEarlyDateAndTodayDate = moment(earlyDate).diff(todayDate, 'd');

  if (diffEarlyDateAndTodayDate < 0) {
    return ['please choose valid early bird date!'];
  }
  return [];
};

export const getEndTimeValue = (
  sessionPeriod: SessionPeriodType,
  startTime: Date
) => {
  const endTime = moment(startTime, FormatDateEnum.HM).add(
    parseInt(sessionPeriod.replace('h', '')),
    'h'
  );
  return endTime;
};

export const getStartDateValue = (startDate: Date, startTime: Date) => {
  const startTimeVal = formatDate(startTime, FormatDateEnum.HM);
  const startDateVal = formatDate(startDate, FormatDateEnum.YMD);
  return moment(`${startDateVal} ${startTimeVal}`).valueOf();
};

export const getEndDateValue = (
  sessionPeriod: SessionPeriodType,
  startTime: Date,
  startDate: Date
) => {
  if (startTime && startDate) {
    const startTimeVal = formatDate(startTime, FormatDateEnum.HM);
    const startDateVal = formatDate(startDate, FormatDateEnum.YMD);
    const endDate = moment(`${startDateVal} ${startTimeVal}`).add(
      parseInt(sessionPeriod?.replace('h', '')),
      'h'
    );
    return endDate.valueOf();
  }
};

export const makeSessionObj = (
  sessionDetails: ISessionFormFields | IExperienceFormFields
) => {
  const {
    hospitality,
    attendeesGender,
    startDate,
    endDate,
    startTime,
    endTime,
    price,
    availableSeats,
    note,
    minimumSeats,
    bufferTime,
    earlyBirdPrice,
    earlyBirdPriceDate,
  } = sessionDetails;

  return {
    startTime: formatDate(startTime, FormatDateEnum.HM),
    endTime: formatDate(endTime, FormatDateEnum.HM),
    hospitality,
    attendeesGender,
    startDate: getStartDateValue(startDate, startTime),
    endDate: getEndDateValue(endDate, startTime, startDate),
    price: parseInt(price as string),
    availableSeats: Number(availableSeats),
    bufferTime,
    minimumSeats: Number(minimumSeats),
    note,
    earlyBirdPrice:
      earlyBirdPrice !== null && earlyBirdPrice !== undefined
        ? parseInt(earlyBirdPrice as string)
        : undefined,
    earlyBirdPriceDate: earlyBirdPriceDate
      ? getEarlyBirdPriceDateValue({ earlyBirdPriceDate, startDate, startTime })
      : undefined,
  };
};

export const getEarlyBirdPricePeriod = (
  startDate: number,
  earlyBirdPriceDate: number
) => {
  if (startDate && earlyBirdPriceDate) {
    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEarlyBirdPriceDate =
      moment(earlyBirdPriceDate).format('YYYY-MM-DD');
    return `${moment(`${formattedStartDate} 23:59:59`).diff(
      moment(`${formattedEarlyBirdPriceDate} 23:59:59`),
      'days'
    )}d`;
  }
};
const getTimeDiff = (session: Session | IDefaultSession) => {
  const startAtMomentObject = moment(session.startAt);
  const endAtMomentObject = moment(session.endAt);
  const timeDiff = endAtMomentObject.diff(startAtMomentObject, 'hours');
  return `${timeDiff}h`;
};

export const handleSessionRes = (
  sessionData: Session | IDefaultSession = {} as Session
) => {
  const {
    minimumSeats,
    startDate,
    availableSeats,
    attendeesGender = [],
    bufferTime,
    startAt,
    endAt,
    endTime,
    price,
    earlyBirdPrice,
    note,
    hospitality,
    earlyBirdPriceDate,
  } = sessionData;

  return {
    minimumSeats,
    startDate: new Date(startDate),
    endDate: getTimeDiff(sessionData),
    availableSeats,
    attendeesGender,
    bufferTime: bufferTime ?? '2h',
    startTime: new Date(startAt),
    endTime: endAt ? new Date(endAt) : new Date(endTime),
    price,
    earlyBirdPrice,
    earlyBirdPriceDate: getEarlyBirdPricePeriod(startDate, earlyBirdPriceDate),
    note,
    hospitality,
  };
};
