import { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

interface LogoProps {
  collapsed: boolean;
}

const Logo: FC<LogoProps> = ({ collapsed }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.sidebarLogoCont}>
      <img
        style={{ display: collapsed ? "none" : "block" }}
        src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
        className={styles.sidebarLogo}
        alt={t("suplift")}
        title={t("suplift")}
      />
      <img
        style={{ display: collapsed ? "block" : "none" }}
        className={styles.sidebarLogoColl}
        src={`${process.env.PUBLIC_URL}/assets/images/small-logo.png`}
        alt={t("suplift")}
        title={t("suplift")}
      />
    </div>
  );
};

export default Logo;
