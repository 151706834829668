import { FC } from 'react';
import { Layout } from 'antd';
import cn from 'classnames';
import Logo from './Logo';
import Menu from './Menu';
import s from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IBaseState, setToggleMobileSideBar } from '../../../store/Base/slice';

const { Sider } = Layout;

const SiderMenu: FC = () => {
  const { sideBar, mobileSideBar } = useSelector((state: { base: IBaseState }) => state.base);
  const dispatch = useDispatch();

  const toggleMobileSidebar = () => {
    dispatch(setToggleMobileSideBar(null));
  };

  return (
    <Sider
      theme="light"
      trigger={null}
      collapsible
      collapsed={sideBar.isClosed}
      className={cn(s.sider, {
        [s.siderOpen]: mobileSideBar.isClosed,
      })}>
      {mobileSideBar.isClosed && <div className={s.fade} onClick={toggleMobileSidebar} />}
      <div
        className={cn(s.innerSideWrap, {
          [s.innerSiderWrapCollapsed]: sideBar.isClosed,
        })}>
        <Logo collapsed={sideBar.isClosed} />
        <Menu />
      </div>
    </Sider>
  );
};

export default SiderMenu;
