import { lazy } from 'react';

// { ****** Experience ****** }
export const ExpList = lazy(() =>
  import('../pages/experiences').then((module) => ({
    default: module.ExperiencesList,
  }))
);
export const EditOrAddExp = lazy(() =>
  import('../pages/experiences').then((module) => ({
    default: module.ExperiencesCreateOrEdit,
  }))
);

// { ****** Session ****** }
export const SessionsList = lazy(() =>
  import('../pages/sessions').then((module) => ({
    default: module.SessionsList,
  }))
);

export const SessionsCreateOrEdit = lazy(() =>
  import('../pages/sessions').then((module) => ({
    default: module.SessionCreateOrEdit,
  }))
);

const SessionLearners = lazy(() =>
  import('../pages/sessions').then((module) => ({
    default: module.SessionAttendeesPage,
  }))
);

// { ****** Talents ****** }

export const TalentsList = lazy(() =>
  import('../pages/talents').then((module) => ({
    default: module.TalentsList,
  }))
);


// const SessionAddLearner = lazy(() =>
//   import('../../pages/sessions').then(module => ({
//     default: module.AddSessionLearner,
//   }))
// );

// const ReviewsList = lazy(() =>
//   import('../../pages/reviews').then(module => ({
//     default: module.ReviewsList,
//   }))
// );

// const LearnersList = lazy(() =>
//   import('../../pages/learners').then(module => ({
//     default: module.LearnersList,
//   }))
// );
// const EditOrAddLearner = lazy(() =>
//   import('../../pages/learners').then(module => ({
//     default: module.CreateOrEditLearner,
//   }))
// );
// const DiscountCodesList = lazy(() =>
//   import('../../pages/discount-codes').then(module => ({
//     default: module.DiscountCodeList,
//   }))
// );
// const CreateOrUpdateDiscountCode = lazy(() =>
//   import('../../pages/discount-codes').then(module => ({
//     default: module.CreateOrUpdateDiscountCode,
//   }))
// );

// const NotificationsList = lazy(() =>
//   import('../../pages/notifications').then(module => ({
//     default: module.NotificationList,
//   }))
// );

// const NotificationCreate = lazy(() =>
//   import('../../pages/notifications').then(module => ({
//     default: module.CreateNotifications,
//   }))
// );

// const Cheering = lazy(() => import('../../pages/cheering'));

// const BookingList = lazy(() => import('../../pages/booking-list'));

// const GiftCardsList = lazy(() =>
//   import('../../pages/gift-card').then(module => ({
//     default: module.GiftCardsList,
//   }))
// );

// const CreateOrEditGiftCard = lazy(() =>
//   import('../../pages/gift-card').then(module => ({
//     default: module.CreateOrEditGiftCard,
//   }))
// );

// const GiftBookingList = lazy(() =>
//   import('../../pages/gift-card').then(module => ({
//     default: module.GiftCardBookingList,
//   }))
// );

// const B2BGiftCardList = lazy(() =>
//   import('../../pages/gift-card').then(module => ({
//     default: module.B2BGiftCardList,
//   }))
// );

// const CreateOrEditB2BGiftCard = lazy(() =>
//   import('../../pages/gift-card').then(module => ({
//     default: module.CreateOrEditB2BGiftCard,
//   }))
// );
// const DashboardReviewsList = lazy(() =>
//   import('../../pages/reviews').then(module => ({
//     default: module.DashboardReviewsList,
//   }))
// );
// const WantedExperiencesList = lazy(() =>
//   import('../../pages/experiences').then(module => ({
//     default: module.WantedExperiencesList,
//   }))
// );
// const CoachesList = lazy(() =>
//   import('../../pages/coaches').then(module => ({
//     default: module.MainList,
//   }))
// );
// const CreateOrEditCoach = lazy(() =>
//   import('../../pages/coaches/create-or-edit').then(module => ({
//     default: module.CreateOrEditCoach,
//   }))
// );

// const Balance = lazy(() => import('../../pages/balance'));

// const CreateOrEditCoachBanner = lazy(() =>
//   import('../../pages/banner').then(module => ({
//     default: module.CreateOrEditCoachBanner,
//   }))
// );

// const BannerList = lazy(() =>
//   import('../../pages/banner').then(module => ({
//     default: module.BannerList,
//   }))
// );

// const CreateOrEditLearnerBanner = lazy(() =>
//   import('../../pages/banner').then(module => ({
//     default: module.CreateOrEditLearnerBanner,
//   }))
// );
// const SortBanners = lazy(() =>
//   import('../../pages/banner').then(module => ({
//     default: module.SortBanners,
//   }))
// );
// const PaymentError = lazy(() =>
//   import('../../pages/payment-error').then(module => ({
//     default: module.PaymentErrorList,
//   }))
// );
// // COLLECTION

// const CollectionList = lazy(() =>
//   import('../../pages/collection').then(module => ({
//     default: module.CollectionsList,
//   }))
// );
// const CreateOrEditCollection = lazy(() =>
//   import('../../pages/collection').then(module => ({
//     default: module.CreateOrEditCollection,
//   }))
// );
// const SortCollections = lazy(() =>
//   import('../../pages/collection').then(module => ({
//     default: module.SortCollections,
//   }))
// );

// // ACCOUNT_MANAGER

// const AccountManagerList = lazy(() =>
//   import('../../pages/account-manager').then(module => ({
//     default: module.AccountManager,
//   }))
// );
// const EditAccountManger = lazy(() =>
//   import('../../pages/account-manager').then(module => ({
//     default: module.EditAccountManger,
//   }))
// );
// const CoachBalance = lazy(() =>
//   import('../../pages/coaches').then(module => ({
//     default: module.CoachBalance,
//   }))
// );

// APPROVALS

// const ApprovalsList = lazy(() =>
//   import('../../pages/approvals').then(module => ({
//     default: module.ApprovalsList,
//   }))
// );

// const EditApprovals = lazy(() =>
//   import('../../pages/approvals').then(module => ({
//     default: module.EditApprovals,
//   }))
// );

// // GIGS

// const GigsList = lazy(() =>
//   import('../../pages/gigs').then(module => ({
//     default: module.GigsList,
//   }))
// );

// const CreateOrEditGigs = lazy(() =>
//   import('../../pages/gigs').then(module => ({
//     default: module.CreateOrEditGigs,
//   }))
// );

// const GigsApplications = lazy(() =>
//   import('../../pages/gigs').then(module => ({
//     default: module.GigsApplications,
//   }))
// );
// const GigsNotifications = lazy(() =>
//   import('../../pages/gigs').then(module => ({
//     default: module.GigsNotifications,
//   }))
// );
