import { Col, Divider, Form, Row } from 'antd';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { FormSubmit, Page } from '../../../components';
import { defaultCoordinates } from '../../../helpers/constants';
import { useAuth } from '../../../hooks';
import { ROUTES, SessionPeriodType } from '../../../models';
import { GetSessionDetailsRequest, PostSessionRequest, SessionFormData } from '../../../models/session/session';
import { routesWithParams } from '../../../routes/routesDetails';
import {
  addSessionRequest,
  changeOneKeyOfSessionState,
  clearSessionDetails,
  getSessionByIdRequest,
  updateSessionRequest,
} from '../../../store/Session/slice';
import SessionInfo from '../components/SessionInfo';
import { formatCreateOrUpdateSessionPayload } from '../utils';

export interface ISessionCreateOrEditProps {
  isEdit?: boolean;
}
const SessionCreateOrEdit = ({ isEdit = false }: ISessionCreateOrEditProps) => {
  const user = useAuth();
  const { expId, sessionId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm<SessionFormData>();
  const experienceId = new URLSearchParams(window.location.search).get('experienceId');
  const { isAddedOrEdited, isCTALoading } = useSelector(
    (state: { sessions: { isAddedOrEdited: boolean; isCTALoading: boolean } }) => state.sessions
  );

  useEffect(() => {
    if (!experienceId && !sessionId && !expId) {
      navigate(ROUTES.SESSIONS);
    }
  }, [experienceId]);

  useEffect(() => {
    if (sessionId && sessionId !== undefined) {
      dispatch(getSessionByIdRequest(sessionId));
    }
    return () => {
      dispatch(clearSessionDetails());
    };
  }, []);

  const { sessionDetails, isGetDetailsLoading } = useSelector(
    (state: { sessions: { sessionDetails: GetSessionDetailsRequest | null; isGetDetailsLoading: boolean } }) =>
      state.sessions
  );

  const languages = [
    { id: 1, name: 'Arabic' },
    { id: 2, name: 'English' },
  ];

  const createOrUpdateSession = (params: any) => {
    if (isEdit) {
      let addPayload: PostSessionRequest;
      addPayload = {
        id: +sessionId || +params.sessionId,
        experienceId: +params.expId,
        lat: params.payload.location?.coordinates?.lat,
        lng: params.payload.location?.coordinates?.lng,
        locationDetails: params.payload.location.details,
        locationDetailsEn: params.payload.location.address,
        sessionPeriod: params.payload.sessionPeriod,
        availableSeats: params.payload.availableSeats,
        minimumSeats: params.payload.minimumSeats,
        sessionDate: params.payload.startDate,
        sessionTime: params.payload.startTime,
        distributorId: params.payload.resellers,
        coachId: params.payload.talent,
      };
      dispatch(updateSessionRequest(addPayload));
    } else {
      let addPayload: PostSessionRequest;
      addPayload = {
        experienceId: +params.expId,
        lat: params.payload.location?.coordinates?.lat,
        lng: params.payload.location?.coordinates?.lng,
        locationDetails: params.payload.location.details,
        locationDetailsEn: params.payload.location.address,
        sessionPeriod: params.payload.sessionPeriod,
        availableSeats: params.payload.availableSeats,
        minimumSeats: params.payload.minimumSeats,
        sessionDate: params.payload.startDate,
        sessionTime: params.payload.startTime,
        distributorId: params.payload.resellers,
        coachId: params.payload.talent,
      };
      dispatch(addSessionRequest(addPayload));
    }
  };

  useEffect(() => {
    if (isAddedOrEdited) {
      form.resetFields();
      let queryParams = '';

      if (experienceId !== undefined) {
        queryParams = `?searchByExperienceId=${experienceId || expId}`;
      }

      navigate(`${ROUTES.SESSIONS}${queryParams}`);
      dispatch(changeOneKeyOfSessionState({ key: 'isAddedOrEdited', value: false }));
    }
    if (isEdit) {
      form.resetFields();
    }
  }, [isAddedOrEdited, navigate]);

  const [position, setPosition] = useState<google.maps.LatLngLiteral>(defaultCoordinates);

  const handleSubmit = (values: SessionFormData) => {
    createOrUpdateSession({
      expId: experienceId || expId,
      sessionId,
      payload: formatCreateOrUpdateSessionPayload(values, languages),
    });
  };

  useEffect(() => {
    if (sessionDetails && sessionDetails !== null) {
      const mappedSessionData: SessionFormData = mapSessionDetailsToFormFields(sessionDetails);
      form.setFieldsValue(mappedSessionData);
    }
  }, [sessionDetails]);

  const mapSessionDetailsToFormFields = (sessionDetails: GetSessionDetailsRequest): SessionFormData => {
    const mappedSessionDetails = {} as SessionFormData;
    mappedSessionDetails.id = sessionDetails.id;
    mappedSessionDetails.experienceId = sessionDetails.experienceId;
    mappedSessionDetails.hospitality = 'waterBanana';
    mappedSessionDetails.attendeesGender = 'male';
    mappedSessionDetails.startDate = sessionDetails.sessionStartDate;
    mappedSessionDetails.startTime = sessionDetails.sessionStartTime;
    mappedSessionDetails.endDate = (sessionDetails.sessionStartDate + 'h') as SessionPeriodType;
    mappedSessionDetails.sessionPeriod = sessionDetails.sessionPeriod;
    mappedSessionDetails.price = sessionDetails.coachPrice;
    mappedSessionDetails.supliftPrice = sessionDetails.supliftPrice;
    mappedSessionDetails.availableSeats = sessionDetails.availableSeats;
    mappedSessionDetails.minimumSeats = sessionDetails.minimumSeats;
    mappedSessionDetails.bufferTime = '10h';
    mappedSessionDetails.earlyBirdPrice = sessionDetails.coachPrice; // TODO
    mappedSessionDetails.earlyBirdPriceDate = '1d'; // TODO
    mappedSessionDetails.language = ['english'];
    mappedSessionDetails.title = 'Test'; // TODO
    mappedSessionDetails.titleEn = 'TestEn'; // TODO
    mappedSessionDetails.location = {
      address: sessionDetails.locationDetails,
      lat: sessionDetails.lat,
      lng: sessionDetails.lng,
      city: 'Ksa',
      coordinates: {
        lat: sessionDetails.lat,
        lng: sessionDetails.lng,
      },
      detailsEn: sessionDetails.locationDetails,
      details: sessionDetails.locationDetails,
    };
    mappedSessionDetails.locationDetails = sessionDetails.locationDetails;
    mappedSessionDetails.locationDetailsEn = sessionDetails.locationDetails;
    mappedSessionDetails.talent = sessionDetails.coach?.id;
    mappedSessionDetails.resellers = sessionDetails.distributor?.id;
    return mappedSessionDetails;
  };

  return (
    <Page loading={isGetDetailsLoading}>
      {/* {
        <FlexWrapper justifyContent="flex-end">
          <Button type="primary" onClick={() => navigate(routesWithParams.sessionLearners(expId, sessionId))}>
            {t('learners')}
          </Button>
        </FlexWrapper>
      } */}
      <Row>
        <Col xs={24}>
          <Divider>
            <h1>{isEdit ? t('editSession') : t('AddSession')}</h1>
          </Divider>

          <Form onFinish={handleSubmit} form={form} layout="vertical">
            {/* Session schedular */}
            {/* <SessionScheduler form={form} /> */} {/* TODO */}
            {/* Session info */}
            <SessionInfo form={form} />
            <FormSubmit
              handleSubmit={form.submit}
              handleCancel={() => {
                let queryParams = '';

                if (experienceId !== undefined) {
                  queryParams = `?searchByExperienceId=${experienceId || expId}`;
                }

                navigate(`${ROUTES.SESSIONS}${queryParams}`);
              }}
              saveBtnLoading={isGetDetailsLoading || isCTALoading}
            />
          </Form>
        </Col>
      </Row>
    </Page>
  );
};

export default SessionCreateOrEdit;
