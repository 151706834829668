import i18next from 'i18next';
import { LanguagesEnum } from '../models/general/lang';

const useLang = () => ({
  lang: i18next.language || localStorage.getItem('distro-current-lang') || LanguagesEnum.EN,
  isRTL: i18next.language === LanguagesEnum.AR,
  isLTR: i18next.language === LanguagesEnum.EN,
});

export default useLang;
