export const attendeesList = [
  {
    id: 0,
    name: "test",
    email: "test@test.com",
    phone: "+966501234222",
    isAbsent: false,
    price: 100,
    discountCode: null,
    discountValue: 0,
    paidPrice: 100,
  },
];

export const defaultTablePagination = {
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total: number, range: [number, number]) =>
    `${range[0]}-${range[1]} of ${total} items`,
};
