import axios from 'axios';
import { requestHandler, successHandler, errorHandler } from '../interceptors';
import { API_ENDPOINTS } from '../apiEndPoints';

export const axiosInstance = axios.create({
  baseURL: API_ENDPOINTS.BASE,
});

// Handle request process
axiosInstance.interceptors.request.use((request) => requestHandler(request));
// Handle response process
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export const externalAxiosInstance = axios.create();
