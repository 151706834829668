import { FC } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { DownOutlined, LogoutOutlined, GlobalOutlined } from '@ant-design/icons';
import { Dropdown, Modal, Layout, Avatar, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import Hamburger from '../Hamburger';
import LangDropDown from '../Language';
import styles from './index.module.scss';
import i18n from '../../../assets/i18n';
import { ROUTES } from '../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { IBaseState } from '../../../store/Base/slice';
import { useAuth } from '../../../hooks';
import { logoutRequest } from '../../../store/Auth/slice';

const { Header } = Layout;

const returnItems = (handleLogout: () => void) => {
  const items: MenuProps['items'] = [
    {
      key: '2',
      label: (
        <>
          <LogoutOutlined className={styles.userMenuIcon} /> {i18n.t('logout')}
        </>
      ),
      onClick: handleLogout,
    },
  ];
  return items;
};

const LayoutHeader: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useAuth();
  const dispatch = useDispatch();
  const { sideBar, mobileSideBar } = useSelector((state: { base: IBaseState }) => state.base);

  const logout = () => {
    dispatch(logoutRequest());
  };

  // handle logout
  const handleLogout = () => {
    Modal.confirm({
      title: t('logout'),
      content: t('areYouSureToLogout'),
      okText: t('yes'),
      cancelText: t('cancel'),
      onOk: () => {
        logout();
      },
    });
  };

  return (
    <Header
      className={cn(styles.header, {
        [styles.headerLayoutColl]: sideBar.isClosed,
        [styles.headerLayoutNotColl]: !sideBar.isClosed,
      })}>
      <Hamburger />
      <div className={styles.rightMenu}>
        <div className="dropdown-wrap">
          <Dropdown menu={{ items: returnItems(handleLogout) }}>
            <div className={styles.userWrap}>
              <Avatar shape="square" size={30} src={`${process.env.PUBLIC_URL}/assets/images/user.png`} />
              <span className={styles.userName}>{currentUser?.role && t(currentUser?.role)}</span>
              <DownOutlined />
            </div>
          </Dropdown>
        </div>
        <LangDropDown />
      </div>
    </Header>
  );
};

export default LayoutHeader;
