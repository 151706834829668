import { Button } from 'antd';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import PopconfirmComp from '../popconfirm';
import styles from './index.module.scss';

export interface IFormSubmit {
  saveBtnLoading: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  withPopConfirm?: boolean;
  popconfirmTitle?: string;
  cancelBtnText?: string;
  saveBtnText?: string;
  className?: string;
}

const FormSubmit = ({
  saveBtnLoading = false,
  handleSubmit,
  handleCancel,
  withPopConfirm = false,
  popconfirmTitle = '',
  cancelBtnText,
  saveBtnText,
  className,
}: IFormSubmit) => {
  const location = useLocation();
  return (
    <div className={`${styles.btnsWrap} ${className ?? ''} `}>
      {withPopConfirm ? (
        <PopconfirmComp title={popconfirmTitle} handleConfirm={handleSubmit}>
          <Button
            htmlType='button'
            type='primary'
            disabled={saveBtnLoading}
            loading={saveBtnLoading}
            className={styles.saveBtn}
          >
            {saveBtnText ||
              (location.pathname.includes('edit') ? t('update') : t('create'))}
          </Button>
        </PopconfirmComp>
      ) : (
        <Button
          htmlType='submit'
          type='primary'
          disabled={saveBtnLoading}
          loading={saveBtnLoading}
          className={styles.saveBtn}
          onClick={handleSubmit}
        >
          {saveBtnText ||
            (location.pathname.includes('edit') ? t('update') : t('save'))}
        </Button>
      )}

      <Button htmlType='button' className='cancel-btn' onClick={handleCancel}>
        {cancelBtnText || t('cancel')}
      </Button>
    </div>
  );
};

export default FormSubmit;
