import { Switch, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export interface ISwitchStatusProps {
  checked: boolean;
  onChange?: (val: boolean) => void;
  disabled?: boolean;
  tooltipContent?: string;
}

const SwitchStatus = ({
  checked,
  onChange,
  disabled = false,
  tooltipContent,
}: ISwitchStatusProps) =>
  tooltipContent ? (
    <Tooltip title={tooltipContent}>
      <Switch
        disabled={disabled}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={checked}
        onChange={onChange}
      />
    </Tooltip>
  ) : (
    <Switch
      disabled={disabled}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      checked={checked}
      onChange={onChange}
    />
  );

export default SwitchStatus;
