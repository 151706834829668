export const API_ENDPOINTS = {
  BASE: process.env.REACT_APP_BASE_URL,
  uploadService: '/api/v1/admin-upload',
  // deleteUploaded: '/api/v1/admin-upload',
  experiences: {
    allPaginated: '/api/v1/admin/experiences/paginated',
    add: '/api/v1/admin/experiences',
    edit: (id: string | number) => `/api/v1/admin/experiences${`/${id}`}`,
    getOne: (id: string | number) => `/api/v1/admin/experiences${`/${id}`}`,
  },
  sessions: {
    allPaginated: '/api/v1/admin/sessions/paginated',
    getOne: (id: string | number) => `/api/v1/admin/sessions${`/${id}`}`,
    add: '/api/v1/admin/sessions',
    update: (id: string | number) => `/api/v1/admin/sessions${`/${id}`}`,
  },
  lookups: {
    hobbies: '/api/v1/hobbies/lookup',
    resellers: '/api/v1/distributors/lookup',
    talents: '/api/v1/coaches/lookup',
    regions: '/api/v1/regions/lookup',
    cities: '/api/v1/cities/lookup',
  },
  auth: {
    login: 'admin/auth/login',
    refreshToken: 'admin/auth/refresh',
    updateEmail: 'admin/auth/update-email',
    updatePassword: 'admin/auth/update-password',
    updateName: 'admin/auth/update-name',
  },
} as const;
