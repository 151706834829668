import { t } from 'i18next';
import { makeSessionObj } from '../../helpers/utils';
import { ISessionLearnersRes, LanguagesItem } from '../../models';

export const formatCreateOrUpdateSessionPayload = (sessionData, languages: LanguagesItem[]) => {
  const { language, title, titleEn, location, locationDetails, locationDetailsEn } = sessionData;

  return {
    ...makeSessionObj(sessionData),
    language: languages.filter((lang) => language.indexOf(lang.id) >= 0),
    title,
    titleEn,
    location: {
      ...location,
      detailsEn: locationDetailsEn,
      details: locationDetails,
    },
    resellers: sessionData.resellers,
    talent: sessionData.talent,
    startDate: sessionData.startDate,
    startTime: sessionData.startTime,
    sessionPeriod: sessionData.sessionPeriod,
  };
};

export const returnDisableTextInCloseSession = (sessionLearners: ISessionLearnersRes) => {
  if (sessionLearners) {
    if (sessionLearners?.absent !== 0) {
      return t('theCloseSessionDisabledBecauseThereIsAbsentLearners');
    } else if (sessionLearners?.attend !== 0) {
      return t('theCloseSessionDisabledBecauseThereIsAttendedLearners');
    } else if (sessionLearners?.attendees.length === 0) {
      return t('theCloseSessionDisabledBecauseThereIsNoAttendees');
    } else if (sessionLearners?.status === 'canceled') {
      return t('theCloseSessionDisabledBecauseItsStatusIsCanceled');
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const returnResetSessionTooltipTitle = (sessionLearners: ISessionLearnersRes) => {
  if (sessionLearners?.absent !== 0) {
    return t('theResetSessionDisabledBecauseThereIsAbsentLearners');
  } else if (sessionLearners?.attend !== 0) {
    return t('theResetSessionDisabledBecauseThereIsAttendedLearners');
  } else {
    return '';
  }
};
