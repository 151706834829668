/* eslint-disable react/jsx-no-useless-fragment */
import { FC, ReactNode } from 'react';
import { useAuth } from '../../../hooks';
import { ROLES } from '../../../models';

export interface IComponentWithRoleProps {
  roles?: ROLES[];
  children: ReactNode | ReactNode[];
}

const ComponentWithRole: FC<IComponentWithRoleProps> = ({ children, roles }) => {
  const user = useAuth();
  if (user?.role && roles.includes(user?.role)) {
    return <>{children}</>;
  }
  return <></>;
};

export default ComponentWithRole;
