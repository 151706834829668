import { message } from 'antd';
import store from '../../store';
import { showHidePageLoader } from '../../store/Loader/slice';
import { t } from 'i18next';
import { loginResponse } from '../../store/Auth/slice';

export const isHandlerEnabled = (config = {}) => {
  return Object.prototype.hasOwnProperty.call(config, 'handlerEnabled') && !config.handlerEnabled ? false : true;
};

// This is used to handle remove loader only if all pending requests are resolved
let numberOfAjaxCAllPending = 0;

export const requestHandler = (request) => {
  numberOfAjaxCAllPending++;
  if (isHandlerEnabled(request)) {
    // store.dispatch(showHidePageLoader(true));
    addRequestHeaders(request);
  }
  return request;
};

export const successHandler = (response) => {
  numberOfAjaxCAllPending--;
  if (isHandlerEnabled(response)) {
    if (numberOfAjaxCAllPending === 0) {
      // store.dispatch(showHideLoader(false));
    }
  }
  return response;
};

export const errorHandler = (error) => {
  if (error.message === 'Network Error') {
    message.error(error.message || t('loginError'));
  }
  numberOfAjaxCAllPending--;
  if (isHandlerEnabled(error.config)) {
    if (numberOfAjaxCAllPending === 0) {
      // store.dispatch(showHideLoader(false));
    }
  }
  if (error.response.status === 401) {
    store.dispatch(
      loginResponse({
        user: null,
        token: null,
        role: null,
      })
    );
    clearStore();
  }

  Array.isArray(error.response.data.message) &&
    error.response.data.message?.length > 0 &&
    error.response.data.message?.map((msg) => message.error(msg));

  message.error(error.response.data.message || 'Something went wrong');
  return Promise.reject({ ...error });
};

const addRequestHeaders = (request) => {
  // authorization
  const user =
    JSON.parse(localStorage.getItem('distro-dashboard-user')) ||
    JSON.parse(sessionStorage.getItem('distro-dashboard-user'));
  const token = user?.token;
  if (token) {
    request.headers['Authorization'] = `Bearer ${token}`;
    request.headers['api-key'] = '076f36b3f28dcf0021d652959f63de6c9dfbd211c6b3f5833e6d279a03ec5dfc';
  }
  // localization
  const locale = localStorage.getItem('locale');
  if (locale) {
    request.headers['Accept-Language'] = locale;
  }
};

const clearStore = () => {
  localStorage.clear();
  sessionStorage.clear();
};
