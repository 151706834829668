import { useEffect, FC } from "react";
import { t } from "i18next";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import styles from "./index.module.scss";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const Loading: FC = () => {
  useEffect(() => {
    NProgress.start();
    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loaderInnerWrap}>
        <div className={styles.loader} />
        <p className={styles.loadingText}>{t("isLoading")}</p>
      </div>
    </div>
  );
};

export default Loading;
