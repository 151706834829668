import { API_ENDPOINTS } from '../network/apiEndPoints';

export interface PaginationParams {
  _limit: number;
  _page: number;
  _totalRows: number;
}

export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

type NestedValueOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? NestedValueOf<ObjectType[Key]>
    : ObjectType[Key];
}[keyof ObjectType & (string | number)];

type ListResultNames = 'experiences' | 'languages' | 'regions' | 'list';

export interface ListResponse<T> {
  hitsPerPage: number;
  nbHits: number;
  npPages: number;
  items: T[];
}

export interface DataResponse<T> {
  data: T;
  message: string;
  status: boolean;
  statusCode: number;
  success: boolean;
  verified: boolean;
}
export interface ListParams {
  page?: number;
  limit?: number;
  _sort?: string;
  _order?: 'asc' | 'desc';
}

export type ReqResponse<responseData = any> = {
  data: responseData;
  message: string;
  statusCode: number;
  success: boolean;
};

export type Status = {
  isEnabled: boolean;
  objectId: string;
};

export interface ListResponseWOPaginate<T> {
  data: {
    [key in ListResultNames]?: T[];
  };
}

export interface ListResponseArr<T> {
  items: T[];
}

export enum FormatDateEnum {
  YMD = 'YYYY/MM/DD',
  YMDHM = 'YYYY/MM/DD HH:mm',
  HM = 'HH:mm',
  YMDHMA = 'YYYY/MM/DD - hh:mm A',
  YMDEXPORT = 'YYYY-MM-DD',
}

export type ApiEndPointsValuesType = NestedValueOf<typeof API_ENDPOINTS>;

export interface Translation {
  translatedText: string;
}

export interface IUploadImageRes {
  url: string;
}
export interface IUploadImageReq {
  image: string;
}

export type CreateOrEditMode = 'create' | 'edit';

export interface GeneralParamsObj extends Record<string, any> {
  limit?: number;
  page?: number;
  sortBy?: string;
}

export type KeyofTypeofObj<T> = keyof T;
export interface LogsItem {
  component: string;
  name: string;
  objectID: string;
  description: string;
  descriptiveData: Record<string, any>;
  editedBy: string;
  timestamp: string;
  from: Record<string, any>;
  to: Record<string, any>;
  type: string;
}

export interface ICreateOrEditProps {
  isEdit?: boolean;
}
export interface ICreateOrUpdateItem<T> {
  id?: string;
  onSuccess: () => void;
  payload: T;
}

export interface ItemsArrayType<T> {
  items: T[];
}

export interface ICountriesItem {
  nameAr: string;
  nameEn: string;
  id: string;
  cities: string[];
  name: string;
}

export interface IPaymentCategoriesItem {
  isVatOfSupliftPercentOnly: boolean;
  percent: number;
  isDefault: boolean;
  taxPercent: number;
  key: string;
  ratio: number;
  isEnabled: boolean;
  objectID: string;
  percentRatio: number;
}

export interface ITreeItemType {
  title: string;
  value: string;
  key: string;
}

export interface ITreeData extends ITreeItemType {
  children: ITreeItemType[];
}
export interface IUploadRes {
  src: string;
  fileName: string;
}
