import { FC, useEffect, useState } from 'react';
import { DatePicker, Form, InputNumber, Select, TimePicker } from 'antd';
import { FormSubmit } from '../../../components';
import { RULES } from '../../../helpers/constants';
  
interface Option {
  id: number;
  label: string;
  value: string;
}

const bulkActionOptions: Option[] = [
  { id: 1, label: 'Delete', value: 'delete' },
  { id: 2, label: 'Reseller', value: 'reseller' },
  { id: 3, label: 'Talent', value: 'talent' },
  { id: 4, label: 'City', value: 'city' },
  { id: 5, label: 'Hobby', value: 'hobby' },
  { id: 6, label: 'Coach Price', value: 'coach_price' },
  { id: 7, label: 'Suplift Price', value: 'suplift_price' },
  { id: 8, label: 'Seats', value: 'seats' },
  { id: 9, label: 'Session time', value: 'session_time' },
  { id: 10, label: 'Session Date', value: 'session_date' },
];

interface ThirdOption {
  id: number;
  label: string;
  value: string;
}

const TalentBulkActions: FC = () => {
  const [form] = Form.useForm<any>();
  const [bulkSelectedOption, setBulkSelectedOption] = useState<string | null>(null);
  const [isShowSecondOptionDropDown, setIsShowSecondOptionDropDown] = useState<boolean>(false);
  const [isShowFixedSecondOption, setIsShowFixedSecondOption] = useState<boolean>(false);
  const [isShowNumberInput, setIsShowNumberInput] = useState<boolean>(false);
  const [isShowDateInput, setIsShowDateInput] = useState<boolean>(false);
  const [isShowTimeInput, setIsShowTimeInput] = useState<boolean>(false);
  const [isShowThirdOptionDropDown, setIsShowThirdOptionDropDown] = useState<ThirdOption[]>([]);

  useEffect(() => {
    if (['reseller', 'talent', 'city', 'hobby'].includes(bulkSelectedOption || '')) {
      if (bulkSelectedOption === 'reseller') {
        setIsShowSecondOptionDropDown(true);
        setIsShowFixedSecondOption(false);
      } else {
        setIsShowFixedSecondOption(true);
        setIsShowSecondOptionDropDown(true);
      }
      setIsShowNumberInput(false);
      setIsShowDateInput(false);
      setIsShowTimeInput(false);
      renderThirdDropDownOptions(bulkSelectedOption);
    } else if (['coach_price', 'suplift_price', 'seats'].includes(bulkSelectedOption || '')) {
      setIsShowThirdOptionDropDown([]);
      setIsShowFixedSecondOption(true);
      setIsShowSecondOptionDropDown(true);
      setIsShowNumberInput(true);
      setIsShowDateInput(false);
      setIsShowTimeInput(false);
    } else if (['session_time', 'session_date'].includes(bulkSelectedOption || '')) {
      setIsShowThirdOptionDropDown([]);
      setIsShowFixedSecondOption(true);
      setIsShowSecondOptionDropDown(true);
      setIsShowNumberInput(false);
      if (bulkSelectedOption === 'session_time') {
        setIsShowTimeInput(true);
        setIsShowDateInput(false);
      } else {
        setIsShowDateInput(true);
        setIsShowTimeInput(false);
      }
    } else {
      setIsShowThirdOptionDropDown([]);
      setIsShowFixedSecondOption(false);
      setIsShowSecondOptionDropDown(false);
      setIsShowNumberInput(false);
      setIsShowDateInput(false);
      setIsShowTimeInput(false);
    }
  }, [bulkSelectedOption]);

  const renderThirdDropDownOptions = (value: string | null) => {
    if (!value) return;
    switch (value) {
      case 'reseller':
        setIsShowThirdOptionDropDown([
          { id: 1, label: 'Reseller 1', value: 'reseller_1' },
          { id: 2, label: 'Reseller 2', value: 'reseller_2' },
          { id: 3, label: 'Reseller 3', value: 'reseller_3' },
        ]);
        break;
      case 'talent':
        setIsShowThirdOptionDropDown([
          { id: 1, label: 'Talent 1', value: 'talent_1' },
          { id: 2, label: 'Talent 2', value: 'talent_2' },
          { id: 3, label: 'Talent 3', value: 'talent_3' },
        ]);
        break;
      case 'city':
        setIsShowThirdOptionDropDown([
          { id: 1, label: 'City 1', value: 'city_1' },
          { id: 2, label: 'City 2', value: 'city_2' },
          { id: 3, label: 'City 3', value: 'city_3' },
        ]);
        break;
      case 'hobby':
        setIsShowThirdOptionDropDown([
          { id: 1, label: 'Hobby 1', value: 'hobby_1' },
          { id: 2, label: 'Hobby 2', value: 'hobby_2' },
          { id: 3, label: 'Hobby 3', value: 'hobby_3' },
        ]);
        break;
      default:
        break;
    }
  };

  const styleForm = {
    padding: '1rem',
    border: '1px solid #d9d9d9',
    background: '#fff',
    marginBottom: '3rem',
    borderRadius: '0.5rem',
  }

  return (
    <Form style={styleForm} form={form} layout="vertical">
      <Form.Item name={'pick_action'} label={`${'Pick action'}:`} rules={[RULES.required]}>
        <Select
          filterOption
          optionFilterProp="label"
          onChange={(value: string) => {
            setBulkSelectedOption(value);
          }}
          showSearch
          placeholder={'pick action'}
          getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          options={bulkActionOptions}
        />
      </Form.Item>
      {isShowSecondOptionDropDown && (
        <Form.Item name={'Pick Action'} label={`${'Pick Action'}:`} rules={[RULES.required]}>
          <Select
            filterOption
            optionFilterProp="label"
            onChange={(value: string) => {}}
            showSearch
            placeholder={'Pick Action'}
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            options={[
              { id: 1, label: 'set to', value: 'set_to' },
              { id: 2, label: 'Add', value: 'add' },
            ]}
            disabled={isShowFixedSecondOption}
            defaultValue={'set_to'}
          />
        </Form.Item>
      )}
      {isShowThirdOptionDropDown?.length > 0 && (
        <Form.Item name={'To'} label={`${'To'}:`} rules={[RULES.required]}>
          <Select
            filterOption
            optionFilterProp="label"
            onChange={(value: string) => {}}
            showSearch
            placeholder={'To'}
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            options={isShowThirdOptionDropDown}
          />
        </Form.Item>
      )}
      {isShowNumberInput && (
        <Form.Item name={'To'} label={`${'To'}:`} rules={[RULES.required]}>
          <InputNumber addonAfter={bulkSelectedOption !== 'seats' && 'SAR'} className="w-100" />
        </Form.Item>
      )}
      {isShowDateInput && (
        <Form.Item name={'To'} label={`${'To'}:`} rules={[RULES.required]}>
          <DatePicker onChange={() => {}} className="w-100" />
        </Form.Item>
      )}
      {isShowTimeInput && (
        <Form.Item name={'To'} label={`${'To'}:`} rules={[RULES.required]}>
          <TimePicker onChange={() => {}} className="w-100" />
        </Form.Item>
      )}
      <FormSubmit
        handleSubmit={() => {}}
        saveBtnLoading={false}
        withPopConfirm={true}
        popconfirmTitle={'Are you sure you want to perform this action?'}
        hasCancelBtn={false}
        saveBtnText="Apply"
      />
    </Form>
  );
};

export default TalentBulkActions;
