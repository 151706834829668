import { Select, Form } from 'antd';
import { t } from 'i18next';
import { RULES } from '../../../helpers/constants';
import { useLang } from '../../../hooks';
import { RegionItem } from '../../../models';

export interface IRegionsProps {
  regionsList: RegionItem[];
  isOnline?: boolean;
  handleChange?: (regionId: string) => void;
  fieldName?: string;
}

export const Regions = ({
  regionsList,
  isOnline = false,
  handleChange,
  fieldName = 'region',
}: IRegionsProps) => {
  const { isLTR } = useLang();

  return (
    <Form.Item
      name={fieldName}
      label={`${t('region')}:`}
      rules={isOnline ? [] : [RULES.required]}
    >
      <Select
        filterOption
        optionFilterProp='label'
        onChange={(value: string) => {
          if (handleChange) {
            handleChange(value);
          }
        }}
        showSearch
        placeholder={t('selectRegion')}
        getPopupContainer={trigger => trigger.parentNode}
        options={regionsList?.map((region: RegionItem) => ({
          label: isLTR ? region?.nameEn : region?.nameAr,
          value: region.id,
        }))}
      />
    </Form.Item>
  );
};

export default Regions;
