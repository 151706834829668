import { ReactNode } from 'react';
import { Popconfirm } from 'antd';
import { t } from 'i18next';

export interface IPopconfirmCompProps {
  title: string;
  okText?: string;
  children: ReactNode | ReactNode[];
  cancelText?: string;
  handleConfirm?: () => void;
  className?: string;
}

const PopconfirmComp = ({
  title,
  okText = t('ok'),
  children,
  cancelText = t('cancel'),
  className = '',
  handleConfirm,
}: IPopconfirmCompProps) => (
  <Popconfirm
    className={className}
    okButtonProps={{ htmlType: 'submit' }}
    title={title}
    okText={okText}
    cancelText={cancelText}
    onConfirm={handleConfirm}
  >
    {children}
  </Popconfirm>
);

export default PopconfirmComp;
