import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { message } from 'antd';
import { t } from 'i18next';

export function displayErrorsOnFail<T = any>(errors: ValidateErrorEntity<T>) {
  const errorFields = errors?.errorFields;
  message.destroy();
  if (errorFields && Array.isArray(errorFields) && errorFields.length > 0) {
    errorFields.forEach(errorField =>
      message.error(
        `${t('errorIn')} ${errorField?.name?.[0]} : ${errorField?.errors?.[0]}`
      )
    );
  }
}
