import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { HobbiesLookups, HobbiesLookupsFilters } from '../../models/lookups/hobbies-lookups/hobbies-lookups';
import { ResellersLookups, ResellersLookupsFilters } from '../../models/lookups/resellers-lookups/resellers-lookups';
import { TalentsLookups, TalentsLookupsFilters } from '../../models/lookups/talents-lookups/talents-lookups';
import { ExperienceDto, RegionItem } from './../../models/experiance/experience';

interface initialListType {
  list: ExperienceDto[];
  currentPage: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  isLoading: boolean;
  isAddedOrEdited: boolean;
  experienceDetails: ExperienceDto | null;
  lookUpHobbiesList: {
    list: HobbiesLookups[];
    isLoading: boolean;
    filtersData?: HobbiesLookupsFilters;
  };
  lookUpResellersList: {
    list: ResellersLookups[];
    isLoading: boolean;
    filtersData?: ResellersLookupsFilters;
  };
  lookUpTalentsList: {
    list: TalentsLookups[];
    isLoading: boolean;
    filtersData?: TalentsLookupsFilters;
  };
  lookUpRegionsList: {
    list: RegionItem[];
    isLoading: boolean;
    filtersData?: any;
  };
  lookUpCitiesList: {
    list: RegionItem[];
    isLoading: boolean;
    filtersData?: any;
  };
}

const initialState: initialListType = {
  list: [],
  isLoading: false,
  currentPage: 1,
  pageSize: 25,
  totalCount: 0,
  totalPages: 0,
  isAddedOrEdited: false,
  experienceDetails: null,
  lookUpHobbiesList: {
    list: [],
    isLoading: false,
    filtersData: null,
  },
  lookUpResellersList: {
    list: [],
    isLoading: false,
    filtersData: null,
  },
  lookUpTalentsList: {
    list: [],
    isLoading: false,
    filtersData: null,
  },
  lookUpRegionsList: {
    list: [],
    isLoading: false,
    filtersData: null,
  },
  lookUpCitiesList: {
    list: [],
    isLoading: false,
    filtersData: null,
  },
};

export const experiencesSlice = createSlice({
  name: 'experiences',
  initialState,
  reducers: {
    // Get All
    getExperiencesPaginatedRequest: (state: initialListType, action) => {
      state.isLoading = true;
      if (action.payload?.currentPage) state.currentPage = action.payload?.currentPage;
      if (action.payload?.pageSize) state.pageSize = action.payload?.pageSize;
    },
    getExperiencesPaginatedResponse: (state: initialListType, action) => {
      state.list = action.payload?.data;
      state.currentPage = action.payload?.currentPage;
      state.pageSize = action.payload?.pageSize;
      state.totalCount = action.payload?.totalCount;
      state.totalPages = action.payload?.totalPages;
      state.isLoading = false;
    },
    clearGetExperiencesListPage: (state: initialListType) => {
      state.list = [];
      state.currentPage = 1;
      state.pageSize = 25;
      state.totalCount = 0;
      state.totalPages = 0;
      state.isLoading = false;
      state.lookUpHobbiesList = {
        list: [],
        isLoading: false,
        filtersData: null,
      };
      state.lookUpResellersList = {
        list: [],
        isLoading: false,
        filtersData: null,
      };
      state.lookUpTalentsList = {
        list: [],
        isLoading: false,
        filtersData: null,
      };
    },
    // Add New
    addNewExperienceRequest: (state: initialListType, action) => {
      state.isLoading = true;
    },
    addNewExperienceResponse: (state: initialListType, action) => {
      state.isLoading = false;
      state.isAddedOrEdited = action.payload?.isAddedOrEdited;
      action.payload?.isAddedOrEdited
        ? message.success('Experience Added Successfully')
        : message.error('Failed to Add');
    },
    changeOneKeyOfExperienceState: (state: initialListType, action) => {
      state[action.payload.key] = action.payload.value;
    },
    // Get One
    getExperienceRequest: (state: initialListType, action) => {
      state.isLoading = true;
    },
    getExperienceResponse: (state: initialListType, action) => {
      state.isLoading = action.payload.isLoading;
      state.experienceDetails = action.payload.experienceData;
    },

    // Update
    updateExperienceRequest: (state: initialListType, action) => {
      state.isLoading = true;
    },
    updateExperienceResponse: (state: initialListType, action) => {
      state.isLoading = false;
      state.isAddedOrEdited = action.payload?.isAddedOrEdited;
      action.payload?.isAddedOrEdited
        ? message.success('Experience Updated Successfully')
        : message.error('Failed to Update');
    },

    // Get Lookups
    getLookUpHobbiesRequest: (state: initialListType, action) => {
      state.lookUpHobbiesList.isLoading = true;
      if (action.payload?.filtersData) {
        if (action.payload?.filtersData?.search)
          state.lookUpHobbiesList.filtersData.search = action.payload?.filtersData?.search;
        if (action.payload?.filtersData?.sortBy)
          state.lookUpHobbiesList.filtersData.sortBy = action.payload?.filtersData?.sortBy;
      } else {
        state.lookUpHobbiesList.filtersData = null;
      }
    },
    getLookUpHobbiesResponse: (state: initialListType, action) => {
      state.lookUpHobbiesList.list = action.payload;
      state.lookUpHobbiesList.isLoading = false;
    },
    getLookUpResellersRequest: (state: initialListType, action) => {
      state.lookUpResellersList.isLoading = true;
      if (action.payload?.filtersData) {
        if (action.payload?.filtersData?.search)
          state.lookUpHobbiesList.filtersData.search = action.payload?.filtersData?.search;
        if (action.payload?.filtersData?.sortBy)
          state.lookUpHobbiesList.filtersData.sortBy = action.payload?.filtersData?.sortBy;
      } else {
        state.lookUpHobbiesList.filtersData = null;
      }
    },
    getLookUpResellersResponse: (state: initialListType, action) => {
      state.lookUpResellersList.list = action.payload;
      state.lookUpResellersList.isLoading = false;
    },
    getLookUpTalentsRequest: (state: initialListType, action) => {
      state.lookUpTalentsList.isLoading = true;
      if (action.payload?.filtersData) {
        if (action.payload?.filtersData?.search)
          state.lookUpHobbiesList.filtersData.search = action.payload?.filtersData?.search;
        if (action.payload?.filtersData?.sortBy)
          state.lookUpHobbiesList.filtersData.sortBy = action.payload?.filtersData?.sortBy;
      } else {
        state.lookUpHobbiesList.filtersData = null;
      }
    },
    getLookUpTalentsResponse: (state: initialListType, action) => {
      state.lookUpTalentsList.list = action.payload;
      state.lookUpTalentsList.isLoading = false;
    },
    getLookUpRegionsRequest: (state: initialListType, action) => {
      state.lookUpRegionsList.isLoading = true;
      if (action.payload?.filtersData) {
        if (action.payload?.filtersData?.search)
          state.lookUpHobbiesList.filtersData.search = action.payload?.filtersData?.search;
        if (action.payload?.filtersData?.sortBy)
          state.lookUpHobbiesList.filtersData.sortBy = action.payload?.filtersData?.sortBy;
      } else {
        state.lookUpHobbiesList.filtersData = null;
      }
    },
    getLookUpRegionsResponse: (state: initialListType, action) => {
      state.lookUpRegionsList.list = action.payload;
      state.lookUpRegionsList.isLoading = false;
    },
    getLookUpCitiesListRequest: (state: initialListType, action) => {
      state.lookUpCitiesList.isLoading = true;
      if (action.payload?.filtersData) {
        if (action.payload?.filtersData?.search)
          state.lookUpCitiesList.filtersData.search = action.payload?.filtersData?.search;
        if (action.payload?.filtersData?.sortBy)
          state.lookUpCitiesList.filtersData.sortBy = action.payload?.filtersData?.sortBy;
      } else {
        state.lookUpCitiesList.filtersData = null;
      }
    },
    getLookUpCitiesListResponse: (state: initialListType, action) => {
      state.lookUpCitiesList.list = action.payload;
      state.lookUpCitiesList.isLoading = false;
    },
  },
});

export const {
  getExperiencesPaginatedRequest,
  getExperiencesPaginatedResponse,
  getLookUpHobbiesRequest,
  getLookUpHobbiesResponse,
  getLookUpResellersRequest,
  getLookUpResellersResponse,
  getLookUpTalentsRequest,
  getLookUpTalentsResponse,
  addNewExperienceRequest,
  addNewExperienceResponse,
  clearGetExperiencesListPage,
  getLookUpRegionsRequest,
  getLookUpRegionsResponse,
  getLookUpCitiesListRequest,
  getLookUpCitiesListResponse,
  changeOneKeyOfExperienceState,
  getExperienceRequest,
  getExperienceResponse,
  updateExperienceRequest,
  updateExperienceResponse,
} = experiencesSlice.actions;
export default experiencesSlice.reducer;
