import { PlusOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { Button } from 'antd';
import styles from './index.module.scss';

export interface IUploadBtnProps {
  onClick?: () => void;
  loading?: boolean;
}

const UploadBtn = ({ onClick, loading = false }: IUploadBtnProps) => (
  <Button
    loading={loading}
    htmlType='button'
    onClick={onClick}
    type='ghost'
    className={styles.uploadBtn}
  >
    <PlusOutlined />
    <span>{loading ? t('uploading') : t('upload')}</span>
  </Button>
);

export default UploadBtn;
