import { Form, Select } from 'antd';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RULES } from '../../../helpers/constants';
import { useLang } from '../../../hooks';
import { HobbyItem } from '../../../models';
import { getLookUpHobbiesRequest } from '../../../store/Experience/slice';
import UseExpForm, { useExpForm } from '../../../pages/experiences/helpers/useExpForm';

interface HobbiesProps {
  fieldName?: string;
  placeHolder?: string;
  label?: string;
  required?: boolean;
  name?: string;
}

const Hobbies = ({
  fieldName = 'hobbies',
  label = `${t('hobbies')} :`,
  placeHolder = t('selectHobby'),
  required = false,
  name = 'hobbiesArr',
}: HobbiesProps) => {
  const dispatch = useDispatch();

  const  lookUpHobbiesList  = useSelector((state: { experiences: { lookUpHobbiesList: { list: any[] } } }) => state.experiences.lookUpHobbiesList.list);

  const getHobbies = () => {
    dispatch(getLookUpHobbiesRequest(null));
  };

  const { isLTR } = useLang();

  useEffect(() => {
    if (!lookUpHobbiesList || lookUpHobbiesList?.length === 0) {
      getHobbies();
    }
  }, []);

  return (
    <Form.Item rules={[required ? RULES.required : null]} name={name ?? fieldName} label={label}>
      <Select
        showSearch
        placeholder={placeHolder ?? t('selectHobby')}
        optionFilterProp="children"
        mode="multiple"
        getPopupContainer={(trigger) => trigger.parentNode}
        options={lookUpHobbiesList?.map((hobby: HobbyItem) => ({
          label: isLTR ? hobby?.nameEn : hobby?.name,
          value: hobby.id,
        }))}
      />
    </Form.Item>
  );
};

export default Hobbies;
