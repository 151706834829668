import { message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { t } from 'i18next';
import { centerCrop, makeAspectCrop } from 'react-image-crop';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import {
  ImageItem,
  IExperienceAddOrEditRequest,
  IUploadRes,
  User,
  IAddExperienceDTO,
  IEditExperienceDTO,
} from '../../models';
import { API_ENDPOINTS } from '../../network/apiEndPoints';
import { useSelector } from 'react-redux';
import { useAuth } from '../../hooks';
import store from '../../store';
import { axiosInstance } from '../../network/apis';
// import Cookies from 'js-cookie';

export const convertImageToFileList = (imgSrc: string, fileName: string = 'image') =>
  ({
    uid: uuidv4(),
    name: /^data:image\/.*;base64/.test(imgSrc) ? fileName : imgSrc.substring(imgSrc.lastIndexOf('/') + 1),
    status: 'done',
    thumbUrl: imgSrc,
    url: imgSrc,
  } as const);

export const convertImagesToFileList = (images: string[], fileName: string = 'image') =>
  images.map((img, index) => convertImageToFileList(img, `${fileName}-${index}`));

export const getBase64 = (file: RcFile, callback: (readedFile: string | ArrayBuffer | null) => void) => {
  const reader = new FileReader();

  reader.addEventListener('load', () => {
    if (callback) {
      callback(reader.result);
    }
  });
  reader.readAsDataURL(file);
};
// check file type  based on props
export const checkFileType = (fileType: string, fileTypes: string[]) => {
  if (!fileTypes || (fileTypes && fileTypes[0] === '*')) {
    return true;
  }
  if (fileTypes && Array.isArray(fileTypes) && fileTypes.length > 0 && fileTypes.indexOf(fileType) !== -1) {
    return true;
  }
  return false;
};

// validate file size based on props
export const isValidFileSize = (fileSize: number, maxSizeInMB: number): boolean => {
  if (maxSizeInMB) {
    return fileSize / 1024 / 1024 < maxSizeInMB;
  }
  return true;
};

// validate file size and type
export const validateFile = (file: RcFile, fileTypes: string[], maxSizeInMB: number) => {
  const isValidFileType = checkFileType(file.type, fileTypes as string[]);
  const isValidSize = isValidFileSize(file.size, maxSizeInMB as number);
  let errorMessage;
  if (!isValidSize) {
    errorMessage = `${(t('fileSizeExceedsTheLimit'), { fileSize: `${maxSizeInMB}MB` })}`;
  }
  if (!isValidFileType) {
    errorMessage = `${t('fileTypeNotSupported', {
      supportedTypes: fileTypes.join(', '),
    })}`;
  }
  if (errorMessage) {
    message.error(errorMessage);
  }
  return isValidFileType && isValidSize;
};
export const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) =>
  centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 80,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );

export const checkImageBase64Link = (imageLink: string) => {
  const regex = /^data:image\/\w+;base64,/;
  return regex.test(imageLink);
};
export const convertImagesToArr = (imagesArr: string[], mainImgIndex: number) => {
  return imagesArr?.map((img, index) => ({ url: img, isMain: index === mainImgIndex })) as ImageItem[] | [];
};

export const convertImagesResToArr = (images: ImageItem[], callback?: (imgIndex: number) => void) => {
  const imagesStrArr: string[] = [];
  if (images && Array.isArray(images) && images.length > 0) {
    images.forEach((img: ImageItem, index: number) => {
      imagesStrArr.push(img.url);
      if (img.isMain && callback) {
        callback(index);
      }
    });
  }
  return imagesStrArr;
};

export const sanitizeBase64URL = (base64URL: string) => base64URL.replace(/data:image\/.*;base64,/g, '');

export const base64ToArrayBuffer = (base64Url: string) => {
  let base64 = base64Url.replaceAll('-', '+');
  base64 = base64.replaceAll('_', '/');
  const binaryString = window.atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const convertBase64ToFile = (base64URL: string, index: number) =>
  new File([base64ToArrayBuffer(sanitizeBase64URL(base64URL))], `croppedImage${index}.jpeg`, {
    type: 'image/jpeg',
  });

export const handleExperienceFilesBeforeUpload = async (
  payload: IAddExperienceDTO,
  mainImageIndex: number,
  cameFrom?: 'experience'
) => {
  const finalPayload = { ...payload };
  const { image, croppedImages, banner, video } = payload;
  const uploadImagesFiles = [];
  const notUploadImagesFiles = [];
  const uploadCroppedImagesFiles = [];
  const notUploadCroppedImagesFiles = [];
  if (Array.isArray(image)) {
    image.forEach((imageItem, index) => {
      const croppedImage = croppedImages[index];
      if (typeof croppedImage === 'string' && checkImageBase64Link(croppedImage)) {
        uploadCroppedImagesFiles.push(convertBase64ToFile(croppedImage, index));
      } else {
        notUploadCroppedImagesFiles.push(croppedImage);
      }
      if (typeof imageItem === 'string') {
        notUploadImagesFiles.push(imageItem);
      } else {
        uploadImagesFiles.push(imageItem);
      }
    });
  }

  const uploadReqPayload = {
    'experience/image': uploadImagesFiles?.length > 0 ? uploadImagesFiles : undefined,
    // 'experience/croppedImage': uploadCroppedImagesFiles?.length > 0 ? uploadCroppedImagesFiles : undefined,
    'experience/video': video instanceof File ? video : undefined,
    'experience/coverImage': banner?.image instanceof File ? banner?.image : undefined,
    environment: process.env.REACT_APP_ENVIRONMENT,
  };
  const formData = new FormData();
  for (const key in uploadReqPayload) {
    if (uploadReqPayload[key]) {
      if (Array.isArray(uploadReqPayload[key])) {
        uploadReqPayload[key].forEach((arrItem) => {
          formData.append('files', arrItem);
        });
      } else {
        formData.append('files', uploadReqPayload[key]);
      }
    }
  }
  formData.append('location', cameFrom);

  const userToken = store.getState().auth.token as string;

  const { data } = await axiosInstance.post<IUploadRes[]>(API_ENDPOINTS.uploadService, formData, {
    headers: { Authorization: userToken },
  });

  return {
    ...finalPayload,
    image: data.map((img) => img.src),
  };
};

export const handleExperienceFilesBeforeUploadToEdit = async (
  payload: IEditExperienceDTO,
  mainImageIndex: number,
  deletedIds?: string[],
  experienceDetails?: IGetExperienceDTO,
  cameFrom?: 'experience',
  isEdit?: boolean
) => {
  const finalPayload = { ...payload };
  const { image, croppedImages, banner, video } = payload;
  const uploadImagesFiles = [];
  const notUploadImagesFiles = [];
  const uploadCroppedImagesFiles = [];
  const notUploadCroppedImagesFiles = [];
  if (Array.isArray(image)) {
    image.forEach((imageItem, index) => {
      const croppedImage = croppedImages[index];
      if (typeof croppedImage === 'string' && checkImageBase64Link(croppedImage)) {
        uploadCroppedImagesFiles.push(convertBase64ToFile(croppedImage, index));
      } else {
        notUploadCroppedImagesFiles.push(croppedImage);
      }
      if (typeof imageItem === 'string') {
        notUploadImagesFiles.push(imageItem);
      } else {
        uploadImagesFiles.push(imageItem);
      }
    });
  }

  const uploadReqPayload = {
    'experience/image': uploadImagesFiles?.length > 0 ? uploadImagesFiles : undefined,
    // 'experience/croppedImage': uploadCroppedImagesFiles?.length > 0 ? uploadCroppedImagesFiles : undefined,
    'experience/video': video instanceof File ? video : undefined,
    'experience/coverImage': banner?.image instanceof File ? banner?.image : undefined,
  };
  const formData = new FormData();

  for (const key in uploadReqPayload) {
    if (uploadReqPayload[key]) {
      if (Array.isArray(uploadReqPayload[key])) {
        uploadReqPayload[key].forEach((arrItem) => {
          formData.append('files', arrItem);
        });
      } else {
        formData.append('files', uploadReqPayload[key]);
      }
    }
  }
  formData.append('location', cameFrom);

  const userToken = store.getState().auth.token as string;

  const { data } = await axiosInstance.post<IUploadRes[]>(API_ENDPOINTS.uploadService, formData, {
    headers: { Authorization: userToken },
  });

  let removedData = [];

  if(isEdit && deletedIds?.length > 0) {
    deletedIds.forEach((el) => {
      if(experienceDetails?.attachments?.length > 0) {
        const removedItem = experienceDetails?.attachments?.find((item) => item.src === el);
        if(removedItem) {
          removedData.push(removedItem.id);
        }
      }
    });
    
  }
  
  return {
    ...finalPayload,
    image: data.map((img) => img.src),
    addedAttachmentUrls: isEdit && data.map((img) => img.src),
    removedAttachmentIds: removedData,
  };
};
