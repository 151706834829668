import { axiosInstance } from '.';
import { CityData, RegionItem } from '../../models';
import { ApiResponse } from '../../models/general/apiResult';
import { HobbiesLookups, HobbiesLookupsFilters } from '../../models/lookups/hobbies-lookups/hobbies-lookups';
import { ResellersLookupsFilters } from '../../models/lookups/resellers-lookups/resellers-lookups';
import { TalentsLookups, TalentsLookupsFilters } from '../../models/lookups/talents-lookups/talents-lookups';
import { API_ENDPOINTS } from '../apiEndPoints';

// lookup
const getHobbiesLookup = async (payload: HobbiesLookupsFilters): Promise<ApiResponse<HobbiesLookups[]>> =>
  await axiosInstance.get(API_ENDPOINTS.lookups.hobbies, {
    params: payload,
  });

const getResellersLookup = async (payload: ResellersLookupsFilters): Promise<ApiResponse<TalentsLookups[]>> =>
  await axiosInstance.get(API_ENDPOINTS.lookups.resellers, {
    params: payload,
  });

const getTalentsLookup = async (payload: TalentsLookupsFilters): Promise<ApiResponse<TalentsLookups[]>> =>
  await axiosInstance.get(API_ENDPOINTS.lookups.talents, {
    params: payload,
  });

const getRegionsLookup = async (payload: TalentsLookupsFilters): Promise<ApiResponse<RegionItem[]>> =>
  await axiosInstance.get(API_ENDPOINTS.lookups.regions, {
    params: payload,
  });

const getCitiesLookup = async (payload: TalentsLookupsFilters): Promise<ApiResponse<CityData[]>> =>
  await axiosInstance.get(API_ENDPOINTS.lookups.cities, {
    params: payload,
  });

export { getCitiesLookup, getHobbiesLookup, getRegionsLookup, getResellersLookup, getTalentsLookup };

