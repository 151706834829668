import { Form, Select } from 'antd';
import { t } from 'i18next';
import { RULES } from '../../../helpers/constants';
import { CoachLite } from '../../../models';

export interface ICoachesListProps {
  coachesList: CoachLite[];
  formItemName?: string;
}

const CoachesSelect = ({
  coachesList = [],
  formItemName = 'coach',
}: ICoachesListProps) => (
  <Form.Item
    name={formItemName}
    label={`${t('coachName')}:`}
    rules={[RULES.required]}
  >
    <Select
      showSearch
      placeholder={t('selectCoach')}
      optionLabelProp='label'
      filterOption={(input, option) => (option?.label ?? '').includes(input)}
      options={coachesList?.map((coach: CoachLite) => ({
        label: coach.name,
        value: coach.objectID,
      }))}
    />
  </Form.Item>
);

export default CoachesSelect;
