import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './sagas';
import loaderReducer from './Loader/slice';
import baseReducer from './Base/slice';
import authReducer from './Auth/slice';
import ExperienceReducer from './Experience/slice';
import SessionsReducer from './Session/slice';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    loader: loaderReducer,
    auth: authReducer,
    base: baseReducer,
    experiences: ExperienceReducer,
    sessions: SessionsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
