import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import LangDropDown from "../Language";
import styles from "./index.module.scss";

export interface IPublicPagesWrapperProps {
  children: ReactNode;
}

const PublicPagesWrapper: FC<IPublicPagesWrapperProps> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div
      className={styles.loginWrap}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/login-bg.svg)`,
      }}
    >
      <div className={styles.languageBar}>
        <LangDropDown />
      </div>

      <div className={styles.loginCardWrap}>
        <div className={styles.loginCardInner}>
          <div className={styles.logoWrap}>
            <img
              alt={t("suplift")}
              title={t("suplift")}
              src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
            />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PublicPagesWrapper;
