type TablePaginationPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';
export const defaultTablePagination = {
  position: ['bottomCenter'] as TablePaginationPosition[],
  showQuickJumper: false,
  responsive: true,
  showSizeChanger: false,
};
