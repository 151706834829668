import { FC, Suspense } from 'react';
import { Layout } from 'antd';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import s from './index.module.scss';
import { ROUTES } from '../../../models';
import { Loader } from '../../ui';

const { Content } = Layout;

const LayoutContent: FC = () => {
  const location = useLocation();
  const { pathname } = location;

  if (pathname === ROUTES.HOME) {
    return <Navigate to={ROUTES.EXPERIENCES} />;
  }
  return (
    <div className={s.content}>
      <Content className={s.contentInner}>
        <TransitionGroup>
          <CSSTransition
            key={pathname}
            timeout={500}
            classNames='fade'
            exit={false}
          >
            <Suspense fallback={<Loader />}>
              <Outlet />
            </Suspense>
          </CSSTransition>
        </TransitionGroup>
      </Content>
    </div>
  );
};

export default LayoutContent;
