import { axiosInstance } from '.';
import { ApiPaginatedResponse } from '../../models/general/pagentation';
import { GetAllSessionDTO, PostSessionRequest, SessionsPaginationFilterParams } from '../../models/session/session';
import { API_ENDPOINTS } from '../apiEndPoints';

const getAllSessionsPaginated = async (
  payload: SessionsPaginationFilterParams
): Promise<ApiPaginatedResponse<GetAllSessionDTO[]>> =>
  await axiosInstance.get(API_ENDPOINTS.sessions.allPaginated, {
    params: payload,
  });

const getSessionDetails = async (id: number) => {
  return await axiosInstance.get(API_ENDPOINTS.sessions.getOne(id));
}

const addNewSession = async (payload: PostSessionRequest) => {
  return await axiosInstance.post(API_ENDPOINTS.sessions.add, payload);
}

const updateSession = async (payload: PostSessionRequest) => {
  return await axiosInstance.put(API_ENDPOINTS.sessions.update(payload.id), payload);
}

export { getAllSessionsPaginated, getSessionDetails, addNewSession, updateSession };
