import { createSlice } from '@reduxjs/toolkit';

export interface IBaseState {
  lang: string;
  sideBar: {
    isClosed: boolean;
  };
  mobileSideBar: {
    isClosed: boolean;
  };
}



const initialState: IBaseState = {
  lang: localStorage.getItem('distro-current-lang') || 'en',
  sideBar: {
    isClosed: false,
  },
  mobileSideBar: {
    isClosed: false,
  },
};

export const baseSlice = createSlice({
  name: 'base',
  initialState: initialState,
  reducers: {
    setLang: (state, action) => {
      localStorage.setItem('distro-current-lang', action.payload);
      state.lang = action.payload;
    },
    setToggleSideBar: (state, action) => {
      state.sideBar.isClosed = action.payload != null ? action.payload : !state.sideBar.isClosed;
    },
    setToggleMobileSideBar: (state, action) => {
      state.mobileSideBar.isClosed = action.payload != null ? action.payload : !state.mobileSideBar.isClosed;
    },
    resetBase: (state) => {
      state.lang = initialState.lang;
      state.sideBar = initialState.sideBar;
      state.mobileSideBar = initialState.mobileSideBar;
    },
  },
});

export const { setLang, setToggleSideBar, setToggleMobileSideBar, resetBase } = baseSlice.actions;
export default baseSlice.reducer;
