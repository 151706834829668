import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Radio, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { attendeesList, defaultTablePagination } from './_helper';
import { useTablePagination } from '../../../hooks';

interface SessionAttendeesPageProps {
  //   session: any;
}

const SessionAttendeesPage: React.FC<SessionAttendeesPageProps> = () => {
  const { handlePagination, pagination, filterObject, searchText } = useTablePagination({
    searchProperties: ['id', 'name'],
    callback: (payload) => {
      const staticPayload = {
        key: 'coaches',
        isEnabled: 'true',
        sortBy: 'unpaid_session',
      };
      if (payload?.phone?.includes('+')) {
        payload = { ...payload, phone: payload?.phone.replace('+', '') };
      }
      // filterCoachList({
      //   ...staticPayload,
      //   ...payload,
      // });
    },
  });

  const nbHits = 0;

  const columns: ColumnsType<any> = [
    {
      title: '#',
      dataIndex: 'attendeeId',
      render: (_text, _record, index) => index + 1,
    },
    {
      title: 'Attendee Name',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'status',
      dataIndex: 'isAbsent',
      key: 'isAbsent',
      render: (_text, record) => (
        <div>
          <Radio.Group
            onChange={(e) =>
              //   handleChangeStatus(e.target.value, record.attendeesID)
              {}
            }
            value={Boolean(record.isAbsent)}>
            <Radio value={false}>{'attend'}</Radio>
            <Radio value>{'absent'}</Radio>
          </Radio.Group>
        </div>
      ),
    },
    {
      title: 'original Price',
      dataIndex: 'price',
      key: 'price',
      render: (_, record) => Math.ceil(record.price),
    },
    {
      title: 'discount Code',
      dataIndex: 'discountCode',
      key: 'discountCode',
      render: (_, record) => record?.discountCode ?? '-',
    },
    {
      title: 'discount Value',
      dataIndex: 'discountValue',
      key: 'discountValue',
      render: (_, record) => record?.discountValue ?? '-',
    },
    {
      title: 'paidPrice',
      dataIndex: 'paidPrice',
      key: 'paidPrice',
      render: (_, record) => record?.paidPrice ?? 0,
    },
    {
      title: 'action',
      dataIndex: 'remove',
      key: 'remove',
      render: (_, record) => (
        <Button onClick={() => () => {}}>
          <CloseCircleOutlined />
          <span>{'remove'}</span>
        </Button>
      ),
    },
  ];

  return (
    <Table
      loading={false}
      rowKey="id"
      columns={columns}
      dataSource={attendeesList}
      onChange={handlePagination}
      pagination={{
        ...pagination,
        total: nbHits,
        ...defaultTablePagination,
      }}
      scroll={{ x: true }}
    />
  );
};

export default SessionAttendeesPage;
