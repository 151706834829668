/* eslint-disable no-unused-vars */

import { ROLES } from '../models/role/role';

export enum ROUTES {
  HOME = '/',

  // { ****** Auth ****** }
  LOGIN = '/login',
  FORGET_PASSWORD = '/forget-password',
  CHANGE_PASSWORD = '/change-password',

  // { ****** roll back ****** }
  UNAUTHORIZED = '/unauthorized',
  NOT_FOUND = '*',

  // { ****** Experience ****** }
  EXPERIENCES = '/experiences',
  ADD_EXPERIENCE = '/experiences/create',
  EDIT_EXPERIENCES = '/experiences/edit/:id',

  // { ****** Sessions ****** }
  SESSIONS = '/sessions',
  EDIT_SESSIONS = '/sessions/:expId/edit/:sessionId',
  SESSION_LEARNERS = '/sessions/:expId/edit/:sessionId/learners',
  ADD_SESSION = '/sessions/create',

  // { ****** Talents ****** }
  TALENTS = '/talents',

  // SESSIONS_LIST = '/sessions/:expId',
  // SESSION_ADD_LEARNER = '/sessions/:expId/edit/:sessionId/learners/add',

  // COACHES = '/coaches',
  // EDIT_COACH = '/coaches/edit/:id',
  // CREATE_COACH = '/coaches/create',
  // COACH_BALANCE = '/coaches/balance/:id',
  // LEARNERS = '/learners',
  // PAYMENT_ERROR = '/payment-error',
  // CREATE_LEARNER = '/learners/create',
  // EDIT_LEARNER = '/learners/edit/:id',

  // ACCOUNT_MANAGER = '/account-manager',
  // ACCOUNT_MANAGER_EDIT = '/account-manager/edit/:id',

  // REVIEWS_LIST = '/reviews/:expId',
  // DISCOUNT_CODES = '/discount-codes',
  // DISCOUNT_CODES_ADD = '/discount-codes/create',
  // DISCOUNT_CODES_EDIT = '/discount-codes/edit/:id',
  // NOTIFICATIONS = '/notifications',
  // NOTIFICATIONS_ADD = '/notifications/create',
  // CHEERING = '/cheering',
  // BOOKING_LIST = '/booking-list',
  // GIFT_CARDS = '/gift-cards',
  // GIFT_CARDS_ADD = '/gift-cards/create',
  // GIFT_CARDS_EDIT = '/gift-cards/edit/:id',
  // GIFT_BOOKING = '/gift-bookings',
  // B2B_GIFT_CARDS = '/b2b-gift-cards',
  // B2B_GIFT_CARDS_ADD = '/b2b-gift-cards/create',
  // B2B_GIFT_CARDS_EDIT = '/b2b-gift-cards/edit/:id',
  // REVIEWS = '/reviews',
  // WANTED_EXPERIENCES = '/wanted-experiences',
  // BALANCE = '/balance',
  // COACH_BANNERS = '/coach-banners',
  // COACH_BANNERS_ADD = '/coach-banners/create',
  // COACH_BANNERS_EDIT = '/coach-banners/edit/:id',
  // COACH_BANNERS_SORT = '/coach-banners/sort',

  // LEARNER_BANNERS = '/learner-banners',
  // LEARNER_BANNERS_ADD = '/learner-banners/create',
  // LEARNER_BANNERS_EDIT = '/learner-banners/edit/:id',
  // LEARNER_BANNERS_SORT = '/learner-banners/sort',

  // COLLECTION = '/collection',
  // COLLECTION_EDIT = '/collection/edit/:id',
  // COLLECTION_ADD = '/collection/create',
  // COLLECTION_SORT = '/collection/sort',

  // APPROVALS = '/approvals',
  // APPROVALS_Edit = '/approvals/edit/:id',

  // GIGS_NOTIFICATIONS = '/gigs-notifications',
  // GIGS_APPLICATIONS = '/gigs-applications',
  // GIGS = '/gigs',
  // GIGS_CREATE = '/gigs/create',
  // GIGS_EDIT = '/gigs/edit/:id',
}

export type LangItem = string;

export interface RouteDto {
  path: ROUTES;
  id: string;
  name: string;
  breadcrumbParentId?: string;
  component: JSX.Element;
}
export interface BasicMenuItem {
  title: LangItem;
  icon?: JSX.Element;
  path: ROUTES;
  roles?: ROLES[];
}
export type MenuItemChildren = (
  | BasicMenuItem
  | (BasicMenuItem & { children?: BasicMenuItem[] })
  | (BasicMenuItem & {
      children: BasicMenuItem[] & { children?: BasicMenuItem[] }[];
    })
)[];
export interface MenuItem extends BasicMenuItem {
  children?: MenuItemChildren;
}
