import { axiosInstance } from '.';
import {
  ExperienceDto,
  ExperiencePaginationFilterParams,
  IAddExperienceDTO,
  IEditExperienceDTO
} from '../../models';
import { ApiResponse } from '../../models/general/apiResult';
import { ApiPaginatedResponse } from '../../models/general/pagentation';
import { API_ENDPOINTS } from '../apiEndPoints';

const getAllExperiencesPaginated = async (
  payload: ExperiencePaginationFilterParams
): Promise<ApiPaginatedResponse<ExperienceDto[]>> =>
  await axiosInstance.get(API_ENDPOINTS.experiences.allPaginated, {
    params: payload,
  });

// Add new
const addNewExperience = async (payload: IAddExperienceDTO): Promise<ApiResponse<ExperienceDto>> =>
  await axiosInstance.post(API_ENDPOINTS.experiences.add, payload);

// update
const updateExperience = async (id: number, payload: IEditExperienceDTO): Promise<ApiResponse<ExperienceDto>> =>
  await axiosInstance.patch(API_ENDPOINTS.experiences.edit(id), payload);

// Get One
const getExperience = async (payload: string | number): Promise<ApiResponse<ExperienceDto>> =>
  await axiosInstance.get(API_ENDPOINTS.experiences.getOne(payload));

export { addNewExperience, getAllExperiencesPaginated, getExperience, updateExperience };

