import { Form, Select } from "antd";

import { CityWithIdItem } from "../../../models";
import { RULES } from "../../../helpers/constants";
import { t } from "i18next";
import { useLang } from "../../../hooks";

export interface ICitiesProps {
    citiesList: CityWithIdItem[];
    isOnline?: boolean;
    handleChange?: (cityId: string) => void;
    regionFieldVal: string;
    fieldName?: string;
}

export const Cities = ({
    citiesList = [],
    isOnline = false,
    handleChange = () => {},
    regionFieldVal,
    fieldName = "city",
}: ICitiesProps) => {
    const { isLTR } = useLang();
    return (
        <>
            {regionFieldVal && citiesList.length > 0 && (
                <Form.Item
                    name={fieldName}
                    label={`${t("city")}:`}
                    rules={isOnline ? [] : [RULES.required]}
                >
                    <Select
                        filterOption
                        optionFilterProp="label"
                        onChange={(value: string) => {
                            if (handleChange) {
                                handleChange(value);
                            }
                        }}
                        showSearch
                        placeholder={t("selectCity")}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        options={citiesList?.map((city: CityWithIdItem) => ({
                            label: isLTR ? city?.nameEn : city?.nameAr,
                            value: city.id,
                        }))}
                    />
                </Form.Item>
            )}
        </>
    );
};

export default Cities;
