/* eslint-disable react/jsx-no-useless-fragment */
import { Menu, MenuProps } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { menuList } from '../../../../helpers/constants';
import { useAuth } from '../../../../hooks';
import { MenuItem } from '../../../../models';
import { routesRoles } from '../../../../routes/routesRoles';
import { setToggleMobileSideBar } from '../../../../store/Base/slice';
import s from './index.module.scss';

const { SubMenu } = Menu;

const SiderMenu: FC = () => {
  const user = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const path = location.pathname;
  const [menuTreeNode, setMenuTreeNode] = useState<any>();
  const [openKeys, setOpenKeys] = useState(['sub1']);
  const [openKey, setMenuOpenKey] = useState<string[]>([]);

  const setSideMobileSidebarOpen = () => {
    dispatch(setToggleMobileSideBar(false));
  };

  const filterMenuItem = (item: MenuItem) => {
    if (item.children) {
      return !!item.children.find((child: MenuItem) => routesRoles?.[user?.role]?.includes(child?.path));
    }
    return user?.role && routesRoles?.[user?.role]?.includes(item?.path);
  };

  const getMenuNodes = (menuItems: typeof menuList) =>
    menuItems.reduce((pre: Array<any>, item) => {
      if (filterMenuItem(item)) {
        if (!item.children) {
          pre.push(
            <Menu.Item onClick={() => setSideMobileSidebarOpen()} key={item.path} icon={item.icon ?? <></>}>
              <Link to={item.path}>
                <span>{t(item.title)}</span>
              </Link>
            </Menu.Item>
          );
        } else {
          // Find a child item that matches the current request path
          const cItem = item.children.find((cItem) => path.indexOf(cItem.path) === 0);
          //  If it exists, it means that the sublist of the current item needs to be opened
          if (cItem) {
            setMenuOpenKey([...openKey, item.path]);
          }
          // Add <SubMenu> to pre
          pre.push(
            <SubMenu key={item.title} icon={item.icon ?? <></>} title={<span>{t(item.title)}</span>}>
              {getMenuNodes(item.children)}
            </SubMenu>
          );
        }
      }
      return pre;
    }, []);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    if (Array.isArray(keys)) {
      setOpenKeys([keys[keys.length - 1]]);
    }
  };

  useEffect(() => {
    setMenuTreeNode(getMenuNodes(menuList));
  }, []);

  const detectCurrentPath = (path: string) => {
    const pathArr = path.split('/');
    const currentPath = '/' + pathArr[1];
    return [currentPath];
  };

  return (
    <div className={s.siderContainer}>
      {menuTreeNode?.map((item: JSX.Element, index: number) => (
        <Menu
          key={index}
          mode="inline"
          selectedKeys={[...detectCurrentPath(path)]}
          defaultOpenKeys={undefined}
          openKeys={openKeys}
          onOpenChange={onOpenChange}>
          {item}
        </Menu>
      ))}
    </div>
  );
};

export default SiderMenu;
