import { Form, FormInstance, Input, Radio, Select } from 'antd';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleMapComp, InfoTooltip } from '../../../components';
import { RULES, defaultCoordinates, genderOptions, hospitalityOptions } from '../../../helpers/constants';
import { getAddressParts } from '../../../helpers/utils';
import { Coordinates } from '../../../models';
import { ResellersLookups } from '../../../models/lookups/resellers-lookups/resellers-lookups';
import { TalentsLookups } from '../../../models/lookups/talents-lookups/talents-lookups';
import { getLookUpResellersRequest, getLookUpTalentsRequest } from '../../../store/Session/slice';

export interface ISessionProps {
  form: FormInstance<any>;
}

// Define constants for options
const LANGUAGE_OPTIONS = [{ code: 'ar', id: 'ar', name: 'العربية', nameEn: 'Arabic' }];

const SessionInfo: React.FC<ISessionProps> = ({ form }) => {
  const minimumSeats = Form.useWatch('minimumSeats');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLookUpResellersRequest(null));
    dispatch(getLookUpTalentsRequest(null));
    const location = form.getFieldValue('location');
    if (location)
      setPosition((prev) => {
        return {
          lat: location?.lat,
          lng: location?.lng,
        };
      });
  }, []);

  const { lookUpResellersList, lookUpTalentsList } = useSelector(
    (state: {
      sessions: { lookUpResellersList: { list: ResellersLookups[] }; lookUpTalentsList: { list: TalentsLookups[] } };
    }) => state.sessions
  );

  const [position, setPosition] = useState<google.maps.LatLngLiteral>(defaultCoordinates);

  const handleMapClick = (coordinatesData: Coordinates) => {
    getAddressParts(coordinatesData, handleAddressData);
  };

  const handleAddressData = (data: any) => {
    if (data) {
      form.setFieldValue('location', data);
    }
  };

  return (
    <>
      {/* title in arabic */}
      <Form.Item
        rules={[RULES.required, RULES.minLengthValidator(3), RULES.arabicField]}
        name="title"
        label={
          <>
            <span>{`${t('sessionTitle')} ${t('inArabic')}`}: </span>
            <InfoTooltip title={`${'This Field should be 3 characters or more'}`} />
          </>
        }>
        <Input type="text" />
      </Form.Item>

      {/* title in english */}
      <Form.Item
        rules={[RULES.required, RULES.minLengthValidator(3), RULES.englishField]}
        name="titleEn"
        label={
          <>
            <span>{`${t('sessionTitle')} ${t('inEnglish')}`}: </span>
            <InfoTooltip title={`${'This Field should be 3 characters or more'}`} />
          </>
        }>
        <Input type="text" />
      </Form.Item>

      {/* language */}
      <Form.Item name="language" label={`${t('language')} :`} rules={[RULES.required]}>
        <Select
          showSearch
          placeholder={`${t('selectLanguage')}`}
          optionFilterProp="children"
          mode="multiple"
          getPopupContainer={(trigger) => trigger.parentNode}
          options={LANGUAGE_OPTIONS.map((item) => ({
            label: item.name,
            value: item.code,
          }))}
        />
      </Form.Item>

      {/* start data and time */}
      <Form.Item name="startDate" label={`${t('startDate')} :`} rules={[RULES.required]}>
        <Input type="date" />
      </Form.Item>

      <Form.Item name="startTime" label={`${t('startTime')} :`} rules={[RULES.required]}>
        <Input type="time" />
      </Form.Item>

      {/* Map */}
      <>
        {/* map location */}
        <Form.Item name="location" label={`${t('locationDetails')}`} rules={[RULES.required]}>
          <GoogleMapComp position={position} handlePointClick={handleMapClick} />
        </Form.Item>
        {/* map location in arabic */}
        <Form.Item
          rules={[RULES.required, RULES.minLengthValidator(7), RULES.arabicField]}
          name="locationDetails"
          label={
            <>
              <span>{`${t('locationDetails')} ${t('inArabic')}`}:</span>
              <InfoTooltip title={`${'This Field should be 3 characters or more'}`} />
            </>
          }>
          <Input.TextArea
            rows={3}
            autoSize={{ minRows: 3, maxRows: 6 }}
            placeholder={`${t('welcomingMessagePlaceHolder')}`}
          />
        </Form.Item>
        {/* map location in english */}
        <Form.Item
          rules={[RULES.required, RULES.minLengthValidator(7), RULES.englishField]}
          name="locationDetailsEn"
          label={
            <>
              <span>{`${t('locationDetails')} ${t('inEnglish')}`}:</span>
              <InfoTooltip title={`${'This Field should be 3 characters or more'}`} />
            </>
          }>
          <Input.TextArea
            rows={3}
            autoSize={{ minRows: 3, maxRows: 6 }}
            placeholder={`${t('welcomingMessagePlaceHolder')}`}
          />
        </Form.Item>
      </>

      {/* Attendees Gender */}
      <Form.Item name="attendeesGender" label={`${t('attendeesGender')} :`} rules={[RULES.required]}>
        <Radio.Group>
          {Object.keys(genderOptions).map((key) => (
            <Radio key={key} value={key.toLowerCase()}>
              {genderOptions[key]['labelEn']}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      {/* Hospitality */}
      <Form.Item name="hospitality" label={`${t('hospitality')} :`} rules={[RULES.required]}>
        <Select
          placeholder={`${'select Hospitality'} :`}
          optionFilterProp="children"
          getPopupContainer={(trigger) => trigger.parentNode}
          options={Object.keys(hospitalityOptions)?.map((option: string) => ({
            label: hospitalityOptions[option as keyof typeof hospitalityOptions].labelAr,
            value: option,
          }))}
        />
      </Form.Item>

      {/* Session period */}
      <Form.Item rules={[RULES.required]} label={`${t('sessionPeriod')} :`} name="sessionPeriod">
        <Select
          placeholder={'select Session Period'}
          getPopupContainer={(trigger) => trigger.parentNode}
          onChange={(sessionPeriod) => {
            // handleSessionPeriodChange(sessionPeriod)
          }}
          options={[1, 2, 3, 4, 5].map((period: any) => ({
            label: period,
            value: period,
          }))}
        />
      </Form.Item>

      {/* Available  Seats */}
      <Form.Item
        rules={[
          RULES.required,
          {
            validator: RULES.validateNumber({
              minVal: minimumSeats || 0,
              minValMessage: 'availableSeatsMinValValidationMsg',
            }),
          },
          RULES.number,
        ]}
        name="availableSeats"
        label={`${t('availableSeats')} :`}>
        <Input className="w-100" type="number" />
      </Form.Item>

      {/* Minimum  Seats  to start the session */}
      <Form.Item
        rules={[
          RULES.required,
          {
            validator: RULES.validateNumber({
              minVal: 0,
              maxVal: form.getFieldValue('availableSeats'),
              maxValMessage: 'minimumSeatsMaxValValidationMsg',
            }),
          },
          RULES.number,
        ]}
        name="minimumSeats"
        label={`${t('minimumSeats')} :`}>
        <Input className="w-100" type="number" />
      </Form.Item>

      {/* talent */}
      <Form.Item rules={[RULES.required]} name="talent" label={`${t('talents')} :`}>
        <Select
          showSearch
          placeholder={'Talents'}
          optionFilterProp="Talents"
          getPopupContainer={(trigger) => trigger.parentNode}
          options={lookUpTalentsList?.list?.map((item) => ({
            label: item.name,
            value: item.id,
          }))}
        />
      </Form.Item>

      {/* reseller */}
      <Form.Item rules={[RULES.required]} name="resellers" label={`${t('resellers')} :`}>
        <Select
          showSearch
          placeholder={`${t('resellers')}`}
          optionFilterProp="Resellers"
          getPopupContainer={(trigger) => trigger.parentNode}
          options={lookUpResellersList?.list?.map((item) => ({
            label: item.name,
            value: item.id,
          }))}
          value={form.getFieldValue('resellers')}
        />
      </Form.Item>

      {/* coach price */}
      <Form.Item rules={[RULES.required, RULES.number]} name="price" label={`${t('coachPrice')} :`}>
        <Input className="w-100" type="number" />
      </Form.Item>

      {/* suplift price */}
      <Form.Item rules={[RULES.required, RULES.number]} name="supliftPrice" label={`${t('supliftPrice')} :`}>
        <Input className="w-100" type="number" />
      </Form.Item>
    </>
  );
};

export default SessionInfo;
