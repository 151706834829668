import { call, put, takeEvery } from "redux-saga/effects";

import { loginResponse, logoutResponse } from "./slice";
import { LoginPayloadType, ROLES, ROUTES } from "../../models";
import { login } from "../../network/apis/auth";
import { message } from "antd";
import { t } from "i18next";

export function* loginSaga({ payload }) {
  try {
    const response = yield call(login, payload as LoginPayloadType);
    const userData = {
      user: { ...response.data.user, password: null },
      token: response.data.backendTokens?.accessToken,
      role: "admin"
    };
    localStorage.setItem("distro-dashboard-user", JSON.stringify(userData));
    yield put(loginResponse(userData));    

  } catch (err) {        
    yield put(loginResponse({ user: null, token: null, role: null}));
  }
}

export function* logoutSaga({ payload }) {
  try {
    // yield call(apis.logout);
    yield put(logoutResponse());
    yield payload.navigate(ROUTES.LOGIN);
  } catch (err) {
    // yield showSnackMsg(err.response?.data?.message);
  }
}

export default function* AuthSagas() {
  yield takeEvery("auth/loginRequest", loginSaga);
  yield takeEvery("auth/logoutRequest", logoutSaga);
}
