/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useState, useRef } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import { BorderHorizontalOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Tooltip, Image, Modal, Radio, Space, RadioChangeEvent } from 'antd';
import cn from 'classnames';
import { canvasPreview } from './canvasPreview';
import 'react-image-crop/dist/ReactCrop.css';
import styles from './index.module.scss';
import { centerAspectCrop } from '../../../helpers/utils';
import { useDebounceEffect } from '../../../hooks';

export interface ImageCropperProps {
  handleChangeCroppedImages: (image: string[]) => void;
  handleChangeOriginalImages: (image: (string | File)[]) => void;
  handleChangeMainImageIndex: (image: number) => void;
  handleChangeDeletedImagesIds: (image: string[]) => void;
  maxCountNumber?: number;
  images: (string | File)[];
  croppedImages: string[];
  mainImageIndex?: number;
  originalImagesFormData?: boolean;
  deletedImagesIds: string[];
}

const ImageCropper: FC<ImageCropperProps> = ({
  handleChangeCroppedImages,
  handleChangeOriginalImages,
  handleChangeMainImageIndex,
  handleChangeDeletedImagesIds,
  deletedImagesIds,
  images,
  croppedImages,
  mainImageIndex = 0,
  maxCountNumber = 7,
  originalImagesFormData = false,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [mode, setMode] = useState<'edit' | 'add'>('add');
  const [currentPreviewedImageIndex, setCurrentPreviewedImageIndex] = useState<number>();
  const [imgSrc, setImgSrc] = useState<string | undefined>(undefined);
  const [currentCroppedImage, setCurrentCroppedImage] = useState<string>();
  const [crop, setCrop] = useState<Crop | undefined>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [aspect, setAspect] = useState<number | undefined>(16 / 9);

  const closeModal = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setImgSrc(undefined);
    setCompletedCrop(undefined);

    setAspect(16 / 9);
    setModalOpen(false);
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (reader.result !== null) {
          const selectedImageSrc = reader.result.toString() ?? '';
          setImgSrc(selectedImageSrc);
          handleChangeOriginalImages([...images, originalImagesFormData ? e.target.files[0] : selectedImageSrc]);
        }
      });
      reader.readAsDataURL(e.target.files[0]);
      setMode('add');
      setModalOpen(true);
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 16 / 9));
  };

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current) {
        // We use canvasPreview as it's much faster than imgPreview.
        const croppedImage = await canvasPreview(imgRef.current, completedCrop);
        if (croppedImage) {
          setCurrentCroppedImage(croppedImage);
        }
      }
    },
    100,
    [completedCrop]
  );

  const handleToggleAspectClick = () => {
    setAspect((prevState) => (prevState === undefined ? 16 / 9 : undefined));
  };

  const changeIndexIfDelete = (imgIndex: number) => {
    if (imgIndex === mainImageIndex && imgIndex - 1 !== -1) {
      handleChangeMainImageIndex(imgIndex - 1);
    } else {
      handleChangeMainImageIndex(0);
    }
  };

  const handleDeleteBtnClick = (imgIndex: number, element) => {
    const imagesSet = new Set(images);
    const croppedImagesSet = new Set(croppedImages);
    imagesSet.delete(images[imgIndex]);
    croppedImagesSet.delete(croppedImages[imgIndex]);
    handleChangeDeletedImagesIds([...deletedImagesIds, element]);
    handleChangeOriginalImages(Array.from(imagesSet));
    handleChangeCroppedImages([...croppedImagesSet]);
    changeIndexIfDelete(imgIndex);
  };

  const handleConfirmCrop = () => {
    if (mode === 'edit' && currentPreviewedImageIndex !== undefined) {
      const newCroppedImages = [...croppedImages];
      newCroppedImages[currentPreviewedImageIndex] = currentCroppedImage as string;
      handleChangeCroppedImages(newCroppedImages);
      setCurrentPreviewedImageIndex(undefined);
    } else {
      handleChangeCroppedImages([...croppedImages, currentCroppedImage as string]);
    }
    setCurrentCroppedImage(undefined);
    closeModal();
  };

  // const handleEditImage = (imageIndex: number) => {
  //   setCurrentPreviewedImageIndex(imageIndex);
  //   setMode('edit');
  //   setImgSrc(images[imageIndex]);
  //   setCurrentCroppedImage(croppedImages[imageIndex]);
  //   setModalOpen(true);
  // };
  return (
    <>
      {images.length < maxCountNumber && (
        <div className={styles.cropControls}>
          <div className={styles.inputContainer}>
            <input
              ref={fileInputRef}
              className={styles.uploadInput}
              type="file"
              accept="image/*"
              onChange={onSelectFile}
            />
            <label className={styles.uploadBtn} htmlFor="uploadInput">
              <PlusOutlined />
              <span>Upload</span>
            </label>
          </div>
        </div>
      )}

      <Modal
        title={
          <div className={styles.titleWrap}>
            <span>Crop Image</span>
            {Boolean(imgSrc) && (
              <Tooltip title={`Toggle aspect ${aspect ? 'off' : 'on'}`}>
                <button
                  type="button"
                  className={cn(styles.aspectBtn, {
                    [styles.aspectBtnToggled]: aspect,
                  })}
                  onClick={handleToggleAspectClick}>
                  <BorderHorizontalOutlined />
                </button>
              </Tooltip>
            )}
          </div>
        }
        centered
        open={modalOpen}
        onOk={handleConfirmCrop}
        onCancel={closeModal}>
        <div className={styles.cropperWrapper}>
          {Boolean(imgSrc) && (
            <ReactCrop
              ruleOfThirds
              className={styles.cropper}
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}>
              <img className={styles.cropperImage} ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
            </ReactCrop>
          )}
        </div>

        <div className={styles.croppedImageWrap}>
          <label htmlFor="croppedImage">
            <span className={styles.croppedImageDesc}>
              Cropped Image <small>(Click to preview)</small> :
            </span>

            <Image id="croppedImage" width={100} height={70} src={currentCroppedImage} />
          </label>
        </div>
      </Modal>

      {croppedImages?.length > 0 && (
        <div className={styles.imagesWrapper}>
          <Radio.Group
            onChange={(event: RadioChangeEvent) => handleChangeMainImageIndex(event.target.value)}
            value={mainImageIndex}>
            <Space direction="vertical">
              {croppedImages?.map((croppedImageItem, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Radio value={index} key={index}>
                  <div className={styles.radioImageItem}>
                    <Image
                      alt="cropped item"
                      src={croppedImageItem}
                      width={50}
                      height={50}
                      onClick={(e) => e.preventDefault()}
                    />
                    <div className={styles.editAddBtnsWrap}>
                      {/* <button
                        onClick={() => handleEditImage(index)}
                        className={styles.editBtn}
                        type='button'
                      >
                        <EditOutlined />
                      </button> */}
                      <button
                        onClick={() => handleDeleteBtnClick(index, croppedImageItem)}
                        className={styles.removeBtn}
                        type="button">
                        <DeleteOutlined />
                      </button>
                    </div>
                  </div>
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>
      )}
    </>
  );
};
export default ImageCropper;
