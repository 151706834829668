import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
// import Cookies from 'js-cookie';
import { message } from 'antd';
import moment from 'moment-timezone';
import { getPropertyWithArrayTypeObject, localStorageHelper } from '../helpers/utils';
import { ROUTES } from '../models';
import store from '../store';

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  BadRequest = 400,
  TooManyRequests = 429,
  InternalServerError = 500,
}
const handleErrorMessage = (errorResponse: any) => {
  const { data, statusText, status } = errorResponse;
  const currentLanguage = store.getState().base.lang || 'ar';
  let msg;
  if (data?.feedback?.message) {
    msg = data.feedback.message[currentLanguage] ?? data.feedback.message.en ?? data?.feedback?.message;
  } else {
    msg = data.message || statusText || (typeof data === 'string' && data);
  }
  message.destroy();
  message.error(msg);
  if (status === StatusCode.Unauthorized && !window.location.pathname.includes('login')) {
    localStorageHelper.clearAllStorage();
    window.location.href = ROUTES.LOGIN;
  }
};

class Http {
  private instance: AxiosInstance | null = null;

  private isCustom: boolean = false;

  constructor(isCustom: boolean = false) {
    this.isCustom = isCustom;
  }

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
    });

    http.interceptors.request.use(
      (config) => {
        const token = store.getState().auth.token || localStorageHelper.get('distro-dashboard-user');
        if (token) {
          config.headers.authorization = token;
        } else {
          window.location.href = ROUTES.LOGIN;
        }
        if (this.isCustom) {
          config['Content-Type'] = 'multipart/form-data';
        }
        config['time-zone'] = moment.tz.guess();

        return config;
      },
      (error) => Promise.reject(error)
    );

    http.interceptors.response.use(
      (response) => {
        const newData: Record<string, any> = { data: response?.data?.data };
        const responseData = response?.data?.data;

        if (responseData) {
          const responseObjectKeys = Object.keys(responseData);
          if (Array.isArray(responseData)) {
            newData.data = { items: responseData };
          } else if (responseObjectKeys.length === 1 || responseObjectKeys.indexOf('npPages') !== -1) {
            const arrayTypeItemKey = getPropertyWithArrayTypeObject(responseData);
            if (arrayTypeItemKey) {
              newData.data.items = responseData[arrayTypeItemKey];
              delete newData.data[arrayTypeItemKey];
            }
          }
        }

        return Promise.resolve({
          ...response,
          ...newData,
        });
      },
      (error) => {
        const { response } = error;
        if (response && response instanceof Object) {
          return this.handleError(response);
        }
        message.destroy();
        message.error(error?.message);

        return Promise.reject(error);
      }
    );

    this.instance = http;
    return http;
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.get<T, R>(url, config as AxiosRequestConfig);
  }

  post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  handleError(error: any) {
    const { status } = error;
    switch (status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        message.error('Internal server error');
        break;
      }
      default:
        handleErrorMessage(error);
    }

    return Promise.reject(error);
  }
}

export const http = new Http();

export const customHttp = new Http(true);
