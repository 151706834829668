import { createSlice } from '@reduxjs/toolkit';
import { ROLES } from '../../models';
import { localStorageHelper } from '../../helpers/utils';

const initialState = {
  user: null,
  token: null,
  role: null,
  isLoading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload?.user;
       state.token = action.payload?.token;
       state.role = action.payload?.role;
    },
    loginRequest: (state, action) => {
      state.isLoading = true;
    },
    loginResponse: (state, action) => {
      state.user = action.payload?.user;
      state.token = action.payload?.token;
      state.role = action.payload?.role;
      state.isLoading = false;
    },
    logoutRequest: () => {},
    logoutResponse: (state) => {
      state.user = null;
      state.token = null;
      state.role = null;
      localStorageHelper.clearAllStorage()
    },
  },
});

export const { setUser, loginRequest, loginResponse, logoutRequest, logoutResponse } = authSlice.actions;
export default authSlice.reducer;
