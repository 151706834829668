import { t } from 'i18next';
import { Checkbox, Divider, Form, FormInstance, Input, Select } from 'antd';

import DatePickerComp from '../date-picker';

import TimePickerComp from '../time-picker';
import {
  RULES,
  genderOptions,
  hospitalityOptions,
  sessionPeriods,
  bufferTimes,
  earlyBirdPriceDatePeriod,
} from '../../../helpers/constants';
import { getEndTimeValue, validateStartDateBasedOnEarlyBirdPrice, validateStartDate } from '../../../helpers/utils';
import { useLang } from '../../../hooks';
import {
  ISessionFormFields,
  SessionPeriodType,
  EarlyBirdPriceDatePeriodType,
  FormatDateEnum,
  StaticItem,
} from '../../../models';

export interface ISessionProps {
  form: FormInstance<ISessionFormFields & any>;
}

const { TextArea } = Input;
const { Group } = Checkbox;

const Session = ({ form }: ISessionProps) => {
  const { isLTR } = useLang();
  const earlyBirdPriceVal = Form.useWatch('earlyBirdPrice', form);
  const minimumSeats = Form.useWatch('minimumSeats', form);
  const handleSessionPeriodChange = (
    sessionPeriod: SessionPeriodType,
    startTime: Date = form.getFieldValue('startTime')
  ) => {
    if (startTime && sessionPeriod) {
      form.setFieldValue('endTime', new Date(getEndTimeValue(sessionPeriod, startTime).valueOf()));
    }
  };

  const handleEarlyBirdPriceValidation = (earlyBirdPriceDateValue: EarlyBirdPriceDatePeriodType) => {
    const startDate = form.getFieldValue('startDate');
    const startTime = form.getFieldValue('startTime');
    if (earlyBirdPriceDateValue && startDate && startTime) {
      const earlyBirdPriceErrors = validateStartDateBasedOnEarlyBirdPrice({
        earlyBirdPriceDate: earlyBirdPriceDateValue,
        startDate,
        startTime,
      });
      form.setFields([
        {
          name: 'earlyBirdPriceDate',
          errors: earlyBirdPriceErrors,
        },
      ]);
    }
  };
  const handleStartDateChange = (value: Date) => {
    const errors = validateStartDate(value);
    handleEarlyBirdPriceValidation(form.getFieldValue('earlyBirdPriceDate'));
    form.setFields([
      {
        name: 'startDate',
        errors,
      },
    ]);
  };

  const handleStartTimeChange = (value: Date) => {
    const sessionPeriod = form.getFieldValue('endDate');
    if (sessionPeriod && value) {
      handleSessionPeriodChange(sessionPeriod, value);
    }
  };

  return (
    <>
      <Divider>{t('sessionInfo')}</Divider>
      {/* Attendees Gender */}
      <Form.Item name="attendeesGender" label={`${t('attendeesGender')} :`} rules={[RULES.required]}>
        <Group>
          {Object.keys(genderOptions).map((option: string) => (
            <Checkbox key={option} value={option}>
              {genderOptions[option as keyof typeof genderOptions][`${isLTR ? 'labelEn' : 'labelAr'}`]}
            </Checkbox>
          ))}
        </Group>
      </Form.Item>
      {/* Hospitality */}
      <Form.Item name="hospitality" label={`${t('hospitality')} :`} rules={[RULES.required]}>
        <Select
          placeholder={`${t('selectHospitality')} :`}
          optionFilterProp="children"
          getPopupContainer={(trigger) => trigger.parentNode}
          options={Object.keys(hospitalityOptions)?.map((option: string) => ({
            label: hospitalityOptions[option as keyof typeof hospitalityOptions][`${isLTR ? 'labelEn' : 'labelAr'}`],
            value: option,
          }))}
        />
      </Form.Item>
      {/* Start Date */}
      <DatePickerComp
        placeholder={t('selectStartDate')}
        format={FormatDateEnum.YMD}
        form={form}
        label={`${t('startDate')} :`}
        name="startDate"
        rules={[RULES.required]}
        handleChange={handleStartDateChange}
      />
      <TimePickerComp
        form={form}
        label={`${t('startTime')} :`}
        name="startTime"
        rules={[RULES.required]}
        handleChange={(startTime) => handleStartTimeChange(startTime)}
      />
      {/* Session period */}
      <Form.Item rules={[RULES.required]} label={`${t('sessionPeriod')} :`} name="endDate">
        <Select
          placeholder={t('selectSessionPeriod')}
          getPopupContainer={(trigger) => trigger.parentNode}
          onChange={(sessionPeriod) => handleSessionPeriodChange(sessionPeriod)}
          options={sessionPeriods.map((period: StaticItem) => ({
            label: isLTR ? period.labelEn : period.labelAr,

            value: period.value,
          }))}
        />
      </Form.Item>
      {/* End time  */}
      <TimePickerComp rules={[RULES.required]} form={form} label={`${t('endTime')} :`} name="endTime" disabled />
      {/* Buffer time  */}
      <Form.Item rules={[RULES.required]} label={`${t('bufferTime')} :`} name="bufferTime">
        <Select
          placeholder={t('selectBufferTime')}
          allowClear
          getPopupContainer={(trigger) => trigger.parentNode}
          options={bufferTimes.map((timeItem: StaticItem) => ({
            label: isLTR ? timeItem.labelEn : timeItem.labelAr,
            value: timeItem.value,
          }))}
        />
      </Form.Item>
      {/* price */}
      <Form.Item
        rules={[
          RULES.required,
          {
            validator: RULES.validateNumber({
              minVal: earlyBirdPriceVal || 10,
              minValMessage: t('priceValueValidationMsg'),
              equality: true,
            }),
          },
          RULES.decimalNumber,
        ]}
        name="price"
        label={`${t('expPrice')} :`}>
        <Input className="w-100" type="number" />
      </Form.Item>
      {/* early bird price */}
      <Form.Item
        rules={[
          {
            validator: RULES.validateNumber({
              minVal: 0,
              maxVal: form.getFieldValue('price'),
              maxValMessage: t('earlyBirdPriceValueValidationMsg'),
              equality: true,
            }),
          },
          RULES.decimalNumber,
        ]}
        name="earlyBirdPrice"
        label={`${t('earlyBirdPrice')} :`}>
        <Input className="w-100" type="number" />
      </Form.Item>
      {/* early bird price Date */}
      <Form.Item name="earlyBirdPriceDate" label={`${t('earlyBirdPriceDate')} :`}>
        <Select
          placeholder={t('selectEarlyBirdPriceDate')}
          allowClear
          getPopupContainer={(trigger) => trigger.parentNode}
          options={earlyBirdPriceDatePeriod.map((earlyDate: StaticItem) => ({
            label: isLTR ? earlyDate.labelEn : earlyDate.labelAr,
            value: earlyDate.value,
          }))}
        />
      </Form.Item>
      {/* Available  Seats */}
      <Form.Item
        rules={[
          RULES.required,
          {
            validator: RULES.validateNumber({
              minVal: minimumSeats || 0,
              minValMessage: t('availableSeatsMinValValidationMsg'),
            }),
          },

          RULES.number,
        ]}
        name="availableSeats"
        label={`${t('availableSeats')} :`}>
        <Input className="w-100" type="number" />
      </Form.Item>
      {/* Minimum  Seats  to start the session */}
      <Form.Item
        rules={[
          RULES.required,
          {
            validator: RULES.validateNumber({
              minVal: 0,
              maxVal: form.getFieldValue('availableSeats'),
              maxValMessage: t('minimumSeatsMaxValValidationMsg'),
            }),
          },
          RULES.number,
        ]}
        name="minimumSeats"
        label={`${t('minimumSeats')} :`}>
        <Input className="w-100" type="number" />
      </Form.Item>
      {/* Notes */}
      <Form.Item name="note" label={`${t('notes')} :`}>
        <TextArea rows={3} autoSize={{ minRows: 3, maxRows: 6 }} />
      </Form.Item>
    </>
  );
};

export default Session;
