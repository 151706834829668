/* eslint-disable jsx-a11y/media-has-caption */
import { Modal } from 'antd';
import styles from './index.module.scss';

export interface IVideoModalProps {
  isOpen: boolean;
  handleCancel: () => void;
  videoSrc: string;
}

const VideoModal = ({
  isOpen = false,
  handleCancel,
  videoSrc,
}: IVideoModalProps) => (
  <>
    {isOpen && (
      <Modal
        bodyStyle={{ height: '400px' }}
        className={styles.videoModal}
        open
        footer={null}
        onCancel={handleCancel}
      >
        <video
          className={styles.modalVideo}
          controls
          autoPlay={false}
          src={videoSrc}
        />
      </Modal>
    )}
  </>
);

export default VideoModal;
