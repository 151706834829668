import { call, put, takeEvery } from 'redux-saga/effects';

import {
  IAddExperienceDTO,
  ExperienceDto,
  ExperiencePaginationFilterParams,
  RegionItem,
  IEditExperienceDTO,
} from '../../models';
import { ApiPaginatedResponse } from '../../models/general/pagentation';
import { HobbiesLookups, HobbiesLookupsFilters } from '../../models/lookups/hobbies-lookups/hobbies-lookups';
import { ResellersLookups, ResellersLookupsFilters } from '../../models/lookups/resellers-lookups/resellers-lookups';
import { TalentsLookups, TalentsLookupsFilters } from '../../models/lookups/talents-lookups/talents-lookups';
import {
  getAllExperiencesPaginated,
  addNewExperience,
  getExperience,
  updateExperience,
} from '../../network/apis/experience';
import {
  addNewExperienceResponse,
  getExperienceResponse,
  getExperiencesPaginatedResponse,
  getLookUpCitiesListResponse,
  getLookUpHobbiesResponse,
  getLookUpRegionsResponse,
  getLookUpResellersResponse,
  getLookUpTalentsResponse,
  updateExperienceResponse,
} from './slice';
import { ApiResponse } from '../../models/general/apiResult';
import { getHobbiesLookup, getResellersLookup, getTalentsLookup, getRegionsLookup, getCitiesLookup } from '../../network/apis/lookups';

// Get All
export function* getExperiencePaginatedSaga({ payload }) {
  try {
    const response: ApiPaginatedResponse<ExperienceDto[]> = yield call(
      getAllExperiencesPaginated,
      payload as ExperiencePaginationFilterParams
    );
    yield put(getExperiencesPaginatedResponse(response.data));
  } catch (err) {
    // yield showSnackMsg(err.response?.data?.message);
  }
}

// Add New
export function* addExperienceSaga({ payload }) {
  try {
    const response: ApiResponse<ExperienceDto> = yield call(addNewExperience, payload as IAddExperienceDTO);
    yield put(addNewExperienceResponse({ addedExperience: response.data, isAddedOrEdited: true }));
  } catch (err) {
    yield put(addNewExperienceResponse({ addedExperience: null, isAddedOrEdited: false }));
  }
}

// Update
export function* updateExperienceSaga({ payload }) {
  try {
    const response: ApiResponse<ExperienceDto> = yield call(
      updateExperience,
      payload.id,
      payload.payload as IEditExperienceDTO
    );
    yield put(updateExperienceResponse({ updated: response.data, isAddedOrEdited: true }));
  } catch (err) {
    yield put(updateExperienceResponse({ updated: null, isAddedOrEdited: false }));
  }
}

// Get One
export function* getExperienceSaga({ payload }) {
  try {
    const response: ApiResponse<ExperienceDto> = yield call(getExperience, payload as string | number);
    yield put(getExperienceResponse({ experienceData: response.data, isLoading: false }));
  } catch (err) {
    yield put(getExperienceResponse({ experienceData: null, isLoading: false }));
  }
}

// Get Lookups
export function* getLookUpHobbiesSaga({ payload }) {
  try {
    const response: ApiResponse<HobbiesLookups[]> = yield call(getHobbiesLookup, payload as HobbiesLookupsFilters);
    yield put(getLookUpHobbiesResponse(response.data));
  } catch (err) {
    // yield showSnackMsg(err.response?.data?.message);
  }
}

export function* getLookUpResellersSaga({ payload }) {
  try {
    const response: ApiResponse<ResellersLookups[]> = yield call(
      getResellersLookup,
      payload as ResellersLookupsFilters
    );
    yield put(getLookUpResellersResponse(response.data));
  } catch (err) {
    // yield showSnackMsg(err.response?.data?.message);
  }
}

export function* getLookUpTalentsSaga({ payload }) {
  try {
    const response: ApiResponse<TalentsLookups[]> = yield call(getTalentsLookup, payload as TalentsLookupsFilters);
    yield put(getLookUpTalentsResponse(response.data));
  } catch (err) {
    // yield showSnackMsg(err.response?.data?.message);
  }
}

export function* getLookUpRegionsSaga({ payload }) {
  try {
    const response: ApiResponse<RegionItem[]> = yield call(getRegionsLookup, payload as TalentsLookupsFilters);
    yield put(getLookUpRegionsResponse(response.data));
  } catch (err) {
    // yield showSnackMsg(err.response?.data?.message);
  }
}

export function* getLookUpCitiesSaga({ payload }) {
  try {
    const response: ApiResponse<RegionItem[]> = yield call(getCitiesLookup, payload as TalentsLookupsFilters);
    yield put(getLookUpCitiesListResponse(response.data));
  } catch (err) {
    // yield showSnackMsg(err.response?.data?.message);
  }
}

export default function* ExperienceSage() {
  // Get All
  yield takeEvery('experiences/getExperiencesPaginatedRequest', getExperiencePaginatedSaga);
  // Add new
  yield takeEvery('experiences/addNewExperienceRequest', addExperienceSaga);
  // Get One
  yield takeEvery('experiences/getExperienceRequest', getExperienceSaga);
  // Update
  yield takeEvery('experiences/updateExperienceRequest', updateExperienceSaga);

  // Get Lookups
  yield takeEvery('experiences/getLookUpHobbiesRequest', getLookUpHobbiesSaga);
  yield takeEvery('experiences/getLookUpResellersRequest', getLookUpResellersSaga);
  yield takeEvery('experiences/getLookUpTalentsRequest', getLookUpTalentsSaga);
  yield takeEvery('experiences/getLookUpRegionsRequest', getLookUpRegionsSaga);
  yield takeEvery('experiences/getLookUpCitiesListRequest', getLookUpCitiesSaga);
}
