import { FC } from 'react';
import { Layout } from 'antd';
import Sider from './Sider';
import Header from './Header';
import BreadCrumb from './BreadCrumb';
import Content from './Content';

const Main: FC = () => (
  <Layout style={{ minHeight: '100vh' }}>
    <Sider />
    <Layout>
      <Header />
      <BreadCrumb />
      <Content />
    </Layout>
  </Layout>
);

export default Main;
