import { call, put, takeEvery } from 'redux-saga/effects';

import { ApiPaginatedResponse } from '../../models/general/pagentation';
import { ResellersLookups, ResellersLookupsFilters } from '../../models/lookups/resellers-lookups/resellers-lookups';
import {
  GetAllSessionDTO,
  GetSessionDetailsRequest,
  SessionsPaginationFilterParams,
} from '../../models/session/session';
import { addNewSession, getAllSessionsPaginated, getSessionDetails, updateSession } from '../../network/apis/sessions';
import {
  addSessionResponse,
  getLookUpResellersResponse,
  getLookUpTalentsResponse,
  getSessionByIdResponse,
  getSessionsPaginatedResponse,
  updateSessionResponse,
} from './slice';
import { TalentsLookups, TalentsLookupsFilters } from '../../models/lookups/talents-lookups/talents-lookups';
import { getResellersLookup, getTalentsLookup } from '../../network/apis/lookups';
import { ApiResponse } from '../../models/general/apiResult';

// Get All
export function* getSessionsPaginatedSaga({ payload }) {
  try {
    const response: ApiPaginatedResponse<GetAllSessionDTO[]> = yield call(
      getAllSessionsPaginated,
      payload as SessionsPaginationFilterParams
    );
    yield put(getSessionsPaginatedResponse(response.data));
  } catch (err) {
    // yield showSnackMsg(err.response?.data?.message);
  }
}

// Get By Id
export function* getSessionsByIdSaga({ payload }) {
  try {
    const response: ApiResponse<GetSessionDetailsRequest> = yield call(getSessionDetails, payload);
    yield put(getSessionByIdResponse(response.data));
  } catch (err) {
    // yield showSnackMsg(err.response?.data?.message);
  }
}

// Add
export function* addSessionSaga({ payload }) {
  try {
    const response: ApiResponse<GetSessionDetailsRequest> = yield call(addNewSession, payload);
    yield put(addSessionResponse(response.data));
  } catch (err) {
    yield put(addSessionResponse(null));
  }
}

// Edit
export function* editSessionSaga({ payload }) {
  try {
    const response: ApiResponse<GetSessionDetailsRequest> = yield call(updateSession, payload);
    yield put(updateSessionResponse(response.data));
  } catch (err) {
    yield put(updateSessionResponse(null));
  }
}

// lookup
export function* getLookUpResellersSaga({ payload }) {
  try {
    const response: ApiResponse<ResellersLookups[]> = yield call(
      getResellersLookup,
      payload as ResellersLookupsFilters
    );
    yield put(getLookUpResellersResponse(response.data));
  } catch (err) {
    // yield showSnackMsg(err.response?.data?.message);
  }
}

export function* getLookUpTalentsSaga({ payload }) {
  try {
    const response: ApiResponse<TalentsLookups[]> = yield call(getTalentsLookup, payload as TalentsLookupsFilters);
    yield put(getLookUpTalentsResponse(response.data));
  } catch (err) {
    // yield showSnackMsg(err.response?.data?.message);
  }
}

export default function* SessionSage() {
  // Get All
  yield takeEvery('sessions/getSessionsPaginatedRequest', getSessionsPaginatedSaga);
  yield takeEvery('sessions/getSessionByIdRequest', getSessionsByIdSaga);
  yield takeEvery('sessions/getLookUpResellersRequest', getLookUpResellersSaga);
  yield takeEvery('sessions/getLookUpTalentsRequest', getLookUpTalentsSaga);
  yield takeEvery('sessions/addSessionRequest', addSessionSaga);
  yield takeEvery('sessions/updateSessionRequest', editSessionSaga);
}
