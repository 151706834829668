import { FormInstance } from 'antd';
import { Rule } from 'antd/es/form';
import DatePickerComp from '../date-picker';
import { FormatDateEnum } from '../../../models';

export interface ITimePickerCompProps {
  label: string;
  name: string;
  form: FormInstance<any>;
  placeholder?: string;
  format?: FormatDateEnum;
  rules?: Rule[];
  handleChange?: (value: Date) => void;
  disabled?: boolean;
  validator?: (value: Date) => string[];
}

const TimePickerComp = ({
  format = FormatDateEnum.HM,
  ...otherProps
}: ITimePickerCompProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <DatePickerComp {...otherProps} isTimePicker format={format} />
);

export default TimePickerComp;
