import { ReactNode } from "react";
import Loader from "../Loader";

const handleLoading = (values: boolean | boolean[]) => {
  if (Array.isArray(values)) {
    return values.includes(true);
  }
  return values;
};

export interface IPageProps {
  children: ReactNode | ReactNode[];
  className?: string;
  loading?: boolean | boolean[];
}

function Page({ className = "", children, loading = false }: IPageProps) {
  return (
    <div className={className}>
      {handleLoading(loading) ? <Loader /> : children}
    </div>
  );
}

export default Page;
