import moment, { MomentInput } from 'moment';
import { t } from 'i18next';
import { FormatDateEnum } from '../../models';

export const isSameDay = (date: MomentInput, recreanceDate: MomentInput = moment()) =>
  moment(date).isSame(recreanceDate, 'day');

export const formatDate = (date: MomentInput, formatType: FormatDateEnum) =>
  moment(date).isValid() ? moment(date).locale('en').format(formatType) : t('invalidDate');

export const isBeforeToday = (date: MomentInput) => {
  const todayDate = moment();
  return moment(date).isBefore(todayDate, 'day');
};

export const isBeforeNow = (date: MomentInput) => {
  const todayDate = moment();
  return moment(date, 'HH:mm').isBefore(moment(todayDate, 'HH:mm'));
};
export const isAfterToday = (date: moment.Moment) => {
  const todayDate = moment();
  return date.isAfter(todayDate, 'day');
};

export const isAfterDate = (currentDate, targetDate) => moment(currentDate).isAfter(moment(targetDate), 'day');

export const getDiffBetweenTwoDatesInDays = (date: number) => {
  const difference = new Date().getTime() - date;
  return Math.floor(difference / 1000 / 60 / 60 / 24);
};

export const convertUnixToDate = (date: number, formatType: FormatDateEnum) => {
  if (date) {
    return moment.unix(date / 1000).format(formatType);
  }
  return 'invalid date ';
};
export const isToday = (date: Date) => moment(date).isSame(moment(), 'day');
