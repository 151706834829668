import { FC, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout, ProtectedRoute } from '../components';
import { routesList } from '../helpers/constants';
import { useAuth } from '../hooks';
import { ROUTES, RouteDto } from '../models';

const NotFound = lazy(() => import('../pages/authorized/not-found'));
const Login = lazy(() => import('../pages/authorized/login'));
const UnAuthorized = lazy(() => import('../pages/authorized/un-authorized'));
const ForgetPassword = lazy(() => import('../pages/authorized/forget-password'));
const ChangePassword = lazy(() => import('../pages/authorized/change-password'));

const AppRoutes: FC = (): JSX.Element => {
  const user: {} | any = useAuth();

  return (
    <Routes>
      <Route path={'/'} element={<Layout />}>
        {routesList.map((route: RouteDto) => (
          <Route key={route.id} element={<ProtectedRoute pathName={route.path} component={route.component} />}>
            <Route path={route.path} element={route.component} />
          </Route>
        ))}
        <Route path={ROUTES.NOT_FOUND} element={user?.token ? <NotFound /> : <Navigate to={ROUTES.LOGIN} />} />
        <Route path={ROUTES.UNAUTHORIZED} element={user?.token ? <UnAuthorized /> : <Navigate to={ROUTES.LOGIN} />} />
      </Route>
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.FORGET_PASSWORD} element={<ForgetPassword />} />
      <Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePassword />} />
    </Routes>
  );
};

export default AppRoutes;
