import { GoogleMap, Marker, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import { Skeleton } from 'antd';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { options } from './settings';

import { defaultCoordinates } from '../../../helpers/constants';
import styles from './index.module.scss';

// google map props
interface IGoogleMapProps {
  handlePointClick: (val: google.maps.LatLngLiteral) => void;
  position?: google.maps.LatLngLiteral;
  withClick?: boolean;
}

// google libraries type
type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[];

const libraries: Libraries = ['places'];

const GoogleMapComp: FC<IGoogleMapProps> = ({ handlePointClick, position, withClick = false }) => {
  
  const { t } = useTranslation();
  // initialize google map
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY!,
    libraries,
  });

  // clicked position
  const [clickedPos, setClickedPos] = useState<google.maps.LatLngLiteral>(
    defaultCoordinates as google.maps.LatLngLiteral
  );
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(defaultCoordinates as google.maps.LatLngLiteral);
  // save map in ref if we want to access the map
  const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
  // save search box in ref to access it
  const searchRef = useRef<google.maps.places.SearchBox | null>(null);

  // pan to function to pan to specific position on map
  const panTo = useCallback(
    ({ lat, lng }: google.maps.LatLngLiteral) => {
      if (mapRef !== null) {
        mapRef.panTo({ lat, lng });
      }
    },
    [mapRef]
  );

  // set the clicked position when initialize the component
  useEffect(() => {
    if (position && mapRef) {
      setClickedPos(position);
      setCenter(position);
    }
  }, [position, mapRef]);

  // handle change places in google search box
  const onPlacesChanged = () => {
    if (searchRef.current) {
      const places = searchRef.current.getPlaces();
      if (places && places.length > 0) {
        const lat = places?.[0]?.geometry?.location?.lat();
        const lng = places?.[0]?.geometry?.location?.lng();
        if (lat && lng) {
          // pan to the selected place on map and set the clicked position
          handlePointClick({ lat, lng });
          panTo({ lat, lng });
          setClickedPos({ lat, lng });
        }
      }
    }
  };

  // set search box ref to it when the search ref comp is loaded
  const onSearchBoxLoad = useCallback((searchBox: google.maps.places.SearchBox): void => {
    searchRef.current = searchBox;
  }, []);

  // set search box ref to map hen it loaded
  const onLoad = useCallback(
    (map: google.maps.Map): void => {
      setMapRef(map);
    },
    [clickedPos]
  );

  // set the map ref to null when the component un mounted
  const onUnMount = (): void => {
    setMapRef(null);
  };

  // handle click on map
  const onMapClick = useCallback((e: google.maps.MapMouseEvent) => {
    if (e !== null && e.latLng) {
      setClickedPos({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      handlePointClick({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    }
  }, []);

  if (loadError) {
    return <p>{t('mapError')}</p>;
  }

  return (
    <div className={styles.mapWrap}>
      {!isLoaded ? (
        <div className={styles.skeletonWrap}>
          <Skeleton.Button active shape="square" block />
        </div>
      ) : (
        <>
          <GoogleMap
            options={options as google.maps.MapOptions}
            zoom={7}
            onLoad={onLoad}
            onUnmount={onUnMount}
            center={center}
            onClick={withClick ? () => {} : onMapClick}>
            {clickedPos.lat ? <Marker position={clickedPos} /> : null}
            <StandaloneSearchBox onLoad={onSearchBoxLoad} onPlacesChanged={onPlacesChanged}>
              <input type="text" className={styles.searchInput} placeholder={t('mapSearchPlaceholder')} />
            </StandaloneSearchBox>
          </GoogleMap>
        </>
      )}
    </div>
  );
};

export default GoogleMapComp;
