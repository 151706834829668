import { useSelector } from "react-redux";
import { User } from "../models";

const useAuth = (): {
  user: User | null;
  token: string | null;
  role: string | null;
} => {
  const { user, token,role} = useSelector(
    (state: {
      auth: {
        user: User | null;
        token: string | null;
        role: string | null;
      };
    }) => state.auth
  );
  return { user, token, role };
};

export default useAuth;
