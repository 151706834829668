import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPageLoading: false,
  isActionLoading: false,
  isShowFilters: true,
  isShowEmptyState: false,
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    showHidePageLoader: (state, action) => {
      state.isPageLoading = action.payload;
    },
    showHideActionLoader: (state, action) => {
      state.isActionLoading = action.payload;
    },
    showHideFilters: (state, action) => {
      state.isShowFilters = action.payload;
    },
    setCanShowEmptyState: (state, action) => {
      state.isShowEmptyState = action.payload;
    },
  },
});

export const { showHidePageLoader, showHideActionLoader, showHideFilters, setCanShowEmptyState } = loaderSlice.actions;
export default loaderSlice.reducer;
