import { FC, useEffect } from 'react';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import s from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IBaseState, setToggleMobileSideBar, setToggleSideBar } from '../../../store/Base/slice';

const Hamburger: FC = () => {
  const { sideBar, mobileSideBar } = useSelector((state: { base: IBaseState }) => state.base);
  const dispatch = useDispatch();

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1200px)',
  });
  const isMobile = useMediaQuery({ query: '(max-width:576px)' });

  useEffect(() => {
    if (!isMobile) {
      dispatch(setToggleMobileSideBar(false));
      if (isDesktopOrLaptop) {
        dispatch(setToggleSideBar(false));
      } else {
        dispatch(setToggleSideBar(null));
      }
    } else {
      dispatch(setToggleMobileSideBar(false));
      dispatch(setToggleSideBar(false));
    }
  }, [isDesktopOrLaptop, isMobile]);

  // toggle side menu
  const toggleSideMenu = () => {
    if (isMobile) {
      dispatch(setToggleMobileSideBar(null));
    } else {
      dispatch(setToggleSideBar(null));
    }
  };
  return (
    <button type="button" className={s.hamburger} onClick={toggleSideMenu}>
      {sideBar.isClosed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
    </button>
  );
};

export default Hamburger;
