import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ROUTES } from '@/models';
import styles from './index.module.scss';

export interface IAddBtnProps {
  link?: ROUTES | string;
  onClick?: () => void;
  text: string;
}

const AddBtn = ({ onClick, text, link }: IAddBtnProps) => (
  <div className={styles.AddBtnWrap}>
    {link ? (
      <Link to={link}>
        <Button onClick={onClick} type='primary' icon={<PlusOutlined />}>
          {text}
        </Button>
      </Link>
    ) : (
      <Button onClick={onClick} type='primary' icon={<PlusOutlined />}>
        {text}
      </Button>
    )}
  </div>
);

export default AddBtn;
