import { useState } from 'react';

const useModalState = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(prevSate => !prevSate);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  return {
    isOpen,
    toggleModal,
    closeModal,
    openModal,
  };
};

export default useModalState;
