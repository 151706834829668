import { Select, Form } from 'antd';
import { t } from 'i18next';
import { useEffect } from 'react';
import { RULES } from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { LanguagesEnum } from '../../../models/general/lang';

interface LanguagesProps {
  fieldName?: string;
  placeHolder?: string;
  label?: string;
  required?: boolean;
}

const Languages = ({
  fieldName = 'language',
  label = `${t('language')} :`,
  placeHolder = t('selectHobby'),
  required = false,
}: LanguagesProps) => {
  const currentLang = useSelector((state: { base: { lang: string } }) => state.base.lang);

  const languages = [
    { id: '1', name: 'English', nameAr: 'الإنجليزية' },
    { id: '2', name: 'Arabic', nameAr: 'العربية' },
  ];

  return (
    <Form.Item rules={required ? [RULES.required] : []} name={fieldName} label={label}>
      <Select
        mode="multiple"
        filterOption
        optionFilterProp="label"
        showSearch
        placeholder={placeHolder}
        getPopupContainer={(trigger) => trigger.parentNode}
        options={languages?.map((lang) => ({
          label: currentLang === LanguagesEnum.EN ? lang.name : lang.nameAr,
          value: lang.id,
        }))}
      />
    </Form.Item>
  );
};

export default Languages;
