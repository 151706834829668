// eslint-disable-next-line import/no-unresolved
import { FormInstance, message } from 'antd';
import { t } from 'i18next';
import { formatDate } from './dates';
import { displayErrorsOnFail } from './form';
import { FormatDateEnum, KeyofTypeofObj, MenuItem, RouteDto } from '../../models';

export const capitalizeString = (str: string): string => {
  if (!str) return '';

  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

// Find the menuItem with the attribute value from the MenuList based on the attribute value
export function getMenuItemInMenuListByProperty(
  menuList: Array<MenuItem>,
  key: string,
  value: string
) {
  let stack: Array<any> = [];
  stack = stack.concat(menuList);
  let res;
  while (stack.length) {
    const cur = stack.shift();
    if (cur.children && cur.children.length > 0) {
      stack = cur.children.concat(stack);
    }
    if (value === cur[key]) {
      res = cur;
    }
  }
  return res;
}

// query ancestor to get the parent from child
export function queryAncestors(
  array: RouteDto[],
  current: RouteDto,
  parentId: string,
  id: string = 'id'
) {
  const result = [current];
  const hashMap = new Map();
  array.forEach((item: any) => hashMap.set(item[id], item));

  const getPath = (current: (typeof array)[number]) => {
    const currentParentId = hashMap.get(
      current[id as keyof (typeof array)[number]]
    )[parentId];
    if (currentParentId) {
      result.push(hashMap.get(currentParentId));
      getPath(hashMap.get(currentParentId));
    }
  };

  getPath(current);
  return result;
}

// return array of value
export const returnArrayFromValue = (value: any) => {  
  if (Array.isArray(value) || value === undefined) {
    return value;
  }
  return [value];
};

// update item in array of objects by id
export const updateItemById = (
  originalArray: any[],
  propertyName: string,
  propertyValue: any,
  id: string
) => {
  const newArray = [...originalArray];

  newArray.map(item => {
    if (item.objectID === id) {
      item[propertyName] = propertyValue;
    }
    return item;
  });
  return newArray;
};

export const getPropertyWithArrayTypeObject = (
  object: Record<any, any> = {}
) => {
  const objectValues = Object.values(object);
  const foundVal = objectValues.find(objectValue => Array.isArray(objectValue));
  if (foundVal) {
    return Object.keys(object)[objectValues.indexOf(foundVal)];
  }
};

export const isLoadingFromArr = (values: boolean[]) => values.includes(true);

export const startCase = (str: string) =>
  str
    .toLowerCase()
    .replace(/(^|\s)\S/g, firstLetter => firstLetter.toUpperCase());

export const toTitleCase = (str: string) =>
  str
    .replace(/([a-z])([A-Z])/g, '$1 $2') // insert space between camel case
    .replace(/\b\w/g, c => c.toUpperCase());

export const isObject = (value: any) => typeof value === 'object';

export const isNull = (value: any) => value === null;
export const isUndefined = (value: any) => value === 'null';

export const getObjectDetails = (obj: Record<string, any> = {}) => {
  let str = '';
  type ObjectKey = KeyofTypeofObj<typeof obj>;
  if (isObject(obj) && !isNull(obj) && !isUndefined(obj)) {
    // eslint-disable-next-line array-callback-return
    Object.keys(obj)?.map(key => {
      if (key === 'isEnabled') return;
      if (key === 'isMain') return;
      if (key === 'images') return;

      if (isObject(obj[key as ObjectKey])) {
        str += `${toTitleCase(key)} :\n`;
        str += getObjectDetails(obj[key as ObjectKey]);
      } else if (
        key.includes('date') ||
        key.includes('At') ||
        key.includes('Date')
      ) {
        str += `${toTitleCase(key)} :${formatDate(
          obj[key],
          FormatDateEnum.YMDHMA
        )}\n`;
      } else str += `${toTitleCase(key)} : ${obj[key as ObjectKey]}\n`;
    });
  }
  return str;
};

export const sleep = (ms: number) =>
  // eslint-disable-next-line no-promise-executor-return
  new Promise(resolve => setTimeout(resolve, ms));

export const convertToArrByIdsOrObjectIds = (
  objectsArr: any & ({ objectID: string } | { id: string })[]
) => {
  if (objectsArr) {
    return objectsArr.map(
      (objectItem: any & ({ objectID: string } | { id: string })) => {
        if ('objectID' in objectItem) {
          return objectItem.objectID;
        }
        return objectItem.id;
      }
    );
  }
  return [];
};

export const displaySuccessEditOrCreateMessage = (
  isEdit: boolean,
  elementName: string
) =>
  message.success(
    t(isEdit ? 'elementUpdatedSuccessfully' : 'newElementAddedSuccessfully', {
      elementName,
    })
  );

export async function validateForm<T>(
  form: FormInstance<T>,
  callback: (values: T) => void,
  onError?: (errors: any) => void
) {
  try {
    const values = await form.validateFields();
    callback?.(values);
  } catch (error) {
    if (onError) {
      displayErrorsOnFail<T>(error);
    } else {
      message.error(t('pleaseFixFieldsErrorThenTryAgain'));
    }
  }
}

export const changeEnabledStatus = (isEnabled: boolean, itemName: string) => {
  message.destroy();
  if (isEnabled) {
    message.success(t('enabledStatusSuccess', { itemName }));
  } else {
    message.success(t('disabledStatusSuccess', { itemName }));
  }
};

export const copyToClipboard = (text: string, onSuccess?: () => void) => {
  const tempInput = document.createElement('input');
  tempInput.value = text;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  if (onSuccess) {
    onSuccess();
  }
  message.destroy();
  message.success(t('copied'));
  document.body.removeChild(tempInput);
};

export const renderRegisterType = (email: string) => {
  if (!email) {
    return t('phone');
  }
  if (Number(email) === 1) {
    return t('error');
  }
  if (email.includes('gmail.com')) {
    return t('google');
  }
  if (
    email.includes('privaterelay.appleid.com') ||
    email.includes('icloud.com')
  ) {
    return t('apple');
  }
  return t('email');
};
export const numberWithCommas = (number: number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export function moveArrayItemToNewIndex<TArrItemType>(
  arr: TArrItemType[],
  oldIndex: number,
  newIndex: number
) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    // eslint-disable-next-line no-plusplus
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
}

export const isArrayAndHaveItems = (data: any) =>
  Array.isArray(data) && data.length > 0;
