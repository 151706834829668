import { createSlice } from '@reduxjs/toolkit';
import { GetAllSessionDTO, GetSessionDetailsRequest } from '../../models/session/session';
import { TalentsLookups } from '../../models/lookups/talents-lookups/talents-lookups';
import { ResellersLookups } from '../../models/lookups/resellers-lookups/resellers-lookups';

interface initialListType {
  list: GetAllSessionDTO[];
  currentPage: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  isLoading: boolean;
  isAddedOrEdited: boolean;
  isCTALoading: boolean;
  sessionDetails: GetSessionDetailsRequest;
  isGetDetailsLoading: boolean;
  lookUpResellersList: {
    list: ResellersLookups[];
    isLoading: boolean;
    filtersData?: GetAllSessionDTO;
  };
  lookUpTalentsList: {
    list: TalentsLookups[];
    isLoading: boolean;
    filtersData?: GetAllSessionDTO;
  };
}

const initialState: initialListType = {
  list: [],
  isLoading: false,
  currentPage: 1,
  pageSize: 25,
  totalCount: 0,
  totalPages: 0,
  isAddedOrEdited: false,
  isCTALoading: false,
  sessionDetails: null,
  isGetDetailsLoading: false,
  lookUpResellersList: {
    list: [],
    isLoading: false,
    filtersData: null,
  },
  lookUpTalentsList: {
    list: [],
    isLoading: false,
    filtersData: null,
  },
};

export const sessionSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    // Get All
    getSessionsPaginatedRequest: (state: initialListType, action) => {
      state.isLoading = true;
      if (action.payload?.currentPage) state.currentPage = action.payload?.page;
      if (action.payload?.pageSize) state.pageSize = action.payload?.perPage;
    },
    getSessionsPaginatedResponse: (state: initialListType, action) => {
      state.isLoading = false;
      state.list = action.payload?.data;
      state.currentPage = action.payload?.currentPage;
      state.pageSize = action.payload?.pageSize;
      state.totalCount = action.payload?.totalCount;
      state.totalPages = action.payload?.totalPages;
    },
    clearGetSessionsListPage: (state: initialListType) => {
      state = initialState;
    },

    // Get By Id
    getSessionByIdRequest: (state: initialListType, action) => {
      state.isGetDetailsLoading = true;
    },
    getSessionByIdResponse: (state: initialListType, action) => {
      state.isGetDetailsLoading = false;
      state.sessionDetails = action.payload;
    },
    clearSessionDetails: (state: initialListType) => {
      state.sessionDetails = null;
      state.isGetDetailsLoading = false;
    },

    // Add
    addSessionRequest: (state: initialListType, action) => {
      state.isCTALoading = true;
    },
    addSessionResponse: (state: initialListType, action) => {
      state.isCTALoading = false;
      state.isAddedOrEdited = action.payload !== null;
    },

    changeOneKeyOfSessionState: (state: initialListType, action) => {
      state[action.payload.key] = action.payload.value;
    },

    // Update
    updateSessionRequest: (state: initialListType, action) => {
      state.isCTALoading = true;
    },
    updateSessionResponse: (state: initialListType, action) => {
      state.isCTALoading = false;
      state.isAddedOrEdited = action.payload !== null;
    },

    // Lookup
    getLookUpResellersRequest: (state: initialListType, action) => {
      state.lookUpResellersList.isLoading = true;
    },
    getLookUpResellersResponse: (state: initialListType, action) => {
      state.lookUpResellersList.isLoading = false;
      state.lookUpResellersList.list = action.payload;
    },

    getLookUpTalentsRequest: (state: initialListType, action) => {
      state.lookUpTalentsList.isLoading = true;
    },

    getLookUpTalentsResponse: (state: initialListType, action) => {
      state.lookUpTalentsList.isLoading = false;
      state.lookUpTalentsList.list = action.payload;
    },
  },
});

export const {
  getSessionsPaginatedRequest,
  getSessionsPaginatedResponse,
  clearGetSessionsListPage,
  getLookUpResellersRequest,
  getLookUpResellersResponse,
  getLookUpTalentsRequest,
  getLookUpTalentsResponse,
  getSessionByIdRequest,
  getSessionByIdResponse,
  clearSessionDetails,
  addSessionRequest,
  addSessionResponse,
  changeOneKeyOfSessionState,
  updateSessionRequest,
  updateSessionResponse,

} = sessionSlice.actions;
export default sessionSlice.reducer;
