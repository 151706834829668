import { t } from 'i18next';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { DeleteOutlined, EditOutlined, FilterFilled, UserOutlined } from '@ant-design/icons';
import { Button, DatePicker, Flex, Input, TimePicker, Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';

import { useDispatch, useSelector } from 'react-redux';
import PopconfirmComp from '../../../components/common/popconfirm';
import useDebouncedCallback from '../../../helpers/UseHooks/useDebouncedCallback';
import { useTablePagination } from '../../../hooks';
import { ISessionListItem, ROUTES } from '../../../models';
import { ResellersLookups } from '../../../models/lookups/resellers-lookups/resellers-lookups';
import { TalentsLookups } from '../../../models/lookups/talents-lookups/talents-lookups';
import { SessionsPaginationFilterParams } from '../../../models/session/session';
import {
  clearGetSessionsListPage,
  getLookUpResellersRequest,
  getLookUpTalentsRequest,
  getSessionsPaginatedRequest,
} from '../../../store/Session/slice';
import TalentBulkActions from '../components/TalentBulkActions';
import './index.module.scss';
import { routesList, routesWithParams } from '../../../routes/routesDetails';

const SessionsList: FC = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state: { base: { lang: string } }) => state.base);
  const [showBulkActionsSection, setShowBulkActionsSection] = useState(false);
  const [showAddSessionBtn, setShowAddSessionBtn] = useState(false);
  const searchByExperienceIdParam = new URLSearchParams(window.location.search).get('searchByExperienceId');
  const navigate = useNavigate();

  useEffect(() => {
    if (
      searchByExperienceIdParam !== null &&
      searchByExperienceIdParam !== undefined &&
      searchByExperienceIdParam !== ''
    ) {
      setShowAddSessionBtn(true);
    } else {
      setShowAddSessionBtn(false);
    }
  }, [searchByExperienceIdParam]);

  useEffect(() => {
    debouncedPrepareLookups();
    return () => {
      dispatch(clearGetSessionsListPage());
    };
  }, []);

  const defaultTablePagination = {
    position: ['bottomCenter'],
    showQuickJumper: false,
    responsive: true,
    showSizeChanger: false,
    showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} من ${total} عناصر`,
  };

  const { list, isLoading, currentPage, pageSize, totalCount, lookUpResellersList, lookUpTalentsList } = useSelector(
    (state: {
      sessions: {
        list: ISessionListItem[];
        isLoading: boolean;
        currentPage: number;
        pageSize: number;
        totalCount: number;
        lookUpResellersList: {
          list: ResellersLookups[];
        };
        lookUpTalentsList: {
          list: TalentsLookups[];
        };
      };
    }) => {
      return {
        list: state.sessions.list,
        isLoading: state.sessions.isLoading,
        currentPage: state.sessions.currentPage,
        pageSize: state.sessions.pageSize,
        totalCount: state.sessions.totalCount,
        lookUpResellersList: state.sessions.lookUpResellersList.list,
        lookUpTalentsList: state.sessions.lookUpTalentsList.list,
      };
    }
  );

  const { handlePagination, pagination, filterObject, searchText } = useTablePagination({
    searchProperties: [
      'searchByExperienceId',
      'searchBySessionId',
      'searchBySessionDate',
      'searchBySessionTime',
      'searchWithTotalSeats',
      'searchWithSupliftPrice',
      'searchByResellersId',
      'searchByTalentsId',
    ],
    filterSearchProperties: [
      'searchByExperienceId',
      'searchBySessionId',
      'searchBySessionDate',
      'searchBySessionTime',
      'searchWithTotalSeats',
      'searchWithSupliftPrice',
      'searchByResellersId',
      'searchByTalentsId',
      'cost',
      'city',
      'Hobby',
    ],
    callback: (payload: SessionsPaginationFilterParams) => {
      debouncedPrepareQueryParam(payload);
    },
  });

  const columns: ColumnsType<ISessionListItem> = [
    {
      title: `${t('experienceId')}`,
      dataIndex: 'experienceId',
      key: 'searchByExperienceId',
      // is Searchable
      ...filterObject({
        name: 'searchByExperienceId',
        type: 'search',
        placeHolder: `${t('experienceId')}`,
      }),
      // Custom filter icon
      filterIcon: (filtered) => <FilterFilled />,
      // is sorted
      sorter: true,
      render: (id) => <Link to={ROUTES.EDIT_EXPERIENCES.replace(':id', id.toString())}>{id}</Link>,
    },
    {
      title: t('sessionId'),
      dataIndex: 'id',
      key: 'searchBySessionId',
      ...filterObject({
        name: 'searchBySessionId',
        type: 'search',
        placeHolder: 'id',
      }),
      filterIcon: (filtered) => <FilterFilled />,
      sorter: true,
    },
    {
      title: t('date'),
      dataIndex: 'sessionDate',
      key: 'searchBySessionDate',
      filterIcon: (filtered) => <FilterFilled />,
      filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <DatePicker
            placeholder="From Date"
            value={selectedKeys[0]}
            onChange={(date) => setSelectedKeys([date, selectedKeys[1]])}
            style={{ width: '100%', marginBottom: 8, display: 'block' }}
          />
          <DatePicker
            placeholder="To Date"
            value={selectedKeys[1]}
            onChange={(date) => setSelectedKeys([selectedKeys[0], date])}
            style={{ width: '100%', marginBottom: 8, display: 'block' }}
          />
          <Button type="primary" onClick={() => confirm()} size="small" style={{ width: 90, marginRight: 8 }}>
            Filter
          </Button>
          <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      sorter: true,
      width: '200px',
    },
    {
      title: t('time'),
      dataIndex: 'sessionTime',
      key: 'searchBySessionTime',
      filterIcon: (filtered) => <FilterFilled />,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <TimePicker
            placeholder="From Time"
            value={selectedKeys[0] as any}
            onChange={(data: any) => setSelectedKeys([data, selectedKeys[1]])}
            style={{ width: '100%', marginBottom: 8, display: 'block' }}
          />
          <TimePicker
            placeholder="To Time"
            value={selectedKeys[1] as any}
            onChange={(data: any) => setSelectedKeys([selectedKeys[0], data])}
            style={{ width: '100%', marginBottom: 8, display: 'block' }}
          />
          <Button type="primary" onClick={() => confirm()} size="small" style={{ width: 90, marginRight: 8 }}>
            Filter
          </Button>
          <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      sorter: true,
    },
    {
      title: t('seats'),
      dataIndex: 'totalSeats',
      key: 'searchWithTotalSeats',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            type="number"
            placeholder="From"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value, selectedKeys[1]] : [])}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Input
            type="number"
            placeholder="To"
            value={selectedKeys[1]}
            onChange={(e) => setSelectedKeys(e.target.value ? [selectedKeys[0], e.target.value] : [])}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
              // Handle the filter logic here
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}>
            Filter
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => <FilterFilled />,
      sorter: true,
    },
    {
      title: t('attendees'),
      dataIndex: 'attendeesCount',
      key: 'searchWithBookedSeats',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            type="number"
            placeholder="From"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value, selectedKeys[1]] : [])}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Input
            type="number"
            placeholder="To"
            value={selectedKeys[1]}
            onChange={(e) => setSelectedKeys(e.target.value ? [selectedKeys[0], e.target.value] : [])}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
              // Handle the filter logic here
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}>
            Filter
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => <FilterFilled />,
      sorter: true,
    },
    {
      title: t('city'),
      dataIndex: 'city',
      key: 'city',
      sorter: true,
    },
    {
      title: t('hobby'),
      dataIndex: 'hobbies',
      key: 'Hobby',
      sorter: true,
      filterIcon: (filtered) => <FilterFilled />,
      render: (hobby) => hobby?.map((item) => (lang === 'ar' ? item?.name : item?.nameEn)).join(', '),
    },
    {
      title: t('price'),
      dataIndex: 'cost',
      key: 'cost',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            type="number"
            placeholder="From"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value, selectedKeys[1]] : [])}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Input
            type="number"
            placeholder="To"
            value={selectedKeys[1]}
            onChange={(e) => setSelectedKeys(e.target.value ? [selectedKeys[0], e.target.value] : [])}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
              // Handle the filter logic here
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}>
            Filter
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => <FilterFilled />,
      sorter: true,
    },
    {
      title: t('Suplift Price'),
      dataIndex: 'supliftPrice',
      key: 'searchWithSupliftPrice',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            type="number"
            placeholder="From"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value, selectedKeys[1]] : [])}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Input
            type="number"
            placeholder="To"
            value={selectedKeys[1]}
            onChange={(e) => setSelectedKeys(e.target.value ? [selectedKeys[0], e.target.value] : [])}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
              // Handle the filter logic here
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}>
            Filter
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => <FilterFilled />,
      sorter: true,
    },
    {
      title: t('resellers'),
      dataIndex: 'distributor',
      key: 'searchByResellersId',
      render: (distributor: { id: number; name: string }) => {
        return <>{distributor?.name}</>;
      },
      filterMultiple: true,
      filters: lookUpResellersList?.map((item) => ({ text: item?.name, value: item?.id })) ?? [],
      filterSearch(input, record) {
        return record?.text?.toLowerCase()?.includes(input?.toLowerCase());
      },
      sorter: true,
    },
    {
      title: t('talents'),
      dataIndex: 'coach',
      key: 'searchByTalentsId',
      render: (coach: { id: number; name: string }) => {
        return <>{coach?.name}</>;
      },
      filterMultiple: true,
      filters: lookUpTalentsList?.map((item) => ({ text: item?.name, value: item?.id })) ?? [],
      filterSearch(input, record) {
        return record?.text?.toLowerCase()?.includes(input?.toLowerCase());
      },
      sorter: true,
    },
    {
      title: t('actions'),
      dataIndex: 'objectID',
      key: 'objectID',
      width: '200px',
      fixed: 'right',
      render: (objectID, rowData) => (
        <Flex gap="10px" align="center">
          <Link
            to={ROUTES.EDIT_SESSIONS.replace(':expId', rowData.experienceId.toString()).replace(
              ':sessionId',
              rowData?.id.toString()
            )}>
            <Tooltip placement="top" title={`${'Edit'}`}>
              <Button type="primary" shape="circle" icon={<EditOutlined />} />
            </Tooltip>
          </Link>
          <Link to={`/sessions/${objectID}/attendees`}>
            <Tooltip placement="top" title={`${'attendees'}`}>
              <Button type="primary" shape="circle" icon={<UserOutlined />} />
            </Tooltip>
          </Link>

          <PopconfirmComp
            title={'Delete this session?'}
            okText={'yes'}
            cancelText={'no'}
            handleConfirm={() => () => {}}>
            <Tooltip placement="top" title={'Delete Session'}>
              <Button type="primary" shape="circle" icon={<DeleteOutlined />} />
            </Tooltip>
          </PopconfirmComp>
        </Flex>
      ),
    },
  ];
  const formatDate = (date?: string, isTime?: boolean) => {
    if (!date) {
      return null;
    }
    const Dated: Date = new Date(date);

    if (isTime) {
      const hours: string = Dated.getUTCHours().toString().padStart(2, '0');
      const minutes: string = Dated.getUTCMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    }

    const year: string = Dated.getUTCFullYear().toString();
    const month: string = (Dated.getUTCMonth() + 1).toString().padStart(2, '0');
    const day: string = (Dated.getUTCDate() + 1).toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const getListOfSessionsPaginated = (payload: SessionsPaginationFilterParams) => {
    dispatch(getSessionsPaginatedRequest(payload));
  };
  const prepareQueryParam = (payload: SessionsPaginationFilterParams) => {
    // Sort By
    const sortOptions = {
      id_asc: 'sessionIdAsc',
      id_desc: 'sessionIdDesc',
      experienceId_asc: 'experienceIdAsc',
      experienceId_desc: 'experienceIdDesc',
      sessionDate_asc: 'sessionDateAsc',
      sessionDate_desc: 'sessionDateDesc',
      sessionTime_asc: 'sessionTimeAsc',
      sessionTime_desc: 'sessionTimeDesc',
      totalSeats_asc: 'sessionTotalSeatsAsc',
      totalSeats_desc: 'sessionTotalSeatsDesc',
      attendeesCount_asc: 'sessionBookedSeatsAsc',
      attendeesCount_desc: 'sessionBookedSeatsDesc',
      city_asc: 'sessionCityAsc',
      city_desc: 'sessionCityDesc',
      hobbies_asc: 'hobbiesAsc',
      hobbies_desc: 'hobbiesDesc',
      cost_asc: 'costAsc',
      cost_desc: 'costDesc',
      supliftPrice_asc: 'supliftPriceAsc',
      supliftPrice_desc: 'supliftPriceDesc',
      distributor_asc: 'sessionResellerAsc',
      distributor_desc: 'sessionResellerDesc',
      coach_asc: 'sessionTalentAsc',
      coach_desc: 'sessionTalentDesc',
    };

    let Query: SessionsPaginationFilterParams = {
      page: pagination.current,
      perPage: pagination.pageSize,
      sortBy: sortOptions[payload['sortBy']],
    };

    const sortBy = payload['sortBy'];
    Query.sortBy = sortOptions[sortBy] || null;
    if (Query.sortBy === null) {
      delete Query.sortBy;
    }

    // Filter By
    if (payload['searchByExperienceId']) {
      Query.searchByExperienceId = payload['searchByExperienceId'];
    } else {
      delete Query.searchByExperienceId;
    }

    if (payload['searchBySessionId']) {
      Query.searchBySessionId = payload['searchBySessionId'];
    } else {
      delete Query.searchBySessionId;
    }

    if (payload['searchBySessionDate']) {
      Query.searchBySessionDateFrom = formatDate(payload['searchBySessionDate'][0]);
      Query.searchBySessionDateTo = formatDate(payload['searchBySessionDate'][1]);
    } else {
      delete Query.searchBySessionDateFrom;
      delete Query.searchBySessionDateTo;
    }

    if (payload['searchBySessionTime']) {
      Query.searchBySessionTimeFrom = formatDate(payload['searchBySessionTime'][0], true);
      Query.searchBySessionTimeTo = formatDate(payload['searchBySessionTime'][1], true);
    } else {
      delete Query.searchBySessionTimeFrom;
      delete Query.searchBySessionTimeTo;
    }

    if (payload['searchWithTotalSeats']) {
      Query.searchWithTotalSeatsFrom = +payload['searchWithTotalSeats'][0];
      Query.searchWithTotalSeatsTo = +payload['searchWithTotalSeats'][1];
    } else {
      delete Query.searchWithTotalSeatsFrom;
      delete Query.searchWithTotalSeatsTo;
    }

    if (payload['searchWithBookedSeats']) {
      Query.searchWithBookedSeatsFrom = +payload['searchWithBookedSeats'][0];
      Query.searchWithBookedSeatsFrom = +payload['searchWithBookedSeats'][1];
    } else {
      delete Query.searchWithBookedSeatsFrom;
      delete Query.searchWithBookedSeatsTo;
    }

    if (payload['cost']) {
      Query.searchWithCostFrom = +payload['cost'][0];
      Query.searchWithCostTo = +payload['cost'][1];
    } else {
      delete Query.searchWithCostFrom;
      delete Query.searchWithCostTo;
    }

    if (payload['searchWithSupliftPrice']) {
      Query.searchWithSupliftPriceFrom = +payload['searchWithSupliftPrice'][0];
      Query.searchWithSupliftPriceTo = +payload['searchWithSupliftPrice'][1];
    } else {
      delete Query.searchWithSupliftPriceFrom;
      delete Query.searchWithSupliftPriceTo;
    }

    if (payload['searchByResellersId']) {
      if (typeof payload['searchByResellersId'] === 'string') {
        Query.searchByResellersId = [+payload['searchByResellersId']];
      } else {
        Query.searchByResellersId = payload['searchByResellersId']?.map((item) => +item);
      }
    } else {
      delete Query.searchByResellersId;
    }

    if (payload['searchByTalentsId']) {
      if (typeof payload['searchByTalentsId'] === 'string') {
        Query.searchByTalentsId = [+payload['searchByTalentsId']];
      } else {
        Query.searchByTalentsId = payload['searchByTalentsId']?.map((item) => +item);
      }
    } else {
      delete Query.searchByTalentsId;
    }
    getListOfSessionsPaginated(Query);
  };

  const getAllLookups = () => {
    dispatch(getLookUpResellersRequest(null));
    dispatch(getLookUpTalentsRequest(null));
  };

  const debouncedPrepareQueryParam = useDebouncedCallback(prepareQueryParam, 600);
  const debouncedPrepareLookups = useDebouncedCallback(getAllLookups, 500);

  const addNewSession = (searchByExperienceId?: string) => {
      let queryParams = '';

      if (searchByExperienceId !== undefined) {
        queryParams = `?experienceId=${searchByExperienceId}`;
      }

      navigate(`${ROUTES.ADD_SESSION}${queryParams}`);
  };

  return (
    <>
      <Flex gap="10px" className="mb-5">
        {/* <Button type="default" onClick={() => setShowBulkActionsSection(!showBulkActionsSection)}>
          Bulk Actions
        </Button> */}
        {showAddSessionBtn && (
          <Button type="primary" onClick={() => addNewSession(searchByExperienceIdParam)}>
            Add New Session
          </Button>
        )}
      </Flex>

      {showBulkActionsSection && <TalentBulkActions />}

      <Table
        loading={isLoading}
        rowKey="objectID"
        columns={columns}
        dataSource={list}
        onChange={handlePagination}
        className="mt-5"
        pagination={{
          ...defaultTablePagination,
          ...pagination,
          total: totalCount,
          current: currentPage,
          pageSize,
        }}
        scroll={{ x: true }}
      />
    </>
  );
};

export default SessionsList;
