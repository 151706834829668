import { useState, useEffect } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { FilterDropdown, FilterDropdownIcon } from '../components';
import { pageSizeConstant } from '../helpers/constants';

// pagination object interface
interface Pagination {
  pageSize: number;
  current: number;
}

export interface filterObjectParams {
  name: string;
  type: 'search' | 'filter';
  placeHolder?: string;
  inputType?: 'single' | 'multi' | 'date';
  optionValueName?: string;
  onChange?: (value: string) => void;
  onReset?: () => void;
  optionName?: string;
  options?: any[];
  resettedFieldName?: string;
  iconVisible?: boolean;
}

// sorted info interface
interface SortedInfo {
  columnKey?: string;
  order?: 'ascend' | 'descend';
}

export interface IUseTablePaginationProps {
  searchProperties?: string[];
  callback: (values: any) => void;
  filterSearchProperties?: string[];
  withScrollOnChange?: boolean;
}

export function useTablePagination({
  searchProperties = [],
  callback,
  filterSearchProperties = [],
  withScrollOnChange = true,
}: IUseTablePaginationProps) {
  type SearchPropertyObject<T> = {
    [key in (typeof searchProperties)[number]]: T;
  };
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams({});
  const [searchText, setSearchText] = useState<SearchPropertyObject<any>>({});
  const [filterDropdownVisible, setFilterDropdownVisible] = useState<SearchPropertyObject<boolean>>({});
  const [sortedInfo, setSortedInfo] = useState<SortedInfo>({});
  const [pagination, setPagination] = useState<Pagination>({
    pageSize: pageSizeConstant,
    current: 1,
  });

  // paginate based of search params
  const paginate = (searchFields: [string, string][]) => {    
    const { pageSize } = pagination;
    const payload: any = {
      limit: pageSize,
    };
    const newSearchText: SearchPropertyObject<any> = {};
    const newSortedInfo: SortedInfo = {};
    let newPagination: Pagination = { ...pagination };

    if (searchFields?.length > 0) {
      for (const entry of searchFields) {        
        const [param, value] = entry;

        switch (param) {
          case 'sortBy':          
            if (value && value.split('_').length >= 2) {
              const splitSortBy = value.split('_');
              newSortedInfo.columnKey = splitSortBy.length > 2 ? `${splitSortBy[0]}_${splitSortBy[1]}` : splitSortBy[0];
              newSortedInfo.order = splitSortBy[splitSortBy.length - 1] === 'asc' ? 'ascend' : 'descend';
              payload.sortBy = value;
            }
            break;
          case 'page':
            payload.page = parseInt(value) - 1;
            newPagination = { ...pagination, current: parseInt(value) };
            break;
          default:
            if (searchProperties.includes(param)) {
              newSearchText[param] = value;
              payload[param] = value;
            }
        }
      }
      setSortedInfo({ ...newSortedInfo });
      setPagination({ ...newPagination });
      setSearchText({ ...newSearchText });
      if (withScrollOnChange) {
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      }
      callback?.(payload);
    } else {
      payload.page = 0;
      if (withScrollOnChange) {
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      }

      callback?.(payload);
    }
  };

  useEffect(() => {    
    paginate(Object.entries(qs.parse(location?.search)));
  }, [searchParams]);

  const changeDropdownVisibility = (field: string, state: boolean) => {
    setFilterDropdownVisible({ ...filterDropdownVisible, [field]: state });
  };

  // handle search
  const handleSearch = (field: string) => {
    changeDropdownVisibility(field, false);
    setPagination({ ...pagination, current: 1 });

    // reset the values of search before setting values to it
    searchProperties.forEach((item) => {
      if (searchParams.has(item)) {
        searchParams.delete(item);
      }
    });

    // assign values to query params
    Object.keys(searchText).forEach((item: string) => {
      searchParams.set(item, String(searchText[item]));
    });

    // delete page when search
    searchParams.delete('page');
    // set search params
    setSearchParams(searchParams);
  };

  //  handle reset
  const handleReset = (field: string) => {
    delete searchText[field];
    // reset the values of search before setting values to it
    if (searchParams.has(field)) {
      searchParams.delete(field);
    }
    setFilterDropdownVisible({ ...filterDropdownVisible, [field]: false });
    setSearchParams(searchParams);
  };

  // handle table pagination
  const handlePagination = (newPagination: any, filters: {}, sorter: any) => {
     
    for (const [key, value] of Object.entries(filters)) {
      if (filterSearchProperties?.includes(key)) {
        searchParams.delete(key);
      }
      if (value !== null && Array.isArray(value)) {
        if (value.length === 1) {
          searchParams.set(key, value[0]);
        } else {
          value.forEach((val) => searchParams.append(key, val));
        }
      }
    }

    const { current } = newPagination;
    setPagination({ ...pagination, current: current ?? 1 });

    // set the current to search params
    if (current !== undefined) {
      searchParams.set('page', String(current));
    }

    // set sortBy to search params
    if (sorter.field && sorter.order) {
      setSortedInfo({
        columnKey: String(sorter.field) ?? '',
        order: sorter.order ?? '',
      });
      searchParams.set('sortBy', `${sorter.field}_${sorter.order === 'ascend' ? 'asc' : 'desc'}`);
    } else {
      searchParams.delete('sortBy');
    }

    setSearchParams(searchParams);
  };

  const handleInputChange = (prop: (typeof searchProperties)[number], val: string | number) => {
    setSearchText({ ...searchText, [prop]: val });
  };

  const filterObject = ({
    name,
    type,
    placeHolder,
    inputType = 'single',
    optionValueName,
    optionName,
    onChange,
    options,
    onReset,
    iconVisible = true,
    resettedFieldName,
  }: filterObjectParams) => ({
    filterDropdown: (
      <FilterDropdown
        name={name}
        handleSearch={handleSearch}
        handleReset={(fieldName) => {
          handleReset(fieldName);
          if (onReset) {
            onReset();
          }
        }}
        handleChange={(prop, val) => {
          handleInputChange(prop, val);
          if (onChange) {
            onChange(val);
          }
          if (resettedFieldName && searchText[resettedFieldName]) {
            const newSearchText = { ...searchText };
            delete newSearchText[resettedFieldName];
            setSearchText(newSearchText);
          }
        }}
        value={searchText[name]}
        visible={filterDropdownVisible[name]}
        placeHolder={placeHolder}
        inputType={inputType}
        optionValueName={optionValueName}
        optionName={optionName}
        options={options}
      />
    ),
    filterIcon: iconVisible ? <FilterDropdownIcon type={type} value={searchText[name]} /> : <></>,
    filterDropdownOpen: filterDropdownVisible[name],
    onFilterDropdownOpenChange: (visible: boolean) => changeDropdownVisibility(name, visible),
    filteredValue: searchText[name],
  });

  return {
    handlePagination,
    sortedInfo,
    pagination,
    filterObject,
    searchText,
    searchParams,
  };
}

export default useTablePagination;
