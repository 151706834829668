import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

interface IInfoTooltipProps {
  title: string;
}

const InfoTooltip = ({ title = '' }: IInfoTooltipProps) => (
    <Tooltip className={styles.tooltip} title={title}>
      <QuestionCircleOutlined />
    </Tooltip>
  );

export default InfoTooltip;
