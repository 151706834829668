import React, { createContext, useContext, ReactNode } from 'react';
import { Form } from 'antd';
import { IExperienceFormFields } from '../../../models';
import { FormInstance } from 'antd/lib';

interface ExpFormContextProps {
  form: FormInstance<IExperienceFormFields>;
  getFieldValue: (name: string) => any;
  setFieldsValue: (values: Record<string, any>) => void;
}

const ExpFormContext = createContext<ExpFormContextProps | undefined>(undefined);

interface ExpFormProviderProps {
  children: ReactNode;
}

export const ExpFormProvider: React.FC<ExpFormProviderProps> = ({ children }) => {
  const [form] = Form.useForm<IExperienceFormFields>();

  const contextValue: ExpFormContextProps = {
    form,
    getFieldValue: form.getFieldValue,
    setFieldsValue: form.setFieldsValue,
  };

  return <ExpFormContext.Provider value={contextValue}>{children}</ExpFormContext.Provider>;
};

export const useExpForm = () => {
  const context = useContext(ExpFormContext);
  if (!context) {
    throw new Error('useExpForm must be used within an ExpFormProvider');
  }
  return context;
};
