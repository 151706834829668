import { Button, Dropdown, MenuProps } from "antd";
import { EnFlag, SaFlag } from "../../common";

import { FC } from "react";
import i18next from "i18next";
import s from "./index.module.scss";
import { setLang } from "../../../store/Base/slice";
import { useDispatch } from "react-redux";

export interface MenuInfo {
    key: string;
    keyPath: string[];
    item: React.ReactInstance;
    domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}
const LangDropDown: FC = () => {
    const dispatch = useDispatch();

    function handleMenuClick(e: MenuInfo) {
        i18next.changeLanguage(e.key);
        dispatch(setLang(e.key));
        // window.location.reload();
    }

    const items: MenuProps["items"] = [
        {
            label: "العربية",
            key: "ar",
            icon: <SaFlag />,
            disabled: i18next.language === "ar",
        },
        {
            label: "English",
            key: "en",
            icon: <EnFlag />,
            disabled: i18next.language === "en",
        },
    ];

    return (
        <Dropdown
            overlayClassName={s.langMenu}
            placement="bottomLeft"
            menu={{ items, onClick: handleMenuClick }}
        >
            <Button className={s.langBtn}>
                {i18next.language === "ar" ? <SaFlag /> : <EnFlag />}
                {i18next.language === "ar" ? "العربية" : "English"}
            </Button>
        </Dropdown>
    );
};

export default LangDropDown;
