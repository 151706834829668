import { t } from 'i18next';

export const genderOptions = {
  Male: { labelAr: 'رجال', labelEn: 'Mens' },
  Female: { labelAr: 'نساء', labelEn: 'Women' },
  children: { labelAr: 'أطفال', labelEn: 'Children' },
  family: { labelAr: 'عائلة', labelEn: 'Family' },
};

export const hospitalityOptions = {
  none: {
    labelEn: `there is No Hospitality`,
    labelAr: `لا يوجد ضيافة`
  },
  water: {
    labelEn: `water ${String.fromCodePoint(
      128166
    )}`,
    labelAr: ` ماء ${String.fromCodePoint(128166)}`,
  },
  cookies: {
    labelEn: ` Cookies ${String.fromCodePoint(127850)}`,
    labelAr: ` كوكيز ${String.fromCodePoint(127850)}`,
  },
  waterBanana: {
    labelEn: `Water & Banana ${String.fromCodePoint(
      128166
    )} ${String.fromCodePoint(127820)}`,
    labelAr: `موز وماء ${String.fromCodePoint(128166)} ${String.fromCodePoint(
      127820
    )}`,
  },
  coffeeWaterBanana: {
    labelEn: `Water, Banana and Coffee ${String.fromCodePoint(
      128166
    )} ${String.fromCodePoint(127820)} ${String.fromCodePoint(9749)}`,
    labelAr: `ماء و موز وقهوة ${String.fromCodePoint(
      128166
    )} ${String.fromCodePoint(127820)} ${String.fromCodePoint(9749)}`,
  },
  specialHospitality: {
    labelEn: `Water,Banana,Coffee and Cookies ${String.fromCodePoint(
      128166
    )} ${String.fromCodePoint(127820)} ${String.fromCodePoint(
      9749
    )} ${String.fromCodePoint(127850)}`,
    labelAr: `ماء , موز , قهوة , كوكيز ${String.fromCodePoint(
      128166
    )} ${String.fromCodePoint(127820)} ${String.fromCodePoint(
      9749
    )} ${String.fromCodePoint(127850)}`,
  },
};

export const sessionPeriods = [
  { labelAr: 'ساعة', labelEn: '1 hour', value: '1h' },
  { labelAr: 'ساعتين', labelEn: '2 hour', value: '2h' },
  { labelAr: '٣ ساعات', labelEn: '3 hour', value: '3h' },
  { labelAr: '٤ ساعات', labelEn: '4 hour', value: '4h' },
  { labelAr: '٥ ساعات', labelEn: '5 hour', value: '5h' },
] as const;

export const bufferTimes = [
  {
    labelEn: 'Until the experience time',
    labelAr: 'حتى موعد التجربة',
    value: 'none',
  },
  { labelEn: 'Before one hour', labelAr: 'قبل ساعة', value: '1h' },
  { labelEn: 'Before two hours', labelAr: 'قبل ساعتين', value: '2h' },
  { labelEn: 'Before 12 hour', labelAr: 'قبل 12 ساعة', value: '12h' },
  { labelEn: 'Before one day', labelAr: 'قبل يوم', value: '1d' },
  { labelEn: 'Before two days ', labelAr: 'قبل يومين', value: '2d' },
] as const;
export const earlyBirdPriceDatePeriod = [
  { labelAr: 'قبل يوم واحد', labelEn: 'Before one day', value: '1d' },
  { labelAr: 'قبل يومين', labelEn: 'Before two days', value: '2d' },
  { labelAr: 'قبل ٣ أيام', labelEn: 'Before three days', value: '3d' },
  { labelAr: 'قبل ٤ أيام', labelEn: 'Before four day', value: '4d' },
  { labelAr: 'قبل ٥ أيام', labelEn: 'Before five day', value: '5d' },
] as const;

export const langPlaceHolderContent = {
  welcomingMessage: {
    ar: 'شكراً للانضمام لتجربتي. أرجو منك إحضار الأدوات المطلوبة والتجهيز للتجربة. سنبدأ اللقاء الساعة ١٠ والتجربة ستبدأ الساعة ١٠:١٠م بالضبط',
    en: 'Thank you for joining my experiment. Please bring the required tools and prepare for the experiment. We will start the meeting at 10, and the experiment will start at exactly 10:10 PM.',
  },
  locationDetails: {
    ar: 'مثال : رقم المبنى , رقم الطابق , رقم الشقة',
    en: 'example: building no. , floor no.',
  },
};

export const defaultCoordinates = {
  lat: 24.7136,
  lng: 46.6753,
};

export const formDataKeys = [
  'session',
  'location',
  'tools',
  'toolsEn',
  'croppedImages',
  'images',
  'language',
  'banner',
];

export const ratingTypeBasedOnValue = {
  satisfied: t('wonderful'),
  dissatisfied: t('bad'),
  neutral: t('good'),
};

export const bankName = [
  'البنك الأهلي التجاري',
  'البنك السعودي البريطاني',
  'البنك السعودي الفرنسي',
  'البنك الأول',
  'البنك السعودي للاستثمار',
  'البنك العربي الوطني',
  'بنك البلاد',
  'بنك الجزيرة',
  'بنك الرياض',
  'سامبا',
  'مصرف الراجحي',
  'مصرف الإنماء',
  'بنك الخليج الدولي',
];

export const accountOwnerRelationship = [
  'أنا صاحب الحساب',
  'أب',
  'أم',
  'أخ',
  'أخت',
  'ابن',
  'ابنة',
  'صديق',
];
export const approvalList = ['pending', 'rejected', 'approved'];
