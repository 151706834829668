import { Coordinates } from '@/models';

export interface ISortableLocation {
  address: string;
  city?: string;
  state?: string;
  country?: string;
  zipcode?: string;
  coordinates: Coordinates;
  details?: string;
  detailsEn?: string;
}

export const getAddressParts = (
  location: Coordinates,
  callback: (data: ISortableLocation | undefined) => void
) => {
  if (
    typeof window.google === 'object' &&
    typeof window.google.maps === 'object'
  ) {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ location }, (address, status) => {
      if (status === 'OK' && address !== null && address[0]) {
        const storableLocation: ISortableLocation = {} as ISortableLocation;
        storableLocation.address = address[0].formatted_address;
        address[0].address_components.forEach(addressItem => {
          if (
            addressItem.types.includes('sublocality') ||
            addressItem.types.includes('locality')
          ) {
            storableLocation.city = addressItem.long_name;
          } else if (
            addressItem.types.includes('administrative_area_level_1')
          ) {
            storableLocation.state = addressItem.short_name;
          } else if (addressItem.types.includes('country')) {
            storableLocation.country = addressItem.long_name;
          } else if (addressItem.types.includes('postal_code')) {
            storableLocation.zipcode = addressItem.long_name;
          }
        });
        storableLocation.coordinates = location;
        callback(storableLocation);
        return;
      }
      callback(undefined);
    });
  }

  callback(undefined);
};
