export const SaFlag = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 480'>
    <path fill='#199d00' d='M-40 0h720v480H-40z' />
    <path
      fill='#fff'
      d='M141.406 136.031c-.75 11.25-1.875 30.938 7.781 33 11.532 1.125 5.157-19.5 9.375-23.25.75-1.875 2.157-1.875 2.25.469v17.531c0 5.625 3.75 7.313 6.563 8.438 3-.188 5.063 0 6.188 2.812l1.5 30.281s6.937 2.063 7.312-16.968c.281-11.25-2.25-20.531-.75-22.688 0-2.156 2.813-2.25 4.688-1.219 3 2.063 4.312 4.688 9 3.75 7.125-2.062 11.437-5.53 11.53-10.968a44.063 44.063 0 0 0-3.28-15.563c.375-.937-1.313-3.469-.938-4.406 1.219 2.063 3.188 1.875 3.563 0-1.22-3.938-3.094-7.781-6.094-9.375-2.531-2.25-6.281-1.875-7.5 2.813-.75 5.343 1.875 11.625 5.719 16.875.843 1.968 1.968 5.343 1.5 8.343-2.063 1.219-4.125.656-5.907-1.125 0 0-5.625-4.219-5.625-5.25 1.5-9.562.281-10.687-.562-13.406-.563-3.656-2.344-4.875-3.75-7.313-1.406-1.5-3.281-1.5-4.219 0-2.531 4.313-1.313 13.594.469 17.813 1.312 3.844 3.281 6.281 2.344 6.281-.75 2.156-2.344 1.594-3.563-.937a62.437 62.437 0 0 1-1.969-16.313c-.469-4.312-1.031-13.5-3.937-15.937-1.688-2.25-4.219-1.125-5.156.937a77.25 77.25 0 0 0 .28 12.563c1.876 6.937 2.532 13.125 3.47 20.156.28 9.469-5.438 4.125-5.157-.656a42.188 42.188 0 0 0-.281-18.188c-.938-2.437-1.969-3-4.313-2.625-1.78 0-6.375 4.969-7.687 13.406 0 0-1.125 4.313-1.594 8.157-.656 4.312-3.469 7.5-5.531-.563-1.688-5.906-2.813-20.25-5.625-16.875z'
    />
    <path
      fill='#fff'
      d='m172.813 182.063-30 14.437c.28-6.844 14.156-19.125 23.718-19.219 6.094.094 4.594 2.344 6.188 4.781z'
    />
    <path
      fill='#fff'
      d='M167.469 191.438c-15.75 40.78 37.031 46.5 42.937 1.687.563-1.875 2.813-3.656 3.188-.656-1.219 40.594-40.875 43.312-47.625 30.562a39.281 39.281 0 0 1-2.344-13.687c-.938-7.969-5.156-4.875-5.813 3-.656 4.406-.468 5.625-.468 9.844 2.062 32.062 53.156 18.28 61.5-8.157 4.406-14.625-.75-25.406 1.594-25.406 5.062 5.438 12.187.75 13.78-1.125.657-.938 2.345-1.594 3.47-.375 3.937 2.813 10.875 1.5 12.375-3.469.843-4.969 1.5-10.031 1.687-15.187-3.281.937-5.625 1.594-5.906 3l-.656 4.312c-.282 1.406-3 1.406-3.188-.375-1.219-5.625-6.281-6.281-9.375 2.344-1.969 1.688-5.719 2.063-6.094-.469.469-5.812-1.875-6.562-6.562-3.844l-4.5-33.937c1.875 0 3.75 1.406 5.531-.844-1.875-6.094-6.094-18.469-8.438-19.406-1.03-1.313-1.968-.469-3.468-.094-2.438.75-4.688 2.813-3.938 6.938 2.813 17.625 4.688 31.031 7.594 48.75.469 1.969-1.219 4.687-3.469 4.406-3.75-2.531-4.687-7.687-11.25-7.5-4.687 0-9.937 5.156-10.594 10.031-.843 3.938-1.125 8.156 0 11.531 3.282 3.938 7.22 3.563 10.688 2.72 2.813-1.22 5.156-4.032 6.188-3.376.656.844.093 10.219-13.407 17.344-8.156 3.75-14.719 4.5-18.187-2.156-2.156-4.219.187-20.063-5.25-16.406z'
    />
    <path
      fill='#fff'
      d='M234.688 150c3.093-1.125 18.093-18.375 18.093-18.375l-2.25-1.875c-.844-.656-.75-1.406 0-2.063 3.75-2.25 2.531-6.937.656-9.187a9.094 9.094 0 0 0-8.156.094c-2.625 2.531-3.187 6.562-1.125 9 1.969.937 3.938 3 2.625 4.125-6.187 6.562-22.969 17.906-21 18.281.375.563 10.781.563 11.063 0zm-90.938 60.938c-5.625 9-6.094 22.406-3 26.437 1.688 1.875 4.406 2.719 6.375 2.063 3.563-1.5 5.156-8.72 4.313-11.25-1.22-1.875-2.157-2.157-3.375-.657-2.438 5.063-3.47 1.594-3.75-1.219a65.625 65.625 0 0 1 .75-14.25c.656-3.937 0-2.812-1.313-1.125zm241.031-14.344c-5.437-11.813-13.031-23.438-15.375-27.844a522.75 522.75 0 0 0-23.25-33.75c-5.812-6.938 9.563 2.906-1.875-10.969L335.937 117c-1.875-1.313-6.375-3.75-7.125.188-.375 3.562-.187 5.437.375 8.343.47 1.875 3.282 5.156 4.688 7.031a529.688 529.688 0 0 1 50.438 81.094c2.437-1.219 1.875-15.094.468-17.062z'
    />
    <path
      fill='#fff'
      d='M360.875 235.781c-1.125 1.219 2.625 6.375 7.5 6.375 8.063-.937 15.188-5.437 21.75-17.437a30.938 30.938 0 0 0 4.969-13.313 297.188 297.188 0 0 0-5.438-67.875c-.281-1.875 0-4.125.188-4.687.562-.656 2.344 0 3.281-1.594 1.406-1.406-3.75-13.125-6.563-17.531-.937-2.063-1.406-3.375-3.093.187a25.313 25.313 0 0 0-2.813 12.75c3.844 26.719 5.063 50.063 7.5 76.781.281 2.625-.094 6.375-1.875 7.875a75.188 75.188 0 0 1-25.406 18.47zm109.219-.094c-5.813 3.375-5.813 7.22-1.125 7.313 8.062-.938 17.625-1.594 24.187-11.531a38.438 38.438 0 0 0 3.938-15 284.063 284.063 0 0 0-4.406-66.938c-.188-1.875-1.032-6.281-.75-6.844.562-1.312 3.187.094 4.125-1.406 1.312-1.406-6.844-11.906-9.75-16.406-.938-2.063-1.313-3.375-3.094.188a20.906 20.906 0 0 0-1.688 12.75c4.313 29.062 7.5 50.812 8.156 76.5-.375 2.437-.468 3.75-1.593 6.843-2.531 3.188-5.344 7.313-7.969 9.281-2.625 1.875-8.25 3.75-10.031 5.25z'
    />
    <path
      fill='#fff'
      d='M474.406 209.719c0-6.844.094-12.656-.094-17.813a31.875 31.875 0 0 0-2.812-12.656c-1.688-3.844-.656-6.938-1.5-11.063-.75-4.125-.563-10.312-1.688-15.093-.375-1.875-1.312-7.969-1.03-8.625.468-1.313 2.25 0 3.187-1.5 1.312-1.406-4.688-16.875-7.688-21.281-1.031-1.97-3.094-1.313-5.437 1.874-2.344 2.157-1.5 6.938-.563 11.532 5.719 30.281 10.125 57.75 9.188 86.531-.375 2.438 8.437-7.313 8.437-11.906zm-42.844-37.5c-3.656-.188-11.25-7.219-13.5-11.25a7.5 7.5 0 0 1 .375-6.094c1.407-.938 3.47-1.875 5.063-.938 0 0 1.594 2.25 1.313 2.532 1.875.937 2.812.469 3-.375.093-1.406-.563-2.25-.563-3.75.844-4.313 5.625-4.969 7.5-2.25 1.313 1.687 1.875 5.156 1.969 7.5 0 1.219-1.875-.188-3.094 0-1.031.375-1.313 1.687-1.406 2.812-.188 3.094-.563 8.063-.656 11.719zm-67.312 45c.938-9.188-.375-25.594-.469-31.031a447.188 447.188 0 0 0-3.469-41.813c-1.125-7.875 3.188.844 2.625-3.75-1.406-7.781-5.718-13.125-10.875-20.156-1.593-2.344-1.593-2.813-4.125.562-2.812 6.281-.375 10.688.375 15.656 3.657 16.125 5.813 30.938 6.844 45.657a368.813 368.813 0 0 1 .375 45.937c2.813.094 7.125-4.406 8.719-11.062z'
    />
    <path
      fill='#fff'
      d='M486.875 202.5c-6.469-10.875-16.125-22.5-18.75-26.906a616.875 616.875 0 0 0-27.375-35.438c-7.969-8.437 3.75-1.406-1.5-7.969-4.406-4.78-5.625-6.375-9.469-9.28-1.875-1.22-3-3.563-3.75.468a77.813 77.813 0 0 0-.187 10.5c0 1.594 1.687 4.688 3.187 6.563 19.406 23.906 40.688 48.28 57.75 78.937 2.438-1.219 1.594-15 0-16.875z'
    />
    <path
      fill='#1ba400'
      d='M194.938 182.531c-.47.844-1.5 1.875-1.125 2.906.656.938 1.312 1.22 2.437 1.22 1.031 0 2.531.28 2.813-.282.562-.656.937-1.875.562-3.094-1.125-2.812-4.125-1.687-4.688-.75z'
    />
    <path
      fill='#fff'
      d='M412.063 339.844c8.625.375 14.25.469 21.843 1.312l9-.937c9.938-.938 10.313 14.156 10.313 14.156 0 8.906-3.469 9.375-7.875 10.313-2.531.375-3.75-1.5-5.156-3.375a13.125 13.125 0 0 1-6.563.375c-3.656-.188-7.219-.188-10.781-.47-3.75-.28-5.813.47-9.656.094-.75 1.22-1.875 2.907-4.125 2.438-1.875-.281-4.22-5.625-3.563-9.844 1.406-3 .938-1.969.938-3.281-35.25-.938-70.782-2.531-105.282-2.063-27 .094-53.625 1.22-80.344 2.344-14.25-.187-25.125-2.437-32.625-13.406.75 0 36.375 1.969 46.782 1.313 19.219-.188 36.844-1.782 56.437-2.344 38.625.656 76.969.656 115.594 3.375-3.75-2.531-3.75-8.438 1.875-9.938.469-.375.75 2.906 1.594 2.813 4.594-.281 2.531 5.906 1.594 7.125zm-155.25-213c-5.813 16.687 3.375 35.062 9.75 33.281 4.687 1.875 7.5-6.938 9.375-16.5 1.406-2.719 2.343-3 3-1.594-.188 12.75.937 15.656 4.218 19.5 7.313 5.625 13.406.75 13.875.281l5.625-5.718c1.313-1.406 3-1.406 4.781-.281 1.782 1.593 1.5 4.312 5.25 6.187 3.188 1.313 9.844.375 11.438-2.344 2.063-3.656 2.625-4.875 3.563-6.187 1.5-1.969 4.03-1.125 4.03-.469-.28 1.125-1.78 2.156-.75 4.219 1.876 1.312 2.25.469 3.282.187 3.75-1.875 6.563-9.937 6.563-9.937.093-3-1.594-2.813-2.72-2.063l-2.906 1.969c-1.875.281-5.343 1.5-7.125-1.219-1.78-3.187-1.78-7.781-3.093-11.062 0-.188-2.438-5.156-.188-5.438 1.125.188 3.469.844 3.844-1.125 1.125-1.969-2.438-7.5-4.969-10.312-2.156-2.344-5.156-2.625-8.062-.188-2.063 1.875-1.781 3.938-2.156 5.906a9.188 9.188 0 0 0 1.875 8.157c2.062 3.937 5.718 9.094 4.5 16.406 0 0-2.157 3.375-5.907 2.906-1.594-.281-4.125-.937-5.437-11.062-1.031-7.5.187-18.188-3-23.156-1.219-3.094-2.063-6-4.875-.844-.75 2.062-4.031 5.156-1.688 11.437a33.75 33.75 0 0 1 1.875 17.813c-1.406 2.062-1.687 2.719-3.469 4.687-2.437 2.813-5.156 2.063-7.218 1.031-1.875-1.218-3.375-1.875-4.313-6.093.188-6.563.563-17.344-.656-19.594-1.781-3.563-4.688-2.25-5.906-1.125a44.719 44.719 0 0 0-10.781 22.031c-1.688 5.438-3.47 3.844-4.688 1.688-3-2.813-3.281-25.031-6.938-21.375z'
    />
    <path
      fill='#fff'
      d='M274.438 163.219c2.718-1.875 1.5-3.188 5.437.75a67.5 67.5 0 0 1 8.625 29.344c-.188 2.437 1.5 3.937 2.344 3.375.375-5.625 14.156-13.5 26.812-14.625 1.875-.47.938-4.125 1.219-6-.75-7.032 3.938-13.407 10.5-13.875 9 1.312 12 6.093 12.188 13.312-1.032 14.063-15.657 16.406-23.813 17.531-1.219.469-1.781 1.031 0 1.688l34.313.187 1.78.938c.188.937-.562.187-1.874 2.437a27.656 27.656 0 0 0-3.469 10.781c-10.219 3.375-20.813 4.688-31.5 6.094-3.75 1.875-5.625 4.406-4.875 7.219 1.313 3.094 9.563 6.281 9.563 6.375 1.593.938 3.375 3.281-.47 8.063-16.687-.75-29.718-7.875-34.218-17.907-1.313-1.031-2.813 0-3.75 1.313-6.563 8.437-12.938 15.937-24.094 20.062-6.562 1.688-13.406-1.031-16.594-5.344-2.156-2.53-2.062-5.25-2.812-5.812-3.656 1.594-34.594 14.719-30.656 8.531 7.5-7.969 20.625-13.969 32.062-21.844.844-2.718 2.344-11.718 6.844-14.625.281 0-.656 5.25-.563 7.5 0 1.875-.187 2.532.188 2.063.844-.469 14.719-11.438 15.938-14.813 1.312-1.875.28-6.75.28-6.937-2.624-6.75-6.28-7.313-7.593-10.688-1.219-4.406-.656-9.468 1.875-10.968 2.25-1.969 4.875-1.781 7.406.469 2.813 2.53 5.25 7.5 6 11.156-.469 1.406-3.75-.938-4.687-.281a15 15 0 0 1 3.469 7.312c1.875 7.687 1.312 10.688-.563 15.656-6.188 13.031-14.063 16.875-21 21.75-.188 0-.281 3.281 2.25 5.063.938.937 4.594 1.406 8.813 0a51.094 51.094 0 0 0 20.906-21.844 47.813 47.813 0 0 0-2.25-20.813c-2.719-6.28-5.906-15.187-5.906-15.375-.094-3.937.187-5.25 1.875-7.218zm-89.813-36.188c3.938 1.875 11.438 1.031 11.063-5.344l-.188-2.906c-.75-1.875-3-1.406-3.469.469-.187.656.281 1.688-.281 1.969-.375.375-1.594.187-1.594-1.594 0-.563-.375-1.125-.656-1.5-.188-.188-.375-.188-.844-.188-.562 0-.562.094-.844.563-.093.469-.28.938-.28 1.5 0 .656-.376.844-.75.938-.563 0-.47 0-.938-.188-.188-.281-.469-.375-.469-.938l-.281-1.5c-.188-.28-.563-.468-.938-.562-2.156 0-2.344 2.531-2.156 3.469-.188.187-.281 4.594 2.625 5.812z'
    />
    <path
      fill='#fff'
      d='M300.406 175.969c3.938 1.875 13.406.844 11.063-5.25l-.188-3c-.844-1.875-3-1.406-3.469.562-.187.563.282 1.594-.375 1.875-.28.375-1.593.188-1.5-1.5 0-.562-.375-1.219-.656-1.594-.281-.093-.375-.187-.937-.187-.469 0-.469.188-.75.656a3.886 3.886 0 0 0-.281 1.5c-.094.563-.375.75-.844.938-.469 0-.375 0-.75-.281-.281-.282-.563-.375-.563-.844l-.281-1.5c-.188-.281-.563-.469-.938-.563-2.156 0-2.343 2.438-2.25 3.375-.093.188-.187 4.688 2.813 5.813zm67.5-20.25c3.938 1.875 11.344 1.031 11.063-5.25l-.188-3c-.844-1.875-3-1.406-3.469.469-.187.656.282 1.687-.375 2.062-.28.281-1.593.094-1.5-1.688 0-.562-.375-1.125-.656-1.5-.281-.187-.375-.187-.937-.187-.469 0-.469.188-.75.656l-.281 1.406c-.094.657-.375.938-.844.938s-.375 0-.75-.188c-.281-.28-.563-.375-.563-.843s-.094-1.219-.281-1.594c-.188-.281-.563-.375-.938-.469-2.156 0-2.343 2.438-2.25 3.375-.093.188-.187 4.594 2.813 5.813zm34.969 50.906c-6.844 7.781-3.844 20.625-2.25 23.438 2.25 4.5 4.031 7.406 8.438 9.656 4.03 2.906 7.218 1.125 8.906-.938 4.031-4.219 4.125-15 6-17.062 1.312-3.938 4.687-3.281 6.281-1.5a15.469 15.469 0 0 0 5.813 4.969c3.75 3.28 8.25 3.937 12.75.937 3-1.781 4.968-3.938 6.75-8.344 1.875-5.25.937-29.625.468-44.062l-3.937-20.156c0-.188-.469-9.563-.938-11.72 0-.937-.281-1.218.656-1.124 1.032.937 1.125.937 1.875 1.219.938.187 1.875-1.594 1.22-3.094l-9.376-17.438c-.75-.75-1.781-1.5-3 .188a6.844 6.844 0 0 0-2.25 5.156c.281 4.125.938 8.344 1.219 12.469l3.75 21.187c1.219 15 1.5 27.375 2.719 42.469-.188 6.375-2.156 11.906-4.031 12.75 0 0-2.813 1.594-4.688-.188-1.406-.562-6.938-9.28-6.938-9.28-2.812-2.532-4.687-1.876-6.656 0-5.625 5.437-8.062 15.374-11.906 22.312-.938 1.594-3.75 2.812-6.75-.094-7.687-10.594-3.188-25.594-4.125-21.75zm-33.188-87.844c3.563 1.406 6 8.625 5.25 12.188-.75 4.219-2.625 8.906-3.937 8.344-1.5-.563.938-4.313-.469-8.25-.75-2.625-5.625-7.313-5.062-8.625-.938-2.907 2.062-4.22 4.219-3.75z'
    />
    <path
      fill='#fff'
      d='M414.313 210.938c.656-8.625-.563-13.875-.75-18.938s-5.72-43.688-6.844-47.438c-1.406-7.312 5.344-.937 4.594-5.25-2.344-5.25-8.063-13.03-9.844-17.625-1.125-1.874-.656-3.75-3.094-.468a39.656 39.656 0 0 0-2.156 18c5.812 30.281 11.719 55.406 10.781 84.187 2.813 0 5.906-6.281 7.313-12.469zm60.375-79.97c3.28 1.595 5.156 10.595 4.78 13.126-.656 4.687-2.343 9.75-3.562 9.094-1.406-.563.281-6.938-.375-8.907-.75-2.812-5.156-7.875-4.687-9.375-.938-3.187 1.875-4.5 3.844-3.937zm-239.72 63.657c3.095 1.219 4.97 7.781 4.688 9.656-.75 3.469-2.344 7.219-3.562 6.656-1.219-.375.281-5.062-.281-6.562-.282-3.469-4.594-5.344-4.5-6.844-.75-2.812 1.875-3.281 3.75-2.906z'
    />
    <path
      fill='#1b9d00'
      d='M309.594 204.563c3.937.187 5.906 3.375 2.25 4.687-3.75 1.219-7.219 2.25-7.313 7.5 1.406 7.5-1.875 4.875-3.75 3.938-2.25-1.688-8.625-5.625-9.562-14.063-.094-1.969 1.5-3.75 4.031-3.75 3.75 1.031 9.375 1.125 14.344 1.688z'
    />
    <path
      fill='#fff'
      d='M152.563 116.625c4.5 1.313 4.78 8.063 4.5 10.031-.657 3.563-2.25 7.406-3.375 6.938-1.313-.469 0-5.344-.657-6.844-.656-2.063-4.5-6-4.125-7.125-.844-2.344 1.875-3.469 3.656-3zm89.906 31.5c-3.563 1.875-4.875 7.5-2.719 10.875 2.063 2.813 5.25 1.781 5.625 1.781 3.469.375 5.531-6.469 5.531-6.469s.094-1.875-3.937 1.782c-1.781.281-1.875-.375-2.344-1.313a8.438 8.438 0 0 1 .469-5.344c.656-1.687-.656-2.437-2.625-1.312zM268.719 114c-1.875 1.219-5.344 4.875-5.438 9-.094 2.344-.562 2.344.938 3.75 1.219 1.688 2.25 1.594 4.5.375a4.781 4.781 0 0 0 2.156-3.188c.563-2.624-2.813 1.313-3.188-1.687-.75-2.813 1.407-3.938 3.47-6.563 0-1.874 0-3.093-2.532-1.687zm21 3.75a55.781 55.781 0 0 0-1.5 10.406c-.563 2.625 2.812 3.75 4.219.375 2.25-6.094 2.25-8.719 2.437-11.25-.656-4.031-3.375-3.937-5.156.469zm133.125 67.781c.375-.469 18.75-13.5 18.75-13.5 1.875-.656 1.406 6.75.562 6.656a72.938 72.938 0 0 1-19.406 13.407c-.938.656-1.781-4.969 0-6.563zm16.594-.187c3.28 1.594 4.593 11.062 4.218 13.594 0 5.062-3.094 9-4.406 8.437-1.313-.656.188-6.281-.469-8.25-.75-2.813-3.469-7.969-3-9.469-.937-3.187 1.688-4.875 3.75-4.312zm-108.75 40.687a24.375 24.375 0 0 1 5.25-4.594c1.875-.937 3.562.75 3.468.657.281 1.875-1.125 3.469-.656 5.906.375.938.656 2.063 2.438 1.688 2.906-2.344 5.625-2.532 8.437-2.625 2.344.093 2.438 3.937.938 3.937-5.344 1.125-7.688 2.625-11.532 4.031-1.875 1.125-3.375-.281-3.375-.375s-1.031-1.031-.375-3.469c.188-1.875-.562-3-2.25-2.812-1.125.75-2.25 1.125-2.812-.281-.281-.938-.375-1.5.469-2.063zm128.062 5.063c.75.937 1.313 1.875-.094 3.562l-3.469 3c-.562.938-.937 2.625.938 3.094 3.375.938 11.25-4.219 11.25-4.313 1.313-.937.938-2.812.75-2.812-.75-.844-2.438-.281-3.563-.469-.562 0-2.343-.187-1.5-1.875a10.688 10.688 0 0 0 1.5-2.719c.47-1.125 0-1.875-1.875-2.53-1.968-.376-2.812-.188-4.968 0-1.125.187-1.5.75-1.781 2.155.093 2.157 1.406 2.063 2.812 2.813z'
    />
    <path
      fill='#259f00'
      d='M331.344 177.844c-.469.937-2.156.937-3.75 0s-2.531-2.438-1.969-3.281 2.156-.844 3.75 0 2.438 2.437 1.969 3.28zm-83.438-50.25c-.937.281-2.25-.563-2.812-1.875s-.281-2.438.656-2.719 2.156.656 2.813 1.875.28 2.531-.75 2.813z'
    />
    <path
      fill='#209000'
      d='M413 351.563c8.813.375 17.063 0 25.781.468 1.594 1.406.469 4.688-.562 4.5l-7.313-.281c-.094-2.813-7.219-2.344-7.031.094-3.844.469-7.313-.094-11.25-.281-1.125-1.407-.938-3.938.375-4.5z'
    />
  </svg>
);
export const EnFlag = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 480'>
    <path fill='#012169' d='M0 0h640v480H0z' />
    <path
      fill='#FFF'
      d='m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z'
    />
    <path
      fill='#C8102E'
      d='m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z'
    />
    <path fill='#FFF' d='M241 0v480h160V0H241zM0 160v160h640V160H0z' />
    <path fill='#C8102E' d='M0 193v96h640v-96H0zM273 0v480h96V0h-96z' />
  </svg>
);

export const CalendarSvg = () => (
  <svg
    viewBox='64 64 896 896'
    data-icon='calendar'
    width='1em'
    height='1em'
    fill='currentColor'
    aria-hidden='true'
  >
    <path d='M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z' />
  </svg>
);

export const ClockSvg = () => (
  <svg
    viewBox='64 64 896 896'
    data-icon='clock-circle'
    width='1em'
    height='1em'
    fill='currentColor'
    aria-hidden='true'
  >
    <path d='M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z' />
    <path d='M686.7 638.6 544.1 535.5V288c0-4.4-3.6-8-8-8H488c-4.4 0-8 3.6-8 8v275.4c0 2.6 1.2 5 3.3 6.5l165.4 120.6c3.6 2.6 8.6 1.8 11.2-1.7l28.6-39c2.6-3.7 1.8-8.7-1.8-11.2z' />
  </svg>
);

export const BalloonSvg = ({
  width = 20,
  height = 20,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 61.27 122.88'
    width={width}
    height={height}
    xmlSpace='preserve'
  >
    <path
      fill='#f05e60'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30.68 0c16.92 0 30.6 16.34 30.6 36.51 0 18.62-11.67 39.94-26.77 42.22l2.94 5.1c1.85 3.21.81 4.09-2.36 4.09h-1.93c6.04 15.27 6.56 18.68.65 33.95 0 .54-.46 1-1 1h-2.97c-.54 0-1-.46-1-1 5.99-15.27 5.32-18.68-.65-33.95h-3.32c-2.4.04-2.24-2.28-1.24-3.9l3.28-5.29C11.74 76.52 0 55.17 0 36.51 0 16.34 13.71 0 30.6 0h.08zm5.51 12.16a1.749 1.749 0 1 1 1.66-3.08c2.93 1.58 5.86 3.92 8.42 6.57 2.64 2.73 4.89 5.8 6.32 8.7a1.747 1.747 0 0 1-3.13 1.55c-1.28-2.58-3.31-5.34-5.71-7.83-2.31-2.4-4.95-4.51-7.56-5.91z'
    />
  </svg>
);

export const CopyIcon = () => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
  >
    <path d='M18.984 21v-14.016h-10.969v14.016h10.969zM18.984 5.016q0.797 0 1.406 0.586t0.609 1.383v14.016q0 0.797-0.609 1.406t-1.406 0.609h-10.969q-0.797 0-1.406-0.609t-0.609-1.406v-14.016q0-0.797 0.609-1.383t1.406-0.586h10.969zM15.984 0.984v2.016h-12v14.016h-1.969v-14.016q0-0.797 0.586-1.406t1.383-0.609h12z' />
  </svg>
);
