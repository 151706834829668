import { FC } from "react";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";

const FilterDropdownIcon: FC<{
  value?: string | number | boolean;
  type: "search" | "filter";
}> = ({ value, type }) => {
  if (type === "search") {
    return <SearchOutlined style={{ color: value ? "#108ee9" : "#aaa" }} />;
  }
  return <FilterOutlined style={{ color: value ? "#108ee9" : "#aaa" }} />;
};
export default FilterDropdownIcon;
