import { FC, useEffect, useRef } from 'react';
import { Input, Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import moment from 'moment';
import s from './index.module.scss';
import { FormatDateEnum } from '../../../models';

const { Option } = Select;

interface FilterDropdownProps {
  handleSearch: (prop: string) => void;
  handleReset: (name: string) => void;
  handleChange: (name: string, val: any) => void;
  value: any;
  name: string;
  placeHolder?: string;
  visible: boolean;
  options?: any[];
  optionValueName?: string;
  optionName?: string;
  inputType?: 'single' | 'multi' | 'date';
}

const FilterDropdown: FC<FilterDropdownProps> = ({
  handleSearch,
  handleReset,
  handleChange,
  value,
  name,
  placeHolder = '',
  visible,
  options = [],
  optionValueName,
  optionName,
  inputType = 'single',
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<any>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [visible]);

  if (inputType === 'multi' && (!options || !optionName || !optionValueName)) {
    throw Error(
      'The input type is multi and the  options or option Name or option value name not passed as props'
    );
  }

  return (
    <div className={s.filterDrop}>
      {inputType === 'single' && (
        <Input
          ref={inputRef}
          placeholder={placeHolder}
          value={value}
          onChange={e => handleChange(name, e.target.value)}
          onPressEnter={() => handleSearch(name)}
        />
      )}

      {inputType === 'multi' && (
        <Select
          value={value}
          placeholder={placeHolder}
          onChange={value => handleChange(name, value)}
        >
          {options?.map(option => (
            <Option
              key={option[optionValueName as string]}
              value={option[optionValueName as string]}
            >
              {option[optionName as string]}
            </Option>
          ))}
        </Select>
      )}

      {inputType === 'date' && (
        <>
          <DatePicker
            value={value ? new Date(parseInt(value)) : value}
            onChange={value => {
              if (value instanceof DateObject) {
                handleChange(
                  name,
                  moment(value.toDate())
                    .startOf('day')
                    .add(2, 'hours')
                    .valueOf()
                );
              }
            }}
            placeholder={placeHolder}
            format={FormatDateEnum.YMD}
            editable={false}
            plugins={[]}
          />
        </>
      )}

      <Button className='search-btn' onClick={() => handleSearch(name)}>
        {t('search')}
      </Button>
      <Button type='primary' onClick={() => handleReset(name)}>
        {t('reset')}
      </Button>
    </div>
  );
};

export default FilterDropdown;
