import {
  HomeOutlined,
  FileAddOutlined,
  UserOutlined,
  DollarCircleOutlined,
  NotificationOutlined,
  SmileOutlined,
  UnorderedListOutlined,
  GiftOutlined,
  SettingOutlined,
  EuroCircleOutlined,
  DollarCircleTwoTone,
  VideoCameraAddOutlined,
  PicCenterOutlined,
  AimOutlined,
  SlidersOutlined,
} from '@ant-design/icons';
import { MenuItem, ROUTES } from '../models';

const menuList: MenuItem[] = [
  // {
  //   title: 'dashboard',
  //   path: ROUTES.DASHBOARD,
  //   icon: <HomeOutlined />,
  // },
  {
    title: 'experiences',
    path: ROUTES.EXPERIENCES,
    icon: <FileAddOutlined />,
    children: [
      {
        title: 'experiences',
        path: ROUTES.EXPERIENCES,
      },
      {
        title: 'sessions',
        path: ROUTES.SESSIONS,
      },
    ],
  },
  {
    title: 'talents',
    path: ROUTES.TALENTS,
    icon: <UnorderedListOutlined />,
  },
  
  // {
  //   title: 'users',
  //   path: ROUTES.HOME,
  //   icon: <UserOutlined />,
  //   children: [
  //     {
  //       title: 'learners',
  //       path: ROUTES.LEARNERS,
  //     },
  //     {
  //       title: 'coaches',
  //       path: ROUTES.COACHES,
  //     },
  //   ],
  // },
  // {
  //   title: 'discountCodes',
  //   path: ROUTES.DISCOUNT_CODES,
  //   icon: <DollarCircleOutlined />,
  // },
  // {
  //   title: 'notifications',
  //   path: ROUTES.NOTIFICATIONS,
  //   icon: <NotificationOutlined />,
  // },
  // {
  //   title: 'cheering',
  //   path: ROUTES.CHEERING,
  //   icon: <SmileOutlined />,
  // },
  // {
  //   title: 'bookingList',
  //   path: ROUTES.BOOKING_LIST,
  //   icon: <UnorderedListOutlined />,
  // },
  // {
  //   title: 'giftCards',
  //   path: ROUTES.HOME,
  //   icon: <GiftOutlined />,
  //   children: [
  //     {
  //       title: 'giftCards',
  //       path: ROUTES.GIFT_CARDS,
  //     },
  //     {
  //       title: 'giftBooking',
  //       path: ROUTES.GIFT_BOOKING,
  //     },
  //     {
  //       title: 'b2bGiftCards',
  //       path: ROUTES.B2B_GIFT_CARDS,
  //     },
  //   ],
  // },
  // {
  //   title: 'dashboards',
  //   path: ROUTES.HOME,
  //   icon: <SettingOutlined />,
  //   children: [
  //     {
  //       title: 'wantedExperiences',
  //       path: ROUTES.WANTED_EXPERIENCES,
  //     },
  //     {
  //       title: 'reviews',
  //       path: ROUTES.REVIEWS,
  //     },
  //   ],
  // },
  // {
  //   title: 'balance',
  //   path: ROUTES.BALANCE,
  //   icon: <EuroCircleOutlined />,
  // },
  // {
  //   title: 'PaymentError',
  //   path: ROUTES.PAYMENT_ERROR,
  //   icon: <DollarCircleTwoTone />,
  // },
  // {
  //   title: 'banners',
  //   path: ROUTES.BALANCE,
  //   icon: <VideoCameraAddOutlined />,
  //   children: [
  //     {
  //       title: 'coachBanners',
  //       path: ROUTES.COACH_BANNERS,
  //     },
  //     {
  //       title: 'learnerBanners',
  //       path: ROUTES.LEARNER_BANNERS,
  //     },
  //   ],
  // },
  // {
  //   title: 'collections',
  //   path: ROUTES.COLLECTION,
  //   icon: <PicCenterOutlined />,
  // },
  // {
  //   title: 'accountManager',
  //   path: ROUTES.ACCOUNT_MANAGER,
  //   icon: <SlidersOutlined />,
  // },
  // {
  //   title: 'gigs',
  //   path: ROUTES.GIGS,
  //   icon: <AimOutlined />,
  //   children: [
  //     {
  //       title: 'gigs',
  //       path: ROUTES.GIGS,
  //     },
  //     {
  //       title: 'gigsApplications',
  //       path: ROUTES.GIGS_APPLICATIONS,
  //     },
  //     {
  //       title: 'gigsNotifications',
  //       path: ROUTES.GIGS_NOTIFICATIONS,
  //     },
  //   ],
  // },
];
export default menuList;
