import { ROLES } from '../models';
import { ROUTES } from './routes';

export const routesRoles: { [key in ROLES]?: ROUTES[] } = {
  [ROLES.admin]: [
    ROUTES.EXPERIENCES,
    ROUTES.ADD_EXPERIENCE,
    ROUTES.EDIT_EXPERIENCES,

    ROUTES.SESSIONS,
    ROUTES.EDIT_SESSIONS,
    ROUTES.SESSION_LEARNERS,
    ROUTES.ADD_SESSION,

    ROUTES.TALENTS,

    // ROUTES.SESSIONS_LIST,
    // ROUTES.SESSION_ADD_LEARNER,

    // ROUTES.COACHES,
    // ROUTES.CREATE_COACH,
    // ROUTES.EDIT_COACH,
    // ROUTES.CREATE_COACH,
    // ROUTES.COACH_BALANCE,

    // ROUTES.LEARNERS,
    // ROUTES.CREATE_LEARNER,
    // ROUTES.CREATE_LEARNER,
    // ROUTES.EDIT_LEARNER,

    // ROUTES.REVIEWS_LIST,

    // ROUTES.CHEERING,

    // ROUTES.BOOKING_LIST,
    // ROUTES.GIFT_BOOKING,

    // ROUTES.GIFT_CARDS,
    // ROUTES.GIFT_CARDS_ADD,
    // ROUTES.GIFT_CARDS_EDIT,
    // ROUTES.B2B_GIFT_CARDS,
    // ROUTES.B2B_GIFT_CARDS_ADD,
    // ROUTES.B2B_GIFT_CARDS_EDIT,

    // ROUTES.WANTED_EXPERIENCES,
    // ROUTES.REVIEWS,
    // ROUTES.BALANCE,

    // ROUTES.COACH_BANNERS,
    // ROUTES.COACH_BANNERS_ADD,
    // ROUTES.COACH_BANNERS_EDIT,
    // ROUTES.COACH_BANNERS_SORT,

    // ROUTES.LEARNER_BANNERS,
    // ROUTES.LEARNER_BANNERS_ADD,
    // ROUTES.LEARNER_BANNERS_EDIT,
    // ROUTES.LEARNER_BANNERS_SORT,

    // ROUTES.COLLECTION,
    // ROUTES.COLLECTION_EDIT,
    // ROUTES.COLLECTION_ADD,
    // ROUTES.COLLECTION_SORT,

    // ROUTES.APPROVALS,
    // ROUTES.APPROVALS_Edit,

    // ROUTES.GIGS,
    // ROUTES.GIGS_CREATE,
    // ROUTES.GIGS_EDIT,
    // ROUTES.GIGS_APPLICATIONS,
    // ROUTES.GIGS_NOTIFICATIONS,

    // ROUTES.ACCOUNT_MANAGER,
    // ROUTES.ACCOUNT_MANAGER_EDIT,
  ],
  [ROLES.super_admin]: [
    ROUTES.EXPERIENCES,
    ROUTES.EDIT_EXPERIENCES,
    ROUTES.ADD_EXPERIENCE,

    ROUTES.SESSIONS,
    ROUTES.EDIT_SESSIONS,
    ROUTES.SESSION_LEARNERS,
    ROUTES.ADD_SESSION,

    ROUTES.TALENTS,

    // ROUTES.Edit_Sessions,
    // ROUTES.Add_Session,
    // ROUTES.SESSION_ADD_LEARNER,

    // ROUTES.NOTIFICATIONS,
    // ROUTES.NOTIFICATIONS_ADD,

    // ROUTES.DISCOUNT_CODES,
    // ROUTES.DISCOUNT_CODES_EDIT,
    // ROUTES.DISCOUNT_CODES_ADD,

    // ROUTES.COACHES,
    // ROUTES.CREATE_COACH,
    // ROUTES.EDIT_COACH,
    // ROUTES.COACH_BALANCE,

    // ROUTES.LEARNERS,
    // ROUTES.CREATE_LEARNER,
    // ROUTES.CREATE_LEARNER,
    // ROUTES.EDIT_LEARNER,

    // ROUTES.REVIEWS_LIST,

    // ROUTES.CHEERING,
    // ROUTES.BOOKING_LIST,
    // ROUTES.GIFT_CARDS,
    // ROUTES.GIFT_CARDS_ADD,
    // ROUTES.GIFT_CARDS_EDIT,
    // ROUTES.GIFT_BOOKING,
    // ROUTES.B2B_GIFT_CARDS,
    // ROUTES.B2B_GIFT_CARDS_ADD,
    // ROUTES.B2B_GIFT_CARDS_EDIT,
    // ROUTES.WANTED_EXPERIENCES,
    // ROUTES.REVIEWS,
    // ROUTES.BALANCE,
    // ROUTES.COACH_BANNERS,
    // ROUTES.COACH_BANNERS_ADD,
    // ROUTES.COACH_BANNERS_EDIT,
    // ROUTES.LEARNER_BANNERS,
    // ROUTES.LEARNER_BANNERS_ADD,
    // ROUTES.LEARNER_BANNERS_EDIT,
    // ROUTES.COACH_BANNERS_SORT,
    // ROUTES.LEARNER_BANNERS_SORT,
    // ROUTES.PAYMENT_ERROR,
    // ROUTES.COLLECTION,
    // ROUTES.COLLECTION_EDIT,
    // ROUTES.COLLECTION_ADD,
    // ROUTES.COLLECTION_SORT,
    // ROUTES.APPROVALS,
    // ROUTES.APPROVALS_Edit,
    // ROUTES.GIGS,
    // ROUTES.GIGS_CREATE,
    // ROUTES.GIGS_EDIT,
    // ROUTES.GIGS_APPLICATIONS,
    // ROUTES.GIGS_NOTIFICATIONS,
    // ROUTES.ACCOUNT_MANAGER,
    // ROUTES.ACCOUNT_MANAGER_EDIT,
  ],
};

export const ComponentWithRoleKeys = {
  addExpBtn: [ROLES.admin, ROLES.super_admin],
  sortCollections: [ROLES.admin, ROLES.super_admin],
  addCoachBtn: [ROLES.admin, ROLES.super_admin],
  generalAddItem: [ROLES.admin, ROLES.super_admin],
  addNotification: [ROLES.super_admin, ROLES.admin],
  logs: [ROLES.admin, ROLES.super_admin],
  discountLogs: [ROLES.admin, ROLES.super_admin],
  exportData: [ROLES.super_admin],
  createGiftBooking: [ROLES.super_admin],
  exportB2BGiftCards: [],
};
