import { axiosInstance } from ".";
import { GetTokenPayload, LoginPayloadType } from "../../models";
import { API_ENDPOINTS } from "../apiEndPoints";

const login = async (payload: LoginPayloadType): Promise<GetTokenPayload> =>
  await axiosInstance.post(API_ENDPOINTS.auth.login, {
    ...payload,
  });

export {
    login,
  
};
