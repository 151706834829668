import { useEffect, useState } from 'react';

const useImageCropper = () => {
  const [croppedImages, setCroppedImages] = useState<string[]>([]);
  const [originalImages, setOriginalImages] = useState<(string | File)[]>([]);
  const [mainImageIndex, setMainImageIndex] = useState<number>(0);
  const [deletedImagesIds, setDeletedImagesIds] = useState<string[]>([]);

  return {
    croppedImages,
    setCroppedImages,
    originalImages,
    setOriginalImages,
    setMainImageIndex,
    mainImageIndex,
    deletedImagesIds,
    setDeletedImagesIds,
  };
};

export default useImageCropper;
