import { t } from 'i18next';
import moment from 'moment';
import { isToday, isBeforeNow, isBeforeToday } from '../utils';

export const englishNameWithSymbolsAndNumbers = {
  pattern: /^[A-Za-z0-9\s.\-_()+]+$/,
  message: 'This field accept English letters, numbers and symbols',
};

export const required = {
  required: true,
  message: `${t('requiredMessage')}`,
};
export const lettersOnly = {
  pattern: /^[\p{L}\s]+$/u,
  message: `${t('thisFieldAcceptsLettersOnly')}`,
};
export const whiteSpace = {
  required: true,
  message: `${t('requiredMessage')}`,
};
export const number = {
  pattern: /^-?[0-9]+$/,
  message: t('numberValidError'),
};

export const arabicField = {
  pattern: /^[\u0600-\u06FF0-9\s.\-_()+"]+$/,
  warningOnly: true,
  message: t('youAreWritingEnglishCharacters'),
};

export const englishField = {
  pattern: /^[A-Za-z0-9\s.\-_()+"]+$/,
  message: t('youAreWritingArabicCharacters'),
  warningOnly: true,
};

export const decimalNumber = {
  pattern: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
  message: t('decimalNumberValidError'),
};

export const email = {
  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  message: t('pleaseProvideValidEmail'),
};

const validateExpUrl = (_1: any, value: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  if (value && !pattern.test(value)) {
    return Promise.reject(t('linkValidationError'));
  }
  return Promise.resolve();
};

export const url = {
  validator: validateExpUrl,
};

export const minLengthValidator = (minLength: number) => ({
  min: minLength,
  message: `${t('minLengthValidationError', { minLength })}`,
});
export const minMaxLengthValidator = (charactersNumber: number) => ({
  min: charactersNumber,
  max: charactersNumber,
  message: `${t('minMaxLengthValidator', { charactersNumber })}`,
});
export const saudiNumberValidator = {
  pattern: /^5(([01345689]\d{7}$)|(7[0125678]\d{6}$))/g,
  message: t('pleaseProvideValidMobileNumber'),
};

export const maxLengthValidator = (maxLength: number) => ({
  max: maxLength,
  message: `${t('maxLengthValidError', { maxLength })}`,
});

export const minLengthValidatorWithoutWhiteSpaces =
  (minLength: number) => (_1: any, value: string) => {
    if (value) {
      const testVal = value.replace(/\s+/g, '');
      if (testVal.length < minLength) {
        return Promise.reject(
          new Error('Name should be at least 2 characters.')
        );
      }
    }
    return Promise.resolve();
  };

export const validateNumber = (values: {
  maxVal?: number | string;
  minVal?: number | string;
  maxValMessage?: string;
  minValMessage?: string;
  equality?: boolean;
}) => {
  const {
    maxVal,
    minVal,
    maxValMessage,
    minValMessage,
    equality = false,
  } = values;

  return (_1: any, value: number) => {
    if (
      value !== undefined &&
      maxVal !== undefined &&
      (equality
        ? parseInt(String(value)) >= parseInt(maxVal as string)
        : parseInt(String(value)) > parseInt(maxVal as string))
    ) {
      return Promise.reject(
        new Error(maxValMessage || `${t('maxValValidError', { maxVal })}`)
      );
    }
    if (
      value !== undefined &&
      minVal !== undefined &&
      (equality
        ? parseInt(String(value)) <= parseInt(minVal as string)
        : parseInt(String(value)) < parseInt(minVal as string))
    ) {
      return Promise.reject(
        new Error(minValMessage || `${t('minValValidError', { minVal })}`)
      );
    }
    return Promise.resolve();
  };
};
export const textArea = [required, whiteSpace];

export const beforeNowTimeValidator =
  (relatedDate: Date) => (_1: any, value: Date) => {
    if (value && isToday(relatedDate) && isBeforeNow(value)) {
      return Promise.reject(t('youCantSelectTimeBeforeNow'));
    }
    return Promise.resolve();
  };

export const beforeTodayValidator = (_1: any, value: string) => {
  if (value && isBeforeToday(value)) {
    return Promise.reject(t('youCantSelectDateBeforeToday'));
  }
  return Promise.resolve();
};

export const beforeDateValidator =
  (otherDate: Date, message: string = t('endDateMustBeAfterStartDate')) =>
  (_1: any, value: string) => {
    if (
      value &&
      otherDate &&
      moment(value).isBefore(moment(otherDate), 'day')
    ) {
      return Promise.reject(message);
    }
    return Promise.resolve();
  };
export const afterDateValidator =
  (otherDate: Date, message: string = t('endDateMustBeAfterStartDate')) =>
  (_1: any, value: string) => {
    if (value && otherDate && moment(value).isAfter(moment(otherDate), 'day')) {
      return Promise.reject(message);
    }
    return Promise.resolve();
  };

export const afterTodayValidator =
  (message: string = t('pleaseSelectValidDate')) =>
  (_1: any, value: string) => {
    if (value && moment(value).isAfter(moment(), 'day')) {
      return Promise.reject(message);
    }
    return Promise.resolve();
  };

export const betweenTwoDatesValidator =
  (
    startDate: Date,
    endDate: Date,
    isEqual: boolean = true,
    message: string = t('theDeadlineMustBeBetweenTodayDateAndStartDate')
  ) =>
  (_1: any, value: string) => {
    if (
      value &&
      startDate &&
      endDate &&
      !moment(value).isBetween(
        moment(startDate),
        moment(endDate),
        'day',
        isEqual ? '[]' : '()'
      )
    ) {
      return Promise.reject(message);
    }
    return Promise.resolve();
  };
