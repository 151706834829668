import i18n from '../assets/i18n';
import { RouteDto, ROUTES } from '../models';
import { ExpFormProvider } from '../pages/experiences/helpers/useExpForm';
import { SessionAttendeesPage } from '../pages/sessions';

import { EditOrAddExp, ExpList, SessionsCreateOrEdit, SessionsList, TalentsList } from './lazyLoadedComponents';

export const routesList: RouteDto[] = [
  // { ****** Experience ****** }
  {
    path: ROUTES.EXPERIENCES,
    id: '2',
    name: i18n.t('experiences'),
    component: <ExpList />,
  },
  {
    path: ROUTES.ADD_EXPERIENCE,
    id: '22',
    breadcrumbParentId: '2',
    name: i18n.t('addNewExperience'),
    component: <EditOrAddExp />,
  },
  {
    path: ROUTES.EDIT_EXPERIENCES,
    id: '21',
    breadcrumbParentId: '2',
    name: i18n.t('editExperience'),
    component: <EditOrAddExp isEdit />,
  },

  // { ****** SESSIONS ****** }
  {
    path: ROUTES.SESSIONS,
    id: '3',
    name: i18n.t('sessions'),
    component: <SessionsList />,
  },
  {
    path: ROUTES.ADD_SESSION,
    id: '32',
    breadcrumbParentId: '3',
    name: i18n.t('addSession'),
    component: <SessionsCreateOrEdit />,
  },
  {
    path: ROUTES.EDIT_SESSIONS,
    id: '31',
    breadcrumbParentId: '3',
    name: i18n.t('editSession'),
    component: <SessionsCreateOrEdit isEdit />,
  },
  {
    path: ROUTES.SESSION_LEARNERS,
    id: '33',
    breadcrumbParentId: '3',
    name: i18n.t('sessionLearners'),
    component: <SessionAttendeesPage />,
  },

  // { ****** Talents ****** }
  {
    path: ROUTES.TALENTS,
    id: '4',
    name: i18n.t('talents'),
    component: <TalentsList />,
  },

  // {
  //   path: ROUTES.SESSION_ADD_LEARNER,
  //   id: '34',
  //   breadcrumbParentId: '3',
  //   name: i18n.t('sessionLearners'),
  //   component: <SessionAddLearner />,
  // },
  // {
  //   path: ROUTES.Add_Session,
  //   id: '32',
  //   breadcrumbParentId: '3',
  //   name: i18n.t('AddSession'),
  //   component: <SessionsCreateOrEdit />,
  // },
  // // REVIEWS
  // {
  //   path: ROUTES.REVIEWS_LIST,
  //   id: '4',
  //   name: i18n.t('reviews'),
  //   component: <ReviewsList />,
  // },
  // // LEARNERS
  // {
  //   path: ROUTES.LEARNERS,
  //   id: '5',
  //   name: i18n.t('learners'),
  //   component: <LearnersList />,
  // },
  // {
  //   path: ROUTES.CREATE_LEARNER,
  //   id: '52',
  //   breadcrumbParentId: '5',
  //   name: i18n.t('createLearner'),
  //   component: <EditOrAddLearner />,
  // },
  // {
  //   path: ROUTES.EDIT_LEARNER,
  //   id: '53',
  //   breadcrumbParentId: '5',
  //   name: i18n.t('editLearner'),
  //   component: <EditOrAddLearner isEdit />,
  // },
  // {
  //   path: ROUTES.DISCOUNT_CODES,
  //   id: '6',
  //   name: i18n.t('discountCodes'),
  //   component: <DiscountCodesList />,
  // },
  // {
  //   path: ROUTES.DISCOUNT_CODES_ADD,
  //   id: '61',
  //   breadcrumbParentId: '6',
  //   name: i18n.t('createDiscountCode'),
  //   component: <CreateOrUpdateDiscountCode />,
  // },
  // {
  //   path: ROUTES.DISCOUNT_CODES_EDIT,
  //   id: '62',
  //   breadcrumbParentId: '6',
  //   name: i18n.t('updateDiscountCode'),
  //   component: <CreateOrUpdateDiscountCode isEdit />,
  // },
  // {
  //   path: ROUTES.NOTIFICATIONS,
  //   id: '7',
  //   name: i18n.t('notifications'),
  //   component: <NotificationsList />,
  // },
  // {
  //   path: ROUTES.NOTIFICATIONS_ADD,
  //   id: '71',
  //   breadcrumbParentId: '7',
  //   name: i18n.t('createNotification'),
  //   component: <NotificationCreate />,
  // },
  // {
  //   path: ROUTES.CHEERING,
  //   id: '8',
  //   name: i18n.t('cheering'),
  //   component: <Cheering />,
  // },
  // {
  //   path: ROUTES.BOOKING_LIST,
  //   id: '9',
  //   name: i18n.t('bookingList'),
  //   component: <BookingList />,
  // },
  // {
  //   path: ROUTES.GIFT_CARDS,
  //   id: '10',
  //   name: i18n.t('giftCards'),
  //   component: <GiftCardsList />,
  // },
  // {
  //   path: ROUTES.GIFT_CARDS_ADD,
  //   id: '101',
  //   breadcrumbParentId: '10',
  //   name: i18n.t('createGiftCard'),
  //   component: <CreateOrEditGiftCard />,
  // },
  // {
  //   path: ROUTES.GIFT_CARDS_EDIT,
  //   id: '102',
  //   breadcrumbParentId: '10',
  //   name: i18n.t('editGiftCard'),
  //   component: <CreateOrEditGiftCard isEdit />,
  // },
  // {
  //   path: ROUTES.GIFT_BOOKING,
  //   id: '11',
  //   name: i18n.t('giftBooking'),
  //   component: <GiftBookingList />,
  // },
  // {
  //   path: ROUTES.B2B_GIFT_CARDS,
  //   id: '13',
  //   name: i18n.t('b2bGiftCards'),
  //   component: <B2BGiftCardList />,
  // },
  // {
  //   path: ROUTES.B2B_GIFT_CARDS_ADD,
  //   id: '131',
  //   breadcrumbParentId: '13',
  //   name: i18n.t('createB2BGiftCard'),
  //   component: <CreateOrEditB2BGiftCard />,
  // },
  // {
  //   path: ROUTES.B2B_GIFT_CARDS_EDIT,
  //   id: '132',
  //   breadcrumbParentId: '13',
  //   name: i18n.t('editB2BGiftCard'),
  //   component: <CreateOrEditB2BGiftCard isEdit />,
  // },
  // {
  //   path: ROUTES.REVIEWS,
  //   id: '14',
  //   name: i18n.t('reviews'),
  //   component: <DashboardReviewsList />,
  // },
  // {
  //   path: ROUTES.WANTED_EXPERIENCES,
  //   id: '15',
  //   name: i18n.t('wantedExperiences'),
  //   component: <WantedExperiencesList />,
  // },
  // {
  //   path: ROUTES.COACHES,
  //   id: '16',
  //   name: i18n.t('coaches'),
  //   component: <CoachesList />,
  // },
  // {
  //   path: ROUTES.CREATE_COACH,
  //   id: '161',
  //   breadcrumbParentId: '16',
  //   name: i18n.t('createCoach'),
  //   component: <CreateOrEditCoach />,
  // },
  // {
  //   path: ROUTES.EDIT_COACH,
  //   id: '162',
  //   breadcrumbParentId: '16',
  //   name: i18n.t('editCoach'),
  //   component: <CreateOrEditCoach isEdit />,
  // },
  // {
  //   path: ROUTES.COACH_BALANCE,
  //   id: '163',
  //   breadcrumbParentId: '16',
  //   name: i18n.t('balance'),
  //   component: <CoachBalance />,
  // },
  // {
  //   path: ROUTES.BALANCE,
  //   id: '17',
  //   name: i18n.t('balance'),
  //   component: <Balance />,
  // },
  // {
  //   path: ROUTES.COACH_BANNERS,
  //   id: '17',
  //   name: i18n.t('coachBanners'),
  //   component: <BannerList bannerType='coach' />,
  // },
  // {
  //   path: ROUTES.COACH_BANNERS_ADD,
  //   id: '171',
  //   name: i18n.t('coachBannerCreate'),
  //   component: <CreateOrEditCoachBanner />,
  // },
  // {
  //   path: ROUTES.COACH_BANNERS_EDIT,
  //   id: '172',
  //   name: i18n.t('editCoachBanner'),
  //   component: <CreateOrEditCoachBanner />,
  // },
  // {
  //   path: ROUTES.COACH_BANNERS_SORT,
  //   id: '173',
  //   name: i18n.t('sortCoachBanners'),
  //   component: <SortBanners bannerType='coach' />,
  // },
  // {
  //   path: ROUTES.LEARNER_BANNERS,
  //   id: '18',
  //   name: i18n.t('learnerBanners'),
  //   component: <BannerList bannerType='learner' />,
  // },
  // {
  //   path: ROUTES.LEARNER_BANNERS_ADD,
  //   id: '181',
  //   name: i18n.t('learnerBannerCreate'),
  //   component: <CreateOrEditLearnerBanner isEdit={false} />,
  // },
  // {
  //   path: ROUTES.LEARNER_BANNERS_EDIT,
  //   id: '182',
  //   name: i18n.t('editLearnerBanner'),
  //   component: <CreateOrEditLearnerBanner isEdit />,
  // },
  // {
  //   path: ROUTES.LEARNER_BANNERS_SORT,
  //   id: '183',
  //   name: i18n.t('sortLearnerBanners'),
  //   component: <SortBanners bannerType='learner' />,
  // },
  // {
  //   path: ROUTES.COLLECTION,
  //   id: '19',
  //   name: i18n.t('collections'),
  //   component: <CollectionList />,
  // },
  // {
  //   path: ROUTES.COLLECTION_EDIT,
  //   id: '191',
  //   name: i18n.t('editCollection'),
  //   component: <CreateOrEditCollection isEdit />,
  // },
  // {
  //   path: ROUTES.COLLECTION_ADD,
  //   id: '192',
  //   name: i18n.t('addCollection'),
  //   component: <CreateOrEditCollection />,
  // },
  // {
  //   path: ROUTES.COLLECTION_SORT,
  //   id: '193',
  //   name: i18n.t('sortCollections'),
  //   component: <SortCollections />,
  // },
  // {
  //   path: ROUTES.PAYMENT_ERROR,
  //   id: '20',
  //   name: i18n.t('PaymentError'),
  //   component: <PaymentError />,
  // },
  // {
  //   path: ROUTES.GIGS,
  //   id: '21',
  //   name: i18n.t('gigs'),
  //   component: <GigsList />,
  // },
  // {
  //   path: ROUTES.GIGS_CREATE,
  //   id: '211',
  //   name: i18n.t('createGig'),
  //   component: <CreateOrEditGigs />,
  // },
  // {
  //   path: ROUTES.GIGS_EDIT,
  //   id: '212',
  //   name: i18n.t('editGig'),
  //   component: <CreateOrEditGigs isEdit />,
  // },
  // {
  //   path: ROUTES.GIGS_APPLICATIONS,
  //   id: '213',
  //   name: i18n.t('gigsApplications'),
  //   component: <GigsApplications />,
  // },
  // {
  //   path: ROUTES.GIGS_NOTIFICATIONS,
  //   id: '214',
  //   name: i18n.t('gigsNotifications'),
  //   component: <GigsNotifications />,
  // },
  // {
  //   path: ROUTES.ACCOUNT_MANAGER,
  //   id: '22',
  //   name: i18n.t('accountManager'),
  //   component: <AccountManagerList />,
  // },
  // {
  //   path: ROUTES.ACCOUNT_MANAGER_EDIT,
  //   id: '211',
  //   name: i18n.t('editAccountManager'),
  //   component: <EditAccountManger isEdit />,
  // },
  // {
  //   path: ROUTES.APPROVALS,
  //   id: '22',
  //   name: i18n.t('Approvals'),
  //   component: <ApprovalsList />,
  // },
  // {
  //   path: ROUTES.APPROVALS_Edit,
  //   id: '221',
  //   name: i18n.t('editCoach'),
  //   component: <EditApprovals />,
  // },
];

export const routesWithParams = {
  expEdit: (objectId: string) => `${ROUTES.EXPERIENCES}/edit/${objectId}`,
  learnerBannerEdit: (objectId: string) => `${ROUTES.LEARNER_BANNERS}/edit/${objectId}`,
  coachBannerEdit: (objectId: string) => `${ROUTES.COACH_BANNERS}/edit/${objectId}`,
  coachEdit: (coachId: string) => `${ROUTES.COACHES}/edit/${coachId}`,

  sessionsList: (expId: string) => `${ROUTES.SESSIONS}/${expId}`,
  reviewsList: (expId: string) => `${ROUTES.REVIEWS}/${expId}`,

  sessionEdit: (expId: string, sessionId: string) => `${ROUTES.SESSIONS}/${expId}/edit/${sessionId}`,

  sessionLearners: (expId: string, sessionId: string) => `${ROUTES.SESSIONS}/${expId}/edit/${sessionId}/learners`,

  sessionAddLearner: (expId: string, sessionId: string) => `${ROUTES.SESSIONS}/${expId}/edit/${sessionId}/learners/add`,
  sessionCreate: (expId?: string) => (expId ? `${ROUTES.SESSIONS}/${expId}/create` : `${ROUTES.SESSIONS}/create`),
  learnerEdit: (learnerId: string) => `${ROUTES.LEARNERS}/edit/${learnerId}`,
  discountCodeEdit: (discountCodeId: string) => `${ROUTES.DISCOUNT_CODES}/edit/${discountCodeId}`,
  giftCardEdit: (id: string) => `${ROUTES.GIFT_CARDS}/edit/${id}`,
  b2bGiftCardEdit: (id: string) => `${ROUTES.B2B_GIFT_CARDS}/edit/${id}`,
  collectionEdit: (id: string) => `${ROUTES.COLLECTION}/edit/${id}`,
  EditAccountManager: (id: string) => `${ROUTES.ACCOUNT_MANAGER}/edit/${id}`,
  coachBalance: (id: string) => `${ROUTES.COACHES}/balance/${id}`,
  approvalEdit: (id: string) => `${ROUTES.APPROVALS}/edit/${id}`,
  editGig: (id: string) => `${ROUTES.GIGS}/edit/${id}`,
};
