import { t } from 'i18next';
import { useEffect, useState } from 'react';
import useLang from './use-lang';
// import { useCommonStore } from '@/stores';
import { ITreeData, RegionItem, CityWithIdItem } from '../models';

const initialRegionsTree: ITreeData[] = [
  {
    title: t('allRegions'),
    value: 'all',
    key: 'all',
    children: [],
  },
];

const generateRegionsTree = (regions: RegionItem[], isLtr: boolean) => {
  const finalRegions = [...initialRegionsTree];
  if (regions) {
    regions.forEach((region) => {
      finalRegions[0].children.push({
        title: isLtr ? region.nameEn : region.nameAr,
        value: region.id,
        key: region.id,
      });
    });
  }

  return finalRegions;
};

const generateCitiesTree = (cities: CityWithIdItem[], isLtr: boolean) => {
  const finalCities = [
    {
      title: t('allCities'),
      value: 'all',
      key: 'all',
      children: [],
    },
  ];
  if (cities) {
    cities.forEach((city) => {
      finalCities[0].children.push({
        title: isLtr ? city.nameEn : city.nameAr,
        value: city.id,
        key: city.id,
      });
    });
  }

  return finalCities;
};

const generateCitiesMap = (cities: CityWithIdItem[]) => {
  const cityMap = new Map();
  cities.forEach((cityData: CityWithIdItem) => cityMap.set(cityData.id, cityData));
  return cityMap;
};

export const mapCitiesWithRegion = (regions: RegionItem[], isLtr: boolean) => {
  const finalMappedRegionsData = new Map();
  regions.forEach((region) => {
    finalMappedRegionsData.set(region.id, {
      name: isLtr ? region.nameEn : region.nameAr,
      citiesMap: generateCitiesMap(region.citiesWithIds),
      citiesTree: generateCitiesTree(region.citiesWithIds, isLtr),
    });
  });
  return finalMappedRegionsData;
};

const useRegionsTree = () => {
  const { isLTR } = useLang();
  const [regionsTree, setRegionsTree] = useState(initialRegionsTree);
  const [mappedRegionsWithCities, setMappedRegionsWithCities] = useState<
    Map<
      string,
      {
        name: string;
        citiesMap: Map<string, CityWithIdItem[]>;
        citiesTree: ITreeData[];
      }
    >
  >(new Map());

  // const { getRegions } = useCommonStore(); // TODO: To be implemented after redux
  const getRegions = (xx) => {};

  useEffect(() => {
    getRegions((regions) => {
      setRegionsTree(generateRegionsTree(regions, isLTR));
      setMappedRegionsWithCities(mapCitiesWithRegion(regions, isLTR));
    });
  }, []);

  return {
    regionsTree,
    mappedRegionsWithCities,
  };
};

export default useRegionsTree;
