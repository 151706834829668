import { t } from 'i18next';

export * from '../../routes/routesDetails';
export { default as menuList } from '../../routes/menuList';
export * as RULES from './rules';
export * from './experienceConstants';
export * from './sleep-values';
export * from './loading-keys';
export * from './default-table-pagination';
export * from './country-codes';
export const pageSizeConstant = 25;
export const COLLECTION_LINK = `${process.env.REACT_APP_SUPLIFT_WEBSITE_LINK}/collections`;

export const gigsNotificationsOptions = [
  { label: t('email'), value: 'email' },
  { label: t('app'), value: 'in-app' },
];
