import { FC } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { pathToRegexp } from 'path-to-regexp';
import s from './index.module.scss';
import { routesList } from '../../../helpers/constants';
import { queryAncestors } from '../../../helpers/utils';

const generateBreadcrumbs: FC = (paths: any) =>
  paths.map(
    (item: any, key: any) =>
      item && (
        <items key={key}>
          {paths.length - 1 !== key ? (
            <Link to={item.path || '#'}>{item?.name}</Link>
          ) : (
            item?.name
          )}
        </items>
      )
  );

const BreadCrumb: FC = () => {
  const location = useLocation();
  const breadCrumbView = () => {
    const { pathname } = location;

    // Find the current route that match the current path name
    const currentRoute = Object.values(routesList).find(
      _ => _.path && pathToRegexp(_.path).exec(pathname)
    );

    // get paths
    const paths = currentRoute
      ? queryAncestors(routesList, currentRoute, 'breadcrumbParentId').reverse()
      : [
          routesList[0],
          {
            id: 404,
            name: 'Not Found',
          },
        ];
    return (
      <div className={s.breadContainer}>
        <Breadcrumb items={routesList}></Breadcrumb>
      </div>
    );
  };

  return <>{breadCrumbView()}</>;
};

export default BreadCrumb;
