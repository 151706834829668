import mapStyles from "./mapStyle";

export const containerStyle = {
  width: "100%",
  height: "100%",
};

// center on city or country
export const center = {
  lat: 24.7136,
  lng: 46.6753,
};

// Disable default UI
export const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};
