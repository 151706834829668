export enum ROLES {
  admin = 'admin',
  super_admin = 'super_admin',
}
export enum SCOPES {
  canCreate = 'can-create',
  canEdit = 'can-edit',
  canDelete = 'can-delete',
  canView = 'can-view',
}
